
.ListContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    gap: 8px;   
    .ItemContainer{
        width: 27%;
        height: 180px;
        background: #FFFFFF;
        box-sizing: border-box;
        border-radius: 10px;
        // margin-top: 2%;
        // margin-right: 22px;
        display: flex;
        flex-direction: row;
        padding: 18px;
        cursor: pointer;
        .imageholder{
            width: 59px;
            margin-top: 3px;
        }
        .content{
            width: calc(100% - 59px);
            .title{
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 110%;
                letter-spacing: -0.415271px;
                color: #013C44;
                font-weight: 500;
                line-height: 32px;
                // margin-bottom: 13.80px;
                // white-space: pre-line;
            }
            .desc{
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 129%;
                color: #4D777C;
            }
           .availableappointment{
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            width: 286px;
            height: 31.02px;
            left: 0px;
            top: 4.53px;
            // background: 'white';
            opacity: 0.1;
            border-radius: 5px;
            // color: #4D777C;
            margin-top: 3%;
        }
    }
        
    }
    @media (min-width: 768px) and (max-width: 1279px) {
        .ItemContainer{
            // width: 260px;
            height: 120.45px;
            // padding: 21px;
            .imageholder{
                width: 55px;
                .image{
                    width: 28px;
                }
            }
            .content{
                width: calc(100% - 55px);
                .title{
                    font-size: 18px;
                }
                .desc{
                    font-size: 11px;
                }
            }
        }

    }

    
    @media (min-width: 375px) and (max-width: 767px) {
        .ItemContainer{
            width: 200px;
            height: 150px;
            padding: 10px;
            .imageholder{
                width: 40px;
                .image{
                    width: 20px;
                }
            }
            .content{
                width: calc(100% - 40px);
                .title{
                    // font-size: 13px;
                    // margin-bottom: 12.80px;
                }
                .desc{
                    // font-size: 9px;
                }
            }
        }
    }
   @media (max-width: 489px) {
       justify-content: center;
       
 }


}

.next{
    margin: 0px;
    // width: 280px;
    align-items: left;
}



.price{
    color: #37CC8C;
    font-size: 14px;
    font-weight: bold;
}

.times{
    color: #00839B;
    font-size: 14px;
    font-weight: bold;
}

.timesfree{
    color: #00839B;
    font-size: 13px;
    font-weight: bold;
}

.newprice{
    color: #37CC8C;
    font-size: 24px;
    margin-left: 4px;
}

.fadprice{
    display: block;
    color: #FF5C3C;
    font-size: 24px;
    // margin-left: 4px;
    margin: 8px 0;

}



.example {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  }


  @media only screen and (max-width: 767px) {
    .ListContainer {
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .ItemContainer {
            width: 80%;
            height:auto;
        }
    }

}