


.video-player video {
    mix-blend-mode: multiply;
    div{
        width: 100% !important;
    }
}

.play-button-div {
    position: absolute;
    top: -180px;
    display: flex;
    justify-content: center;
    //max-width: 91% !important;
}

.video-play-button {
    width: 49px;
    height: 49px;
    border-radius: 100%;
    max-width: 49px;
    background-size: 22px;
    background-repeat: no-repeat;
    /* opacity: 0.3; */
    /* mix-blend-mode: multiply; */
    border: 2px solid white;
    background-position: 14px 50%;
    cursor: pointer;
}
.video-play-button-small{
    min-width: 80px;
    height: 85px;
    border-radius: 100%;
    max-width: 49px;
    background-size: 22px;
    background-repeat: no-repeat;
    border: 2px solid #fff;
    background-position: center;
    cursor: pointer;
    background-color: #013c44;
}