.tab-cls-health-rec {
  margin-top: 36px;
  margin-left: 60px;
  .MuiBox-root {
    padding-left: 0px !important;
  }
}

html,
body {
  min-width: auto !important;
  min-height: auto !important;
}

.doctors-page {
  width: 100%;
  padding: 0px;
  overflow-x: hidden;
}

.doctors-header {
  padding: 35px;
  background: #f2f8f9;
}

.doctors-header-link {
  display: flex;
  justify-content: flex-end;
}

.doctors-section {
  padding-top: 250px;
  padding-bottom: 212px;
  background-repeat: no-repeat;
  background-color: rgba(3, 35, 39, 0.76);
  background-size: 104% 80% 125%;
  /* mix-blend-mode: multiply; */
  background-blend-mode: multiply;
  position: relative;
}

.doctors-section-col1 {
  font-style: normal;
  font-weight: normal;
  font-size: 43px;
  line-height: 50px;
  letter-spacing: -0.3px;
  color: #ffffff;
}

.doctors-section-col2 {
  padding-bottom: 25px;
}

.doctors-section2 {
  position: relative;
  top: -105px;
}

.doctors-box {
  padding-right: 25px;
  padding-left: 25px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 14px 5px rgba(3, 130, 153, 0.08);
  margin-left: 3px;
  margin-right: 3px;
}

.doctors-box-logo {
  text-align: center;
  padding: 0px;
}

.doctors-box-container {
  margin-top: 25px;
  margin-bottom: 25px;
  border: 2px solid rgb(55, 204, 140);
  border-radius: 10px;
  padding: 25px;
  cursor: pointer;
}

.doctors-box-text1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.415271px;
  color: #013c44;
  margin-bottom: 10px;
}

.doctors-box-text2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #4d777c;
}

.doctors-section3 {
  margin-bottom: 72px;
}

.doctors-section3-title {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 42px;
  /* or 117% */
  text-align: center;
  color: #013c44;
}

.doctors-section4 {
  margin-bottom: 85px;
}

.doctors-section4-box {
  margin-top: 25px;
  text-align: center;
}

.doctors-section5-link {
  text-align: center;
  display: flex;
  justify-content: center;
}

.doctors-section5 {
  margin: 25px;
  padding: 25px;
}

.doctors-section6 {
  background: #f2f8f9;
  padding-top: 82px;
  padding-bottom: 82px;
}

.doctors-section6-title {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  font-family: "VisueltMedium";
  color: #013c44;
}

.doctors-section6-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #013c44;
  font-family: "VisueltRegular";
}

.doctors-search {
  background: #ffffff;
  border: 1px solid #e3ebf6;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
}

.doctors-section6-filter {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  cursor: pointer;
}

.doctors-section6-text2 {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.filter-section-form {
  background: white;
  border-radius: 5px;
  padding: 10px;
  margin: 2px;
  border-style: groove;
  border-width: 1px;
  border-color: lightblue;
  color: blue;
}

.ItemCardsWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
  margin-left: 4%;
}
.header-text {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 34px;
  display: flex;
  align-items: center;
  color: #0c44ac !important;
  margin-top: 54px;
}
.sub-header {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #0c44ac !important;
  margin-top: 8 px;
}
.ItemCard1 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 450px;
  height: 210px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;

  background: #ffc655;

  border-radius: 10px;
}
.ItemCard1:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.ItemContainer1-text {
  font-family: Visuelt;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;

  color: #f2f8f9;

  padding: 18px 15px 12px 4px;
}
.ItemCard2 {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 450px;
  height: 210px;
  margin-right: 30px;
  display: flex;
  flex-direction: row;

  border-radius: 10px;

  background: #37cc8c;
  // mix-blend-mode: color;
}
.ItemCard2:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.ItemContainer2-text {
  font-family: Visuelt;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0em;

  color: #f2f8f9;

  padding: 18px 15px 12px 4px;
}
.date-range-container {
  border-style: groove;
  border-color: lightblue;
  border-radius: 3px;
  font-size: larger;
  height: 7vh;
  font-family: "VisueltRegular";
}

.booked-card {
  // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  // transition: 0.3s;
  // width: 30%;
  // height:47vh;
  // border-style:solid;
  // border-width: 1px;

  // border-radius: 5px;
}
.booked-card-text {
  font-family: Visuelt;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  /* or 24px */
  color: #163e8c;
  align-items: center;
  margin-left: 30px;
  margin-bottom: 10%;
  margin-top: 13px;
}

.booked-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.booked-card-footer {
  color: #163e8c;
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #effff8;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-top: 39px;
  justify-content: space-between;
}
.footer-icons {
  display: flex;
  flex-direction: row;
  //   padding: 10px;
  //   margin-left: 60%;
  margin-top: 10%;
}
.footer-text {
  display: flex;
  flex-direction: column;
  //   font-size: xx-large;
  font: bolder;
  //       font-family: Visuelt;
  // font-style: normal;
  // font-weight: normal;
  font-size: 16px;
  line-height: 70%;

  margin-left: 10px;
}
.footer-text-cost {
  font-size: 26px;
  color: #163e8c;
}
.status-button {
  color: green;
}
.doctors-section7 {
  background: #f2f8f9;
  padding-bottom: 82px;
  .pagination {
    justify-content: center;
  }
}

.profile-row {
  width: 100%;
  margin: 0px;
  justify-content: center;
}

.drop-filter {
  border-color: #013c44;
  border-width: 10px;
  width: 100%;
}
.test-book-details {
  // display: flex;
  // flex-direction: row;
}
.testbookdetails-container {
  float: left;
  width: 30%;
  // display: flex;
  flex-direction: row;
  border-color: blue;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: auto;
  border-style: solid;
  border-width: 1px;
  margin: 10px;
  border-radius: 5px;
  padding: 1%;

  @media (max-width: 900px) {
    width: 40%;
  }
  @media (max-width: 700px) {
    width: 70%;
  }
}
@media (min-width: 992px) {
  .doctors-box {
    .middle {
      margin: 25px;
    }
  }
}
.doctors-search {
  input[type="text"] {
    outline: none !important;
    outline-offset: 0px !important;
    color: #163e8c !important;
  }
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
  color: #163e8c !important;
}

.Icons {
  margin-left: 120px;
  margin-top: 50%;
  position: relative;
}
@media screen and (max-width: 440px) {
  .testbookdetails-container {
    float: left;
    width: 100%;
    // display: flex;
    flex-direction: row;
    border-color: blue;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    height: auto;
    border-style: solid;
    border-width: 1px;
    margin-left: -5%;
    margin-bottom: 10%;
    border-radius: 5px;
  }
  .Icons {
    margin-left: 200px;
    margin-top: -115%;
    position: relative;
  }
  .booked-card-footer {
    color: #163e8c;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #effff8;
    padding-bottom: 9px;
    // padding-top:10px;
    // margin-top: 39px;
    padding-top: 0px;
    justify-content: space-between;
  }
  .col-md-3 {
    width: 40%;
  }
  .tab-cls-health-rec {
    margin-top: 36px;
    margin:10px;
    .MuiBox-root {
      padding-left: 0px !important;
    }
  }
}
