body {
  background-color: #eee;
}

.date {
  font-size: 11px;
}

.track-line {
  height: 2px !important;
  background-color: #00839b;

  @media (max-width: 650px) {
    height: 50px !important;
    width: 2px;
  }
}

.dot {
  height: 10px;
  width: 10px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0px;
  // background-color: green;
  border-radius: 50%;
  display: inline-block;
}

.big-dot {
  height: 25px;
  width: 25px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  background-color: #37cc8c;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0 10px 5px #37cc8c;
}

.big-dot i {
  font-size: 12px;
}

.status_tracker {
  display: flex;
  flex-direction: column;
  @media (max-width: 650px) {
    flex-direction: row;
  }
}

.status_tracker_text {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 650px) {
    flex-direction: column;
    margin-left: 25px;
  }
}

.status_tracker_indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  @media (max-width: 650px) {
    flex-direction: column;
  }
}

.status_tracker_text_inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
