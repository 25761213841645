.cont-wrapper-health-record{
    .text-wrap{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-left: 60px;
        .header-text{
            font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 30px;
        line-height: 34px;
        /* identical to box height, or 113% */
        display: flex;
        align-items: center;
        color: #143656;
        margin-top: 54px;
        // margin-left: 60px;
        }
        .sub-header{
            font-family: "VisueltRegular";;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #4D777C;
        margin-top:8px;
        // margin-left:48px;
        }
    }

.cont-wrap{
    overflow-x: hidden;
}
}
@media (min-width: 768px) and (max-width: 1279px) {
.cont-wrapper-health-record{
    .text-wrap{
     justify-content: space-evenly;
  
}
}
}
@media (min-width: 300px) and (max-width: 767px) {
.cont-wrapper-health-record{
    .text-wrap{
    .header-text{
        font-size: 21px;
        justify-content: flex-start;
        }
        .sub-header{
        font-size: 18px;
        }
    }
}
}