@import '../../layout/authLayout.scss';
.inputForm{
    outline: none;
    border: none;
    background: none;
    border: 1px solid #ADC2C2;
    border-radius: 5px;
    min-height:48px;
    width: 100%;
    padding: 2% 3%;
}
.inputContainer{
    position: relative;
}
.mask{
    position: absolute;
    right: 8px;
    top: 14px;
}

.inputForm:focus {
    background-color:white;
  }
  label{
    color: #013C44;
    margin-top: 24px;
    font-size: 15px;
    margin-bottom: 5px;
}

.error{
    font-size: 12px;
    position: absolute;
    font-family: $fontnormal;
    color: #E04E47;
}
.error1{
    font-size: 12px;
    font-family: $fontnormal;
    color: #E04E47;
}
.loginForm{
    padding: 5% 15%;
    display: flex;
    font-family: $fontnormal;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h1{
        color:$primaryColour;
        font-weight: 600;
        font-family: $fontnormal;
        padding-bottom: 12px;
        font-size: 36px;
        margin-bottom: 0;
        
    }
    p{
        color: $paraColour;
        font-family: $fontnormal;
        font-weight: 400;
        line-height: 24px;
        font-size: 18px;
        padding-bottom: 1.5rem;
        margin-bottom: 0;
    

    }
    form{
        display: flex;
        flex-direction: column;
       
        label{
            color: #013C44;
            margin-top: 24px;
            margin-bottom: 5px;
        }
        input[type='tel']{
            outline: none;
            border: none;
            background: none;
            height: 48px !important;
           // border: 1px solid #ADC2C2;
            border-radius: 5px;
            width: 100%;
            min-width: 35vw !important;
        }
    }
    .signup{
        padding: 3% 0%;
        color: #00839B;
        a{
            font-weight: 600;
            color: $primaryColour;
            text-decoration: underline;
            letter-spacing: 0.5px;
            :hover{
                color: #ADC2C2;
            }
        }
    }
}

.forgetPass {
    text-align: end;
    color: #00839B !important;
    margin-top: 12px;
    margin-bottom: 24px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    text-decoration: none;
}
.forgetPass:hover{
    text-decoration: none;
    color: $primaryColour;
}
// .sumbitBtn {
//     margin: 2% 0%;
//     padding: 2%;
//     border: 1px solid #00839B !important;
//     color: white !important;
//     background-color: #00839B !important;
//     border-radius: 5px;
//     :hover{
//         background-color:red !important;
//     }
// }
.sumbitBtn{
    margin: 2% 0%;
    padding: 2%;
    border: 1px solid #00839B ;
    color: white ;
    border-radius: 5px;
    background-color: #00839B ;

}
.sumbitBtn:hover{
    background-color:#66B5C3 ;
}

//sign up 
.signupContainer{
    padding: 1% 15%;
    .back{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        max-width: 60px;
        margin-bottom: 22px;
        justify-content: space-between;
        
        a{
            color: #04A9C8;
            font-size: 18px;
        }
    }
    .heads{
        h2{
          color: #00839B;  
          font-weight: 500;
          margin-bottom: 12px;
        }
        p{
            color: $paraColour;
            font-family: $fontnormal;
            font-weight: 400;
            line-height: 24px;
            font-size: 18px;
            padding-bottom: 1.5rem;
            margin-bottom: 0;
        }
    }
    form{
        display: flex;
        flex-direction: column;
        // max-width: 45vw;
        label{
            color: #013C44;
            margin-top: 24px;
            margin-bottom: 5px;
        }
        input[type='tel']{
            outline: none;
            background: none;
            
        }
        input[type=text]:focus {
            outline: none !important; 
            outline-offset: none !important; 
        }
    }
}
.radioCheck{
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    padding: 7% 0%;
    p{
        color: #00839B;
        font-family: $fontnormal;
        font-size: 12px;
    }
}
// #clicker{
//     display: none;
// }
// .checker {
//     background-image: url(../../assets/AuthImages/check.png);
//     background-position: left center;
//     background-size: auto;
//     width: 40px;
//     height: 40px;
//     background-repeat: no-repeat;
   
// }
// #clicker:checked + .checker {
// //    background-color: green;
// //    background-position: right center;
// }

.setpassCodeContainer{
    padding: 1% 20%;
    .back{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        max-width: 60px;
        margin-bottom: 22px;
        justify-content: space-between;
        
        a{
            color: #04A9C8;
            font-size: 18px;
        }
    }
    .heads{
        h2{
          color: #00839B;  
          font-weight: 500;
          margin-bottom: 12px;
        }
        p{
            color: $paraColour;
            font-family: $fontnormal;
            font-weight: 400;
            line-height: 24px;
            font-size: 18px;
            padding-bottom: 1.5rem;
            margin-bottom: 0;
        }
    }
    form{
        display: flex;
        flex-direction: column;
        // max-width: 45vw;
        label{
           color: #013C44; 
           margin-top: 1.5vw;

        }
        input[type='number']{
            -webkit-appearance: none;
        }
    }
}
.radioCheck1{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    padding: 7% 0%;
    p{
        color: #00839B;
        font-family: $fontnormal;
        font-size: 12px;
    }
}
.confirmVerification{
    padding: 5% 15%;
    .back{
        display: flex;
        flex-direction: row;
        align-items: center;
        max-width: 50px;
        justify-content: space-between;
        
        a{
            color: #04A9C8;
        }
    }
    .heads{
        h2{
          color: #00839B;  
          font-weight: 500;
          margin-bottom: 12px;
        }
        p{
            color: $paraColour;
            font-family: $fontnormal;
            font-weight: 400;
            line-height: 24px;
            font-size: 18px;
            padding-bottom: 1.5rem;
            margin-bottom: 0;
        }
    }
    form{
        display: flex;
        flex-direction: column;
       
        label{
           color: #013C44; 
           margin-top: 1.5vw;

        }
    }
    .sumbitBtn{
        margin-top: 12%;
    }
    .resendCode{
        p{
            margin-top: 40px;
            color: #4D777C;
            font-size: 16px;
        }
        button{
            text-decoration: underline !important;
        }
    

    }

}

.forgotContainer{
border-radius: 5px;
width: 672px;
height: auto;
background-color: white;
text-align: center;
padding: 2% 5% 3% 5%;
box-shadow: 0px 0px 8px 5px rgba(3, 130, 153, 0.08);

h2{
    color: #00839B;  
    font-weight: 500;
    margin-bottom: 7%;
  }
.forget_pin_Txt{
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #4D777C;
}

.forget_pin_TxtTwo{
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #4D777C;
    margin-top: 70px;
}
  .heading{
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    
    
    color: #00839B;
  }
.spacing{
    margin-bottom: 40px;
}
  .forget_pin_TxtBottom{
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    width: 285px;   
    margin: auto; 
    text-align: center;
        
    color: #4D777C;
  }
  p{
      color: $paraColour;
      font-family: $fontnormal;
      font-weight: 400;
      line-height: 24px;
      font-size: 18px;
      padding-bottom: 1.5rem;
      margin-top: 15px;
      margin-bottom: 0;
  }
.sumbitBtn{
    width: 516px;
    font-size: 18px;
}
}
.radio_content{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    height: 111px;
    align-items: center;
    padding: 3% 11%;
    .insideContent{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 3%;
        justify-content: flex-start;
        h4{
            color:$primaryColour;
            font-size: 18px;
            font-weight: 600;
        }
        p{
            color: $paraColour;
            font-size: 14px;
            padding-bottom: 0px;
            text-align: left;
        }
    }
   

}
.labl {
    display : block;

}
.labl > input{ 
    visibility: hidden; 
    position: absolute;
}
.labl > input + div{ 
    cursor:pointer;
    border:2px solid #EDEDED;
    border-radius:5px ;
}
.labl > input:checked + div{ 
    border: 2px solid #66B5C3;
    border-radius:5px ;

}

.forgetNumber{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 516px;
    height: 235.35px;
    padding: 6% 6%;
    border: 1px solid #EDEDED;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 5%;
    label{
        font-size: 15px;
        margin-top: 0;
        color: #013C44;
    }
    input[type='tel'] {
        outline: none;
        background: none;
        min-height: 48px ;
        border: 1px solid #ADC2C2;
        border-radius: 5px;
        width: 100%;
        min-width: 100%;
    }
}
.successDialog{
border-radius: 5px;
font-family: $fontnormal;
height: auto;
width: 36.667vw;
background-color: white;
text-align: center;
padding: 8% 5%;
img{
    margin-bottom: 5%;
}

h2{
    color: #00839B;  
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 12px;
    font-family: 'VisueltMedium';
    
  }
  p{
      color: $paraColour;
      font-family: $fontnormal;
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
      padding-bottom: 1.5rem;
      margin: auto;
      width: 334px;
  }
.sumbitBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    width: 381px;
    margin: auto;
}

}
.dialoglimit{
border-radius: 5px;
position: relative;
height: auto;
width: 36.667vw;
background-color: white;
text-align: center;
padding: 10% 5%;
img{
    margin-bottom: 5%;
}
p{
    color: $paraColour;
    font-size: 18px;
}

}
.support{
    color:$primaryColour;
    font-weight: 600;
    font-size: 18px;
}
.xbutton{
    position: absolute;
    top:1%;
    right:0;
    cursor: pointer;
}
.errorWidth{
    width: 100%;
    position: relative;
}
.newMask{
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;
}

.repasscode{
    position: absolute;
    right: 20px;
    top: 13px;
    cursor: pointer;
}
.pincode-input-text{
    width: 81px;
    height: 72px;
    border: 1px solid #ADC2C2;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: 15px !important;
}
@media (max-width: 991px) {

    .loginForm form input {
        outline: none;
        border: none;
        background: none;
        height: 48px !important;
        border: 1px solid #ADC2C2;
        border-radius: 5px;
        padding: 2% 3%;
        min-width: 71vw !important;
    }
}