

.name {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #013C44;
}

.speciality {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #66B5C3;
    padding-bottom: 10px;
}

.info {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
}

.doctor-profile {
    padding: 10px;
    background: white;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 10px;
    margin-right: -5px !important;
    margin-left: -5px !important;
}

.book_doctor {
    background: #00839B;
    border-radius: 5px;
    align-items: center;
    /* padding-top: 8px; */
    /*min-width: 137px;*/
    text-align: center;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.linknotallow {
    background: rgba(73, 147, 160, 0.75) !important;
    border-radius: 5px;
    align-items: center;
    /* padding-top: 8px; */
    /*min-width: 137px;*/
    text-align: center;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.book_doctor a {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
}

.book_doctor a:hover {
    color: #FFFFFF !important;
}

.book_slot {
    background: #F2F8F9;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px;
    .notallow{
        text-align: center;
        background:white;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px;
    }
   
}

.book_time {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #013C44;
    padding: 0px !important
}

.book_icon {
    display: flex;
    margin-top: 10px;
    padding: 0px;
    cursor: pointer;
    margin-left: -13%;
    // padding-left: 10px;
}

.profile_pic {
    min-width: 146px;
    height: 178px;
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    margin-right: 15px;
    width: 90%;
}

.slotList {
    display: flex;
    justify-content: space-between;
}

.MuiRating-label {
    margin-top: 10px;
} 


.consultation-fee {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 14px;
    color: #013C44;
}


.MuiRating-root label { 
    color: #FFB800 !important;
}

.MuiRating-root.Mui-disabled {
    opacity: inherit !important;
}


.location {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}