.app-simple.light {
  --app-background: #ffffff;
  --app-color: #585858;
  --app-avatar-background: #f0f3f7;
  --app-info-color: #9b9b9b;
  --app-info-border: #d7e0e9;
  --app-chat-background: #f0f3f7;
  --app-people-color: gray;
  --app-people-active-color: #00839B;
}

.app-simple.light .pn-msg-input {
  --msg-input__background: var(--chat--light__background--1);
--msg-input__padding: 10px 18px;
--msg-input__emoji-picker__bottom: 14px;
--msg-input__emoji-picker__left: 10px;
--msg-input__icon__color: var(--chat--light__color--2);
--msg-input__icon__margin: 0 18px 0 0;
--msg-input__icon__fontSize: 13px;
--msg-input__send__background: transparent;
--msg-input__send__border: none;
--msg-input__send__borderRadius: 5px;
--msg-input__send__color: var(--chat--light__color--2);
--msg-input__send--active__color: var(--chat--light__hover--1);
--msg-input__send__fontFamily: inherit;
--msg-input__send__fontSize: 13px;
--msg-input__send__fontWeight: bold;
--msg-input__send__margin: 0 0 0 18px;
--msg-input__send__minWidth: 0;
--msg-input__send__padding: 0;
--msg-input--disabled__placeholder__color: rgba(var(--chat--light__color--2-rgb), 0.5);
--msg-input__placeholder__color: var(--chat--light__color--2);
--msg-input__textarea--focus__border: 1px solid transparent;
--msg-input__textarea--focus__outline: none;
--msg-input__textarea__background: var(--chat--light__hover--2);
--msg-input--disabled__textarea__background: var(--chat--light__background--3);
--msg-input__textarea__border: 1px solid transparent;
--msg-input--disabled__textarea__border: 1px solid transparent;
--msg-input__textarea__borderRadius: 20px;
--msg-input__textarea__boxShadow: none;
--msg-input__textarea__color: var(--chat--light__color--1);
--msg-input__textarea__fontFamily: inherit;
--msg-input__textarea__fontSize: 13px;
--msg-input__textarea__height: 32px;
--msg-input__textarea__lineHeight: 18px;
--msg-input__textarea__outline: none;
--msg-input__textarea__padding: 6px 14px 8px;
--msg-input__textarea__resize: none;
--msg-input__textarea__width: 100%;
}

.app-simple.light .pn-msg-list--light {
  --msg-list__background: transparent;
  --msg--hover__background: #e5e8ec;
}

.app-simple.light .pn-channel-list--light {
  --channel-list__background: #ffffff;
  --channel--active__background: #f0f3f7;
  --channel--hover__background: #00839B;
  --channel__name__color: #585858;
  --channel--active__name__color: #585858;
}

.app-simple {
  background: var(--app-background);
  color: var(--app-color);
  display: flex;
  height: 88vh;
  overflow: hidden;
}

.app-simple .pn-msg-input {
  --msg-input__send__borderRadius: 24px;
  --msg-input__send__padding: 10px 20px;
  --msg-input__textarea__borderRadius: 24px;
  --msg-input__textarea__padding: 12px 24px;
}

.app-simple .pn-channel-list {
  --channel__description__display: none;
  --channel__name__fontSize: 12px;
  --channel__name__fontWeight: 400;
  --channel__padding: 12px 24px;
}

.app-simple .pn-msg-list {
  --member__padding: 9px 0;
  --member--hover__background: transparent;
}

.app-simple .channels {
  background: var(--app-background);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 240px;
}

.app-simple .channels h4 {
  margin: 34px 24px 12px;
}

.chat {
  background: var(--app-chat-background);
  border-radius: 8px;
  display: flex;
  margin-left: 4%;
  margin-top: 4%;
  flex-direction: column;
  flex-grow: 1;
  /* margin: 20px 40px 20px 0; */
  padding-bottom: 30px;
  padding-left: 3%;
  position: relative;
   
}

.inputField {
  height: 48px;
  border: 1px solid #adc2c2;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 30px;
}

.box {
  width: 300px;
  /* margin: 50px auto; */
  border-radius: 15px;
  background: #DDEDF2;
  color: black;
  padding: 5px;
  text-align: center;
  font-weight: 300;
  font-family: arial;
  position: relative;
}

.box2 {
  width: 300px;
  /* margin: 90px; */
  margin-left: 60%;
  border-radius: 15px;
  background: #fff;
  color: 'black';
  padding: 20px;
  text-align: center;
  font-weight: 600;
  font-family: arial;
  position: relative;
}

.sb1:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid #fff;
  border-right: 15px solid transparent;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  right: -16px;
  top: 0px;
}

.sb14:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 15px solid transparent;
  border-right: 15px solid #fff;
  border-top: 15px solid #fff;
  border-bottom: 15px solid transparent;
  left: -16px;
  top: 0px;
}


.chat_input_container{
  margin-top:-5%
}
@media screen and (min-width:320px) and (max-width:426px) {
  .chat_input_container{
    margin-top:-15%
  }
}

