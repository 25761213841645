.modal-cls{
    width: 458px;
    height: 320px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
}
.header{
    display: flex;
    .close-btn{
        margin-left:auto;
        margin-top:16px;
        margin-right:16px;
    }
}
.success-img-cont-pass{
    margin-top: 20px;
    margin-left: 173px;
    width: 92px;
    height: 92px;
    background: #EFFFF8;
border-radius: 50%;
display: flex;
    justify-content: center;
    align-items: center;
.img-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.img-tick{
    position: absolute;
   
}
}
.font-one{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
margin-left: 54px;
margin-top:19px;
color: #013C44;
}
.font-two{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
/* or 133% */

text-align: center;

/* color/primary/tints/deep-docteal-T1 */
margin-top: 7px;

color: #4D777C;

}

@media (min-width: 300px) and (max-width: 767px) {
    .modal-cls{
        width: auto;
        height: 320px;
        display: flex;
        margin-left: auto;
        margin-right: auto ;
    }
    .success-img-cont-pass{
        margin-top: 20px;
        margin-left: 100px !important;
        width: 92px !important;
    height: 92px;

    }
    .font-one{
        font-size: 16px !important;
        display: flex;
        margin-left: 32px;
        margin-right: 10px;
        text-align: center;
    }
    .font-two{
        font-size: 12px;

    }
   
}