.lab-health-record {
  .container-pres-tab-lab {
    max-width: 1093px;
    height: 100%;
    // margin-left:62px;
    // margin-top:94px;
    margin-bottom: 186px;
    background: #ffffff;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    .info-container {
      // width: 1039px;
      width: 95%;
      min-height: 47px;
      background: #effff8;
      border: 1px solid #c4f0dd;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      margin-left: 26px;
      margin-top: 28px;
      justify-content: flex-start;
      align-items: center;
      .info-img {
        padding-left: 24px;
      }
      .info-text {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        /* identical to box height, or 137% */
        padding-left: 8px;
        padding-top: 5px;
        /* color/primary/docteal */

        color: #00839b;
      }
    }
    .card-align-cont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 28px;
      .card-cont-pres {
        width: 329px;
        height: 322px;
        background: #ffffff;
        border: 1px solid #d9edf1;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 32px;
        margin-left: 26px;
        .badge-cont-outer {
          display: flex;
          flex-direction: row;
          padding-left: 28px;
          padding-top: 28px;
          .badge-cont {
            width: 68px;
            height: 22px;
            background: #f3f4f6;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;
            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              /* identical to box height, or 129% */
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              color: #991b1b;
            }
          }
        }
        .card-content-det-wrapper {
          padding-left: 30px;
          padding-top: 32px;

          .card-content-det-out {
            display: flex;
            flex-direction: column;
            .card-content-det {
              padding-top: 5px;
              .card-font {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
        .pending-btn {
          width: 272px;
          height: 44px;
          border: 1px solid #66b5c3;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 63px !important;
          margin-left: 28px;
          span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            /* identical to box height, or 107% */

            color: #00839b;
          }
        }
        .pending-cont-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 63px !important;
          margin-left: 28px;
          .pending-button {
            width: 137px;
            height: 47px;
            background: #00839b;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          .view-button {
            width: 137px;
            height: 44px;
            border: 1px solid #66b5c3;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              color: #00839b;
            }
          }
          .icon-cont {
            width: 58px;
            height: 44px;
            border: 1px solid #66b5c3;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            img {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}
.ContainerWrapper {
  position: relative;
  margin-top: 50px;
  margin-right: 50px;
  .Closepopup {
    position: absolute;
    transform: scale(1.5);
    top: -30px;
    right: -30px;
    cursor: pointer;
  }
}
.pdfiframe {
  height: 75vh;
  width: 96%;
  margin: 25px;
  border: 0px;
  display: block;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .lab-health-record {
    .container-pres-tab-lab {
      width: 620px;
      margin-left: 0px !important;
      .info-container {
        width: 580px;
        .info-img {
          padding-left: 24px;
        }
        .info-text {
          font-size: 12px;
        }
      }
      .card-align-cont {
        display: flex;
        justify-content: center;
        .card-cont-pres {
          width: 229px;
          height: 322px;
          .badge-cont-outer {
            .badge-cont {
              width: 68px;
              height: 22px;
              span {
                font-size: 10px;
              }
            }
          }
          .card-content-det-wrapper {
            .card-content-det-out {
              .card-content-det {
                .card-font {
                  font-size: 12px;
                }
              }
            }
          }
          .pending-btn {
            width: 100px;
            height: 44px;
            margin-top: 33px !important;
            span {
              font-size: 10px;
            }
          }
          .pending-cont-row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 33px !important;
            margin-left: 28px;
            .pending-button {
              width: 100px;
              height: 37px;
              span {
                font-size: 10px;
              }
            }
            .view-button {
              width: 100px;
              height: 37px;
              span {
                font-size: 10px;
              }
            }
            .icon-cont {
              width: 40px;
              height: 37px;
            }
          }
        }
      }
    }
  }
  .ContainerWrapper {
    position: relative;
    margin-top: 50px;
    margin-right: 50px;
    .Closepopup {
      position: absolute;
      transform: scale(1.5);
      top: -30px;
      right: -30px;
      cursor: pointer;
    }
  }
  .pdfiframe {
    height: 75vh;
    width: 96%;
    margin: 25px;
    border: 0px;
    display: block;
  }
}

.upcomming {
  position: relative;
  width: 513px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
  border-radius: 5px;
  padding: 20px 35px;
  .closePop {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info {
    display: block;
    margin: 20px auto;
  }

  .heading {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 10px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #013c44;
  }
  .txt {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #013c44;
  }
  .btn {
    width: 160px;
    height: 49px;
    background: #00839b;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
  }
}
@media (min-width: 375px) and (max-width: 426px) {
  .info-container {
    width: 83% !important;
  height: 67px;
  }
// .card-cont-pres{
//   width: 271px !important;
// }
}