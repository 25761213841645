.radioComponent2{position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    input[type=checkbox] {
        -webkit-appearance: none;
       
    }
    label.newRadio {
        display: flex;
        align-items: flex-start;
        gap: 10px;

        img{
            position: absolute;
            top: 3px;
            margin-right: 10px;
        }
    }
    .radioOn{
        display: none;
    }
    input[type=checkbox]:checked + label.newRadio{
        .radioOn{
            display: block;
            
        }
        .radioOff{
            display: none;
        }
    }
    .radioName{
        font-size: 14px;
        color: #013C44;
        font-family: "VisueltMedium";
    }
}
