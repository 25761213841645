.order-details {
  // height: 20vh;
  margin-left: 6%;
  color: #143656;
}

.img-synlab {
  height: 40px;
  margin-left: 40px;
}
.order-date-type {
  margin-left: 4%;
  font-size: small;
  margin-top: 4%;
}
.order-tracking-paid {
  background-color: #f3f8ff;
  color: #143656;
}
.individual-tests {
  margin-left: 6%;
  color: #143656;
}

.test-orders {
  margin: 4%;
  background-color: #f3f8ff;
  height: auto;
  margin-left: 0%;
  border-radius: 10px;
  width: 88%;
}
.test-22 {
  display: flex;
  flex-direction: row;
  margin-top: 25px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
}

.test-33 {
  margin-left: 350px;
  width: 300px;
  margin-bottom: 5%;

  @media (max-width: 900px) {
    margin-left: 10px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 440px) {
  .test-22 {
    display: flex;
    flex-direction: column;
    margin-left: 5%;
  }
  .test-33 {
    margin-left: 10px;
    margin-top: 20px;
    width: 300px;
  }
}
