.modal-content-feedback {
    max-width: 627px;
    height: 1530px;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
    .container-feedback{
 
    
        .header {
            display: flex;
            justify-content: space-between;
            .feedback-header-text {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 32px;
                display: flex;
                align-items: center;
        
                color: #013c44;
                margin-top: 3.9%;
                margin-left: 32px;
            }
            .close-btn {
                margin-right: 33px;
                margin-top: 3.9%;
                cursor: pointer;
            }
        }
        .divider {
            border-bottom: 1px solid #d9edf1;
            margin-top: 3.4%;
        }
        .second-header-cont{
            display: flex;
            flex-direction: column;
            .second-header {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;
                color: #013c44;
                margin-left: 33px;
                margin-top: 4.5%;
            }
            .second-header-subtext {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                color: #4d777c;
                margin-left: 33px;
                margin-top: 11px;
                span {
                    display: block;
                }
            }
        }
        
        .feedback-one-cont {
            display: flex;
            flex-direction: column;
            .feedback-one {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #013c44;
                margin-top: 37px;
                margin-left: 33px;
            }
            .rating-cont {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 12px;
                margin-left: 53px;
                img {
                    padding-right: 7px;
                }
            }
        }
        .feedback-two-cont {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .feedback-two {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                color: #013c44;
                margin-top: 32px;
                margin-left: 31px;
            }
            .feedback-two-options {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
                margin-left: 53px;
                img {
                    padding-right: 9px;
                }
                .option-text {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    /* or 129% */
        
                    color: #013c44;
                    margin-top: 5px;
                }
            }
            .number-box-cont {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                margin-top: 16px;
                margin-left: 53px;
                .number-box-cls {
                    width: 42px;
                    height: 56px;
                    background: #ffffff;
                    border: 1px solid #adc2c2;
                    box-sizing: border-box;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    cursor: pointer;
                }
                .boxselected {
                    background-color: #00839B;
                }
                .selecttxt{
                    color: #fff !important;
                }
                .number-box-cls:first-child {
                    border-radius: 5px 0px 0px 5px;
                }
                .number-box-cls:nth-last-child(1) {
                    border-radius: 0px 5px 5px 0px;
                }
                .number-class {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    /* identical to box height, or 129% */
        
                    color: #013c44;
                }
            }
            .text-area-cont {
                margin-top: 16px;
                margin-left: 53px;
                .text-area-cls {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #143656;
                    background: #ffffff;
                    border: 1px solid #adc2c2;
                    box-sizing: border-box;
                    border-radius: 5px;
                    width: 425px;
                    height: 163px;
                    padding-left: 15px;
                    padding-top: 15px;
                }
            }
        }
        
        .feedback-input {
            max-width: 425px;
            height: 48px;
            background: #ffffff;
            border: 1px solid #adc2c2;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 18px;
            margin-left: 53px;
            input {
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                -ms-appearance: none;
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 18px;
                height: 48px;
                width: 100%;
                padding-left: 15px;
                color: #143656;
                border-radius: 5px;
                height: 46px;
            }
        }
        .footer-cls{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            .submit-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background: #00839b;
                border-radius: 5px;
                max-width: 207px;
                height: 49px;
                margin-top: 6.1%;
                margin-left: 53px;
                cursor: pointer;
                padding: 8px;
                span {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    color: #ffffff;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }
            .footer-cont {
                max-width: 503px;
                height: 97px;
                background: #effff8;
                border: 1px solid #c4f0dd;
                box-sizing: border-box;
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-top: 6.9%;
                margin-left: 20px;
                margin-right: 20px;
                padding: 10px;

                .footer-dir {
                    display: flex;
                    flex-direction: column;
                }
                .font-cls-one {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-decoration-line: underline;
                    color: #013c44;
                    margin-left: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                .font-cls-two {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-decoration-line: underline;
                    color: #013c44;
                    margin-top: 11px;
                    margin-left: 20px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
            }
        }
        }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .modal-content-feedback {
        
        max-height: 650px;
        overflow: auto;
        .container-feedback{

            .feedback-input {
                max-width: 325px;
                height: 48px;
            }
            .feedback-two-cont {
                .number-box-cont {
                    .number-box-cls {
                        width: 32px;
                    }
                }
                .text-area-cont {
                    .text-area-cls {
                        width: 325px;
                    }
                }
            }
            .footer-cls{
            .submit-btn {
                max-width: 107px;
                margin-top: 1.5%;
                span {
                    font-size: 10px;
                }
            }
            .footer-cont {
                // width: 303px;
                // height: 37px;
                // margin-top: 3.9%;
                // margin-left: 53px;
                margin-left: 20px !important;
                margin-right: 20px !important;
                margin-bottom: 20px !important;
                padding: 10px;
                .footer-dir {
                    display: flex;
                    flex-direction: column;
                }
               
            }
        }
        }
    }

}
@media (min-width: 300px) and (max-width: 767px) {
    .modal-content-feedback {
        width: 100%;
        max-height: 650px;
        overflow: auto;
        .container-feedback{

            .header {
                .feedback-header-text {
                    font-size: 18px;
                }
                .close-btn {
                    margin-right: 10px;
                    margin-top: 3%;
                }
            }
            .divider {
                border-bottom: 1px solid #d9edf1;
                margin-top: 3.4%;
            }
            .second-header-cont{
                display: flex;
                flex-direction: column;
            .second-header {
                font-size: 12px;
            }
            .second-header-subtext {
                font-size: 10px;
                span {
                    display: block;
                }
            }
        }
            .feedback-one-cont {
                .feedback-one {
                    font-size: 11px;
                }
            }
            .feedback-two-cont {
                .feedback-two {
                    font-size: 11px;
                }
                .feedback-two-options {
                    .option-text {
                        font-size: 11px;
                        line-height: 18px;
                    }
                }
                .number-box-cont {
                    .number-box-cls {
                        width: 23px;
                    }
                    .number-class {
                        font-size: 10px;
                    }
                }
                .text-area-cont {
                    .text-area-cls {
                        width: 220px;
                    }
                }
            }
        
            .feedback-input {
                max-width: 220px;
            }
            .submit-btn {
              
                margin-top: 3.1%;
                span {
                    font-size: 10px;
                }
            }
            .footer-cont {
                // width: 220px;
                // height: 67px;
                // margin-top: 6.9%;
                margin-left: 10px !important;
                margin-right: 10px !important;
                margin-bottom: 10px;
                .font-cls-one {
                    font-size: 8px;
            

                }
                .font-cls-two {
                    font-size: 8px;

                }
            }
        }
    }

}