.dropdown{
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #013C44;
    
    .Dropdown-noresults{
        padding: 15px;
        font-size: 13px;
    }
    .dropdownMenu{
        position: absolute;
        display: block;
        background: #FFFFFF;    
        cursor: pointer;
        border: 1px solid #D9EDF1;
        top: 50px;
        width: 100%;
        z-index: 1;
        overflow: auto;
        // max-height: 200px;
    
    }
    
    .myControlClassName{
        // margin: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 48px;
        border: 1px solid #ADC2C2;
        border-radius: 5px;

        .Dropdown-arrow-wrapper{
            img{
                position:absolute;
                right: 10px;
                top: 20px;
            }
        }
    }
    
    .Dropdown-option{
        padding: 10px;
        
        &:hover{
            background: #F2F8F9;
        }
    }
}

.dropdown-health-record{
    min-width:  225px;
    height: 48px;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #013C44;  
    .myControlClassName{
        // margin: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 48px;
        border: 1px solid #ADC2C2;
        border-radius: 5px;

        .Dropdown-arrow-wrapper{
            img{
                position:absolute;
                right: 10px;
                top: 20px;
            }
        }
    }
     .Dropdown-option{
        padding: 10px;
        
        &:hover{
            background: #C4F0DD;;
        }
    }
}
// @media (min-width: 768px) and (max-width: 1279px) {
//     .dropdown-health-record{
//      width: auto;
//      font-size: 10px;
//      .Dropdown-arrow-wrapper{
//          width: auto;
//         img{
//             position:absolute;
//             right: 10px;
//             top: 20px;
//         }
//     }
//     }
    

// }
@media (min-width: 300px) and (max-width: 767px) {
    .dropdown-health-record{
        min-width:  225px;
        height: 48px;
        position: relative;
        align-items: flex-start;
        justify-content: flex-start;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: 'VisueltMedium';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #013C44;  
        display: flex;
        .myControlClassName{
            // margin: 17px;
            max-width: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            height: 48px;
            border: 1px solid #ADC2C2;
            border-radius: 5px;
    
            .Dropdown-arrow-wrapper{
                img{
                    position:absolute;
                    right: 10px;
                    top: 20px;
                }
            }
        }
         .Dropdown-option{
            padding: 10px;
            
            &:hover{
                background: #C4F0DD;;
            }
        }
    }
}