.modal-cls-upload-popup{
    width: 458px;
    height: 423px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
    .upload-pop-container{
        display: flex;
        flex-direction: column;
       
    .header{
        display: flex;
        .close-btn{
            margin-left:auto;
            margin-top:16px;
            margin-right:16px;
            cursor: pointer;
        }
    }
    .upload-del-img-cont{
        display: flex;
        align-items: center;
        justify-content: center;
    .success-img-cont-pass-confirm{
        margin-top: 20px;
        // margin-left: 173px;
        width: 92px;
        height: 92px;
        background: #EFFFF8;
    border-radius: 50%;
    display: flex;
        justify-content: center;
        align-items: center;
    .img-wrap{
        display: flex;
        justify-content: center;
       align-items: center;
        position: relative;
        width: 104px;
    height: 104px;
    border-radius: 50%;
    background: #F2F8F9;
    }
    .img-tick{
        position: absolute;
       
    }
    }
}
 .font-cont{
     display: flex;
     align-items: center;
     flex-direction: column; 
    .header-font{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    color: #013C44;
    margin-top: 25px;
    }
    .font-one{
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        /* or 133% */
        padding-top:6px;
        text-align: center;
        // margin-left:89px;
        display: flex;
    // align-items: center;
    // justify-content: center;
        /* color/primary/tints/deep-docteal-T1 */
        
        color: #4D777C;
        span{
            display: block;
            text-align: center;
        }
    }
}
    .btn-cont{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-top:29px;
       padding-bottom: 52px;
        .yes-btn{
            width: 139px;
    height: 49px;
    background: #00839B;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    }
        }
        .no-btn{
            width: 135px;
    height: 49px;
    border: 1px solid #00839B;
    box-sizing: border-box;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    span{
        font-family: "VisueltRegular";
        font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    
    
    color: #00839B;
    
    }
        }
    }
}
}


@media (min-width: 768px) and (max-width: 1279px) {

}

@media (min-width: 300px) and (max-width: 767px) {
    .modal-cls-upload-popup{
        width: 300px;
        height: 423px;
    .upload-pop-container{
        .upload-del-img-cont{
        .success-img-cont-pass-confirm{
            margin-top: 20px;
            display: flex;
           justify-content: center;
            // margin-left: 100px !important;
        //     width: 92px !important;
        // height: 92px;
        .img-wrap{
            display: flex;
            justify-content: center;
           align-items: center  ;
            position: relative;
            width:50px;
            height:50px;
        }
        .img-tick{
            position: absolute;
           
        }
        }
    }
     .font-cont{
        .header-font{
        font-size: 24px;
        display: flex;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        }
        .font-one{
            font-size: 18px !important;
            display: flex;
         margin-left: auto;
         margin-right: auto;
        text-align: center;
    
        }
    }
        .btn-cont{
           padding-bottom: 52px !important;
            .yes-btn{
                width: 72px;
        height: 39px;
        span{
        font-size: 12px;
        }
            }
            .no-btn{
                width: 70px;
        height: 39px;
        span{
          
        font-size: 12px;
        
        }
            }
        }
    }
}
}