
    .container-filter{
        max-width: 1093px;
min-height: 124px;
background: #FFFFFF;
border: 1px solid #D9EDF1;
box-sizing: border-box;
border-radius: 5px;
margin-top: 20px;
margin-bottom: 18px;
flex-wrap: wrap;
display: flex;
flex-direction: row;
justify-content: space-evenly;
align-items: center;
padding-bottom: 26px;
// .filter-row{
padding-top: 10px;;   
 
    .filter-text{
        font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 21px;
line-height: 113%;
/* identical to box height, or 24px */
letter-spacing: -0.03em;
color: #013C44;
margin-top: 3%;

    }
// }
    }
    @media (min-width: 768px) and (max-width: 1279px) {
        .container-filter{
    justify-content: space-evenly;
    align-items: center;
    padding: 26px 26px 26px 0;
    flex-wrap: wrap;
    .filter-text{
    margin-top: 4%;
    margin-left: 4%;
    }
}
    }
    @media (min-width: 300px) and (max-width: 767px) {
        .container-filter{
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .filter-text{
            margin-top: 4%;
            }
        }
    }