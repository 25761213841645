.SelectTimeSlote,.SadnSelectTimeSlote{
    width: 502.81px;
    height: 291px;
    background: #F2F8F9;
    margin-top: 6%;
    border-radius: 5px;
    padding: 20px 30px;
   
    .dropHolder{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .dropWrap{
            width: 32%;
        }
    }
    .button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #00839B;
        line-height: 107%;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        // padding: 3% 5% 2.8%;
        cursor: pointer;
        width: 138px;
        height: 44px;        
        border: 2px solid #00839B;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 4%;

        &:hover{
            color: #FFFFFF;
            background-color: #00839B;

        }
    }

   
}
.select-box{
    width: 300px;
    background: #F2F8F9;
    margin-top: 1%;
    border-radius: 5px;
    padding: 20px 30px;
    .show-patient-cls{
        width: 100%;
        background-color: #FFFFFF;
        height:48px ;
        border: 1px solid #ADC2C2;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'VisueltMedium';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 18px;
        color: #013C44;
    }
    }
}
.selectSpecial{
    width: 300px;
    background: #F2F8F9;
    margin-top: 1%;
    border-radius: 5px;
    padding: 20px 30px;
    .dropHolder{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .dropWrap{
            width: 100%;
        margin: 2% 0%;


        }
    }
    .button{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #00839B;
        line-height: 107%;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        // padding: 3% 5% 2.8%;
        cursor: pointer;
        width: 138px;
        height: 44px;        
        border: 2px solid #00839B;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 4%;

        &:hover{
            color: #FFFFFF;
            background-color: #00839B;

        }
    }
    .disable_button{
        display: none;
       

    }
}
.SelectTimeSlotSpecial{
    width: 348px;
    // height: 217px;
    background: #F2F8F9;
    margin-top: 6%;
    border-radius: 5px;
    padding: 20px 30px;
    .dropHolder{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .dropWrap{
            width: 100%;
        margin: 2% 0%;


        }
    }
}


.SadnSelectTimeSlote{
    height: auto;
    margin-top:2%;
}
@media (min-width: 768px) and (max-width: 1279px) {
    .SelectTimeSlote,.SadnSelectTimeSlote{
        width: 100%;
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .SelectTimeSlote,.SadnSelectTimeSlote{
        width: 100%;
    }
}