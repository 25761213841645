.buttonConfirm{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.972vw;
    color: #FFFFFF;
    background-color: #00839B;
    
    line-height: 107%;
    font-family: 'VisueltRegular';
    font-size: 14px;
    line-height: 15px;
    font-style: normal;
    font-weight: normal;
    padding: 1% 3% 0.8%;
    cursor: pointer;
    width: auto;
    height: 44px;       
    border: 2px solid #00839B;
    box-sizing: border-box;
    border-radius: 5px;
    text-transform: uppercase;
    &:hover{
        color: #00839B;
        background: transparent;

    }
}
.buttonTwo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #00839b;
    line-height: 107%;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    padding: 1% 3% 0.8%;
    cursor: pointer;
    width: auto;
    text-transform: uppercase;
    border: 2px solid #00839b;
    box-sizing: border-box;
    border-radius: 5px;

    &:hover {
        color: #ffffff;
        background-color: #00839b;
    }
}
.btn-primary.disabled, .btn-primary:disabled{
    background-color: #078CA5;
}