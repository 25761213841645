html,
body {
    min-width: auto !important;
    min-height: auto !important;
}

.doctors-page {
    width: 100%;
    padding: 10px;
    overflow-x: hidden;
}

.doctors-header {
    padding: 35px;
    background: #f2f8f9;
}

.doctors-header-link {
    display: flex;
    justify-content: flex-end;
}

.doctors-section {
    padding-top: 250px;
    padding-bottom: 212px;
    background-repeat: no-repeat;
    background-color: rgba(3, 35, 39, 0.76);
    background-size: 104% 80% 125%;
    /* mix-blend-mode: multiply; */
    background-blend-mode: multiply;
    position: relative;
}

.doctors-section-col1 {
    font-style: normal;
    font-weight: normal;
    font-size: 43px;
    line-height: 50px;
    letter-spacing: -0.3px;
    color: #ffffff;
}

.doctors-section-col2 {
    padding-bottom: 25px;
}

.doctors-section2 {
    position: relative;
    top: -105px;
}

.doctors-box {
    padding-right: 25px;
    padding-left: 25px;
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 14px 5px rgba(3, 130, 153, 0.08);
    margin-left: 3px;
    margin-right: 3px;
}

.doctors-box-logo {
    text-align: center;
    padding: 0px;
}

.doctors-box-container {
    margin-top: 25px;
    margin-bottom: 25px;
    border: 2px solid rgb(55, 204, 140);
    border-radius: 10px;
    padding: 25px;
    cursor: pointer;
}

.doctors-box-text1 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 110%;
    letter-spacing: -0.415271px;
    color: #013c44;
    margin-bottom: 10px;
}

.doctors-box-text2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #4d777c;
}

.doctors-section3 {
    margin-bottom: 72px;
}

.doctors-section3-title {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    /* or 117% */
    text-align: center;
    color: #013c44;
}

.doctors-section4 {
    margin-bottom: 85px;
}

.doctors-section4-box {
    margin-top: 25px;
    text-align: center;
}

.doctors-section5-link {
    text-align: center;
    display: flex;
    justify-content: center;
}

.doctors-section5 {
    margin: 25px;
    padding: 25px;
}

.doctors-section6 {
    background: #f2f8f9;
    padding-top: 82px;
    padding-bottom: 82px;
}

.doctors-section6-title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    font-family: "VisueltMedium";
    color: #013c44;
}

.doctors-section6-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #013c44;
    font-family: "VisueltRegular";
}

.doctors-search {
    background: #ffffff;
    border: 1px solid #e3ebf6;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.doctors-section6-filter {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    cursor: pointer;
}

.doctors-section6-text2 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}

.filter-section-form {
    background: #ffffff;
    border-radius: 5px;
    padding: 10px;
    margin: 2px;
}

.doctors-section7 {
    background: #f2f8f9;
    padding-bottom: 82px;
    .pagination {
        justify-content: center;
    }
}

.profile-row {
    width: 100%;
    margin: 0px;
    justify-content: center;
}
.header-butoon{
    padding:0px;
}

@media (min-width: 992px) {
    .doctors-box {
        .middle {
            margin: 25px;
        }
    }
}
.doctors-search {
    input[type="text"] {
        outline: none !important;
        outline-offset: 0px !important;
    }
    input[type="text"]:focus {
        outline: none !important;
        outline-offset: 0px !important;
    }
   
}

@media (max-width: 426px) {
    .header-butoon{
        padding:10px;
    }
    .doctors-section {
        padding: 20px;
     
    }
}