.secondaryLabel{
    font-family: 'VisueltRegular';
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 25px;
/* identical to box height, or 133% */


color: #013C44;
}