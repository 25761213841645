.freeBooking{
    padding: 2% 4%;
    
    .h4{
        margin-top: 20px;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        width: 730.87px;
        line-height: 133%;
        color: #013C44;
    }

    .buttonConfirm{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 0.972vw;
        color: #FFFFFF;
        background-color: #00839B;
        
        line-height: 107%;
        font-family: 'VisueltRegular';
        font-size: 14px;
        line-height: 15px;
        font-style: normal;
        font-weight: normal;
        padding: 1% 1% 0.8%;
        cursor: pointer;
        width: 218px;
        height: 44px;       
        border: 2px solid #00839B;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 30px;
        &:hover{
            color: #00839B;
            background: transparent;

        }
    }
    .containerWrapper{
        display:  flex;
        flex-direction: row;
        .componentContainer{
            margin-right: 11px;
            .Dropdown-placeholder{
                font-size: 14px !important;
            }
        }
        .componentContainer2{
        width: 150px;
                    display:  flex;
            flex-direction: row;
    
    }

    .appointmenttip{
        display:  flex;
        flex-direction: row;

    }
    }

   

    @media (min-width: 768px) and (max-width: 1279px) {
        .h4{
            width: 90%;
            font-size: 16px;
        }
        .containerWrapper{
            flex-direction: column;
            .componentContainer ,.componentContainer2{
                margin-right: 0px;
             
            }
            .componentContainer2,.appointmenttip{
           
                width: 100%;

            }
        }
    }

    @media (min-width: 300px) and (max-width: 767px) {
        .h4{
            width: 90%;
            font-size: 13px;
        }
        .containerWrapper{
            flex-direction: column;
            .componentContainer,.componentContainer2{
                margin-right: 0px;
            }
            .componentContainer2{
                display: flex;
                flex-direction:row;
                width: 100%;

            }

          
           
        }
    }
}