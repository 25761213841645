.Symptomsdetails {
    width: 640.85px;
    height: 152.73px;
    background: #effff8;
    border: 1px solid #c4f0dd;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 3% 4%;
    margin-left: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    .container {
        width: 48%;
        .title {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 137%;

            color: #046673;
        }
        .value {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 133%;
            padding-top: 10px;
            color: #013c44;
        }
    }
}
@media (min-width: 768px) and (max-width: 1279px) {
    .Symptomsdetails {
        height: 136px;
        .container {
            .title {
                font-size: 14px !important;
            }
            .value {
                font-size: 16px !important;
            }
        }
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .Symptomsdetails {
        max-width: 100% !important;
        width: 100% !important;
        height: auto;
        .container {
            .title {
                font-size: 12px !important;
            }
            .value {
                font-size: 14px !important;
            }
        }
    }
}
