.bookdetails {
    max-width: 640.85px;
    height: 315.27px;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f2f8f9;
    padding: 3% 4%;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px;
    margin-bottom: 20px;
    .containers {
        width: 278px;
        .title {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;
            color: #046673;
        }
        .value {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 133%;
            color: #013c44;
        }
    }
}
@media (min-width: 768px) and (max-width: 1279px) {
    .bookdetails {
        height: 250px;
        .container {
            .title {
                font-size: 16px !important;
            }
            .value {
                font-size: 20px !important;
            }
        }
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .bookdetails {
        max-width: 100% !important;
        width: 100% !important;
        height: auto;
        .container {
            .title {
                font-size: 14px !important;
            }
            .value {
                font-size: 18px !important;
            }
        }
    }
}
