.radioComponent{
    display: flex;
    justify-content: center;
    align-items: center;
    input[type=radio] {
        -webkit-appearance: none;
    }
    label.newRadio {
        display: flex;
        align-items: flex-start;
        gap: 10px;
    }
    .radioOn{
        display: none;
    }
    input[type=radio]:checked + label.newRadio{
        .radioOn{
            display: block;
        }
        .radioOff{
            display: none;
        }
    }
    .radioName{
        font-size: 14px;
        color: #013C44;
        font-family: 'VisueltRegular';
    }
}

@media (min-width: 320px) and (max-width: 426px) {
    .radioName{
        font-size: 14px;
        color: #013C44;
        font-family: 'VisueltRegular';
        width: 78%!important;
    }
}
