
.modal-cls-confirm-pop-change-passcode{
    width: 458px;
    min-height: 320px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
    .change-passcode-confirm-cont{
  
        .header{
            display: flex;
            .close-btn{
                margin-left:auto;
                margin-top:16px;
                margin-right:16px;
                cursor: pointer;
            }
        }
        .success-img-cont-pass-confirm{
            margin-top: 20px;
            margin-left: 173px;
            width: 92px;
            height: 92px;
            background: #EFFFF8;
        border-radius: 50%;
        display: flex;
            justify-content: center;
            align-items: center;
        .img-wrap{
            display: flex;
            justify-content: center;
           align-items: center;
            position: relative;
        }
        .img-tick{
            position: absolute;
           
        }
        }
        .font-one{
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            /* or 133% */
            padding-top:17px;
            text-align: center;
            margin-left:89px;
            /* color/primary/tints/deep-docteal-T1 */
            
            color: #4D777C;
            
        }
        .btn-cont{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-top:29px;
            margin-bottom: 29px;
            .yes-btn{
                width: 139px;
        height: 49px;
        background: #00839B;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
            font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        /* identical to box height, or 100% */
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        }
            }
            .no-btn{
                width: 135px;
        height: 49px;
        border: 1px solid #00839B;
        box-sizing: border-box;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        span{
            font-family: "VisueltRegular";
            font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        /* identical to box height, or 100% */
        
        
        color: #00839B;
        
        }
            }
        }
    }
}


@media (min-width: 300px) and (max-width: 767px) {
    .modal-cls-confirm-pop-change-passcode{
        width: auto;
        height: 320px;
        .change-passcode-confirm-cont{
            .success-img-cont-pass-confirm{
                margin-top: 20px;
                margin-left: 100px !important;
                width: 92px !important;
            height: 92px;
            .img-wrap{
                display: flex;
                justify-content: center;
               align-items: center  ;
                position: relative;
            }
            .img-tick{
                position: absolute;
               
            }
            }
            .font-one{
                font-size: 16px !important;
            }
            .btn-cont{
               padding-bottom: 52px !important;
                .yes-btn{
                    width: 72px;
            height: 39px;
            span{
            font-size: 12px;
            }
                }
                .no-btn{
                    width: 70px;
            height: 39px;
            span{
              
            font-size: 12px;
            
            }
                }
            }
        }
    }
  
}