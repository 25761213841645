.video-container {
    width: 312.04px;
    height: 71px;
    background: #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 5px 8px 2px rgba(0, 131, 155, 0.08);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    .container-left {
        background: #2d8cff;
        border-radius: 5px 0px 0px 5px;
        width: 84px;
        display: flex;
        justify-content: center;
        align-items: center;
        .blur1 {
            width: 54px;
            height: 54px;
            background: rgba(46, 134, 240, 0.43);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .blur2 {
            width: 44px;
            height: 44px;
            background: rgba(55, 129, 219, 0.69);
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        img {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .container-right {
        display: flex;
        flex-direction: column;
        border: 1px solid #66b5c3;
        width: 100%;
        border-radius: 0px 5px 5px 0px;
        .top-text {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #013c44;
            padding-top: 14px;
            padding-left: 14px;
        }
        .bottom-text-container {
            display: flex;
            flex-direction: row;
            padding-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            padding-left: 14px;
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                .time-text {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 133%;
                    color: #00839b;
                    margin-left: 6.5px;
                }
                .icon {
                }
                .icon2 {
                    margin-left: 10px;
                }
            }
        }
    }
    .disablelayer {
        position: absolute;
        width: 312.04px;
        height: 71px;
        background-color: #ffffff8c;
        cursor: not-allowed;
    }
    .infoimg{
        position: absolute;
        right: -25px;
        top: calc(50% - 8px);
        cursor: pointer;
    }
    .showinfo{
        position: absolute;
        width: 264px;
        height: 60px;
        background: #FFFFFF;
        opacity: 0.95;
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 14px;
        padding-right: 14px;
        right: -130px;
        top: calc(50% - -18px);
        div{
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 133%;
            color: #013C44;
        }
    }
    .showinfo:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 40%;
        border-width: 0 6px 6px 6px;
        border-style: solid;
        border-color: #fff transparent;
      }
}
@media (min-width: 768px) and (max-width: 1279px) {
    .video-container {
        width: 270.04px;
        height: 71px;
        .container-left {
            width: 79px;
            .blur1 {
                width: 50px;
                height: 50px;
            }
            .blur2 {
                width: 40px;
                height: 40px;
            }
        }
        .container-right {
            .top-text {
                font-size: 14px;
            }
            .bottom-text-container {
                div {
                    .time-text {
                        font-size: 10px;
                    }
                }
            }
        }
        .disablelayer {
            width: 270.04px;
            height: 71px;
        }
        .showinfo{
            width: 226px;
            height: 50px;
            right: -112px;
            div{
                font-size: 10px;
            }
        }
        .showinfo:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            right: 40%;
            border-width: 0 6px 6px 6px;
            border-style: solid;
            border-color: #fff transparent;
          }
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .video-container {
        width: 200.04px;
        height: 54px;
        .container-left {
            width: 75px;
            .blur1 {
                width: 48px;
                min-height:48px;
            }
            .blur2 {
                width: 38px;
                height: 38px;
            }
        }
        .container-right {
            .top-text {
                font-size: 10px;
                padding-top: 4px;
                padding-left: 8px;
            }
            .bottom-text-container {
                padding-left: 5px;
                padding-top: 6px;
                div {
                    .time-text {
                        font-size: 8px;
                    }
                }
            }
        }
        .disablelayer {
            width: 200.04px;
            height: 54px;
        }
        .showinfo{
            width: 233px;
            height: 41px;
            div{
                font-size: 8px;
            }
        }
        .showinfo:after {
            content: "";
            width: 0;
            height: 0;
            position: absolute;
            bottom: 100%;
            right: 46%;
            border-width: 0 6px 6px 6px;
            border-style: solid;
            border-color: #fff transparent;
          }
    }
}
