
.pagination {
    width: 100%;
    display:flex;
    flex-direction: column;
}

.pagination-footer {
    display: flex;
    flex-wrap: wrap;
}

.pagination-back-button, .pagination-next-button  {
    background: #00839B;
    width: 26px;
    height: 22px;
    border-radius: 5px;
    /* text-align: center; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.pagination-pages {
    display: flex;
    /* flex: 3; */
    padding-right: 15px;
    padding-left: 15px;
    /* font-family: Visuelt; */
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    align-items: center;
    color: #4D777C;
}

.pagination-page {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #013C44;
}

.pagination-input-box {
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    /* width: 25px; */
    margin-right: 15px;
    margin-left: 15px;
    width: 22px;
    height: 22px;
    outline: none !important;
}

.pagination-go-button {
    width: 55px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #00839B;
    border-radius: 5px;
}

.pagination-flex-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: 50px;
}

.pagination-flex-box2 {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}


.pagination-root {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-right: 20px;
    padding-bottom: 112px;
    width: 100%;
    padding-top: 30px;
}
.pagination-no-records{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}

@media screen and (max-width: 440px) {
    .pagination-root {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding-right: 20px;
        padding-bottom: 112px;
        width: 100%;
        padding-top: 40px;
    }

}
