@import '../../layout/authLayout.scss';
// .MuiBox-root-18 {
//     padding: 0 !important;
// }
.MuiBox-root-6 {
    padding: 0 !important;
}
// .MuiBox-root-12 {
//     padding: 0 !important;
    
// }
// .MuiBox-root-8 {
//     padding: 0px;
// }
.PrivateTabIndicator-colorPrimary-2 {
    background-color: #00839B !important;
}
.MuiLinearProgress-barColorPrimary {
    background-color: #37CC8C !important;
}
.options{
    outline: none;
    border: none;
    background: none;
    border: 1px solid #ADC2C2;
    border-radius: 5px;
    min-height:48px;
    width: 100%;
    padding: 2% 3%;
}
.inputForm{
    outline: none !important;
    border: none;
    background: none;
    font-family: 'VisueltMedium';
    border: 1px solid #ADC2C2;
    border-radius: 5px;
    min-height:48px;
    width: 100%;
    padding: 2% 3%;
    
}
.inputsForm{
    padding: 3px 13px;
}
.inputForm:focus {
    background-color:white;
  }
  input[type='tel'] {
    outline: none;
    border: none;
    background: none;
    height: 48px !important;
    border: 1px solid #ADC2C2;
    border-radius: 5px;
    width: 100%;
  }
.formSection{
    margin-top: 8px;
    display: flex;
    gap: 14px;
    padding: 0% 2%;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    .innerFormSec{
     display: flex;
     flex: 1;
     flex-direction: column;
     min-width:200px ;
     .formName{
         margin-bottom: 10px;
         font-size: 15px;
         font-family: 'VisueltRegular';
         color: #013C44 ;
         font-weight: 500;
     }
     .inputsForm{
         font-family: 'VisueltRegular';
         outline: none;
         border-radius: 5px;
         border: 1px solid #ADC2C2;
         background-color: #FFFFFF;
         min-height:48px;
        //  min-width: 230px;
     }
        
    }
}

.formSection2{
    margin-top: 30px;
    display: flex;
    gap: 14px;
    padding: 0% 2%;
    flex-direction: row;
    flex-wrap: wrap;
    // min-width: 200px;
    .innerFormSec{
     display: flex;
     min-width: 200px;
     flex: 1;
     flex-direction: column;
     .formName{
         margin-bottom: 10px;
         font-size: 15px;
         font-family: 'VisueltRegular';
         color: #013C44 ;
         font-weight: 500;
     }
     .react-date-picker {
        display: inline-flex;
        position: relative;
        outline: none !important;
        border-radius: 5px;
        border: 1px solid #ADC2C2;
        height:48px;
        width: auto;
        min-width: 205px !important;
    }
     .inputsForm{
         outline: none;
         border-radius: 5px;
         border: 1px solid #ADC2C2;
         height:48px;
         background-color: #FFFFFF;
        //  min-width: 230px;
     }
        
    }
}

.react-date-picker {
    display: inline-flex;
    position: relative;
    outline: none !important;
    border-radius: 5px;
    border: 1px solid #ADC2C2;
    min-height:48px;
    width: auto;
    min-width: 240px;
}


.radioSection {
    display: flex;
    min-height: 48px;
    flex-direction: row;
    background-color: #F2F8F9;
    justify-content: space-between;
    padding: 0 50px 0 15px;
    width: 100%;
    min-height: 48px;
    min-width: 250px;
    border: 1px solid #D9EDF1;
    border-radius: 5px;
    label{
        margin-top: 14px;
    }
}
.radioSection2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: #F2F8F9;
    justify-content: space-between;
    width: 100%;
    min-width: 242px;
    min-height: 123px;
    padding: 8px 20px;
    gap: 10px;
    border: 1px solid #D9EDF1;
    border-radius: 5px;
    label{
        margin-top: 14px;
    }
    .inputsForm{

        margin-top: -10px;
        background-color: #FFFFFF;
        min-width: 100%;
    }
    
}
.radioSection3 {
    display: flex;
    flex-direction: row;
    padding: 8px 20px;
    flex-wrap: wrap;
    background-color: #F2F8F9;
    min-width: 250px;
    justify-content: space-between;
    height: 123px;
    gap: 10px;
    border: 1px solid #D9EDF1;
    border-radius: 5px;
    label{
        margin-top: 14px;
    }
    
        .inputsForm{
            height: 48px !important;
            width: 30%;
            margin-top: -10px;
            background-color: #FFFFFF;
        }

    
}
.radioSection4{
    display: flex;
    flex-direction: row;
    padding: 1% 2% ;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    gap: 30px;
    min-height:48px;
    label{
        margin-top: 10px;
    }
}
.radioSection6{
    display: flex;
    flex-direction: row;
    padding: 1% 2% ;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    min-width: 250px;
    gap: 10px;
    min-height:48px;
    label{
        margin-top: 10px;
    }
    .radioComponent {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-width: 214px;
    }

}
.radioSection5{
    display: flex;
    position: relative;
    flex-wrap: wrap;
    width: 100%;
    margin: 1% 2%;
    // min-width: 250px;
    gap: 10px;
    label{
        margin-top: 10px;
    }

}
/// radio button
///bmi card
.formSection3{
    margin-top: 8px;
    display: flex;
    gap: 14px;
    padding: 0% 2%;
    flex-direction: row;
    flex-wrap: wrap;
    
    .bmiCard{
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        padding: 2% 3%;
        border: 1px solid #D9EDF1;
        background: #F2F8F9;
        border-radius: 5px;
        .formName{
            margin-bottom: 10px;
            font-size: 15px;
            font-family: 'VisueltRegular';
            color: $headColor;
            font-weight: 500;
            width: 100%;
        }
        .bmiInfo {
            display: flex;
            flex-wrap: wrap;
            min-height:48px;
            gap: 1rem;
            flex-direction: row;
            width: 100%;
            .bmiSeperator {
                display: flex;
                flex-wrap: wrap;
                flex: 3;
                // width: 503px;

                justify-content: space-around;
                border: 1px solid #ADC2C2;
                min-height:48px;
                border-radius: 5px;
                padding: 0;
                align-items: center;
                font-family: 'VisueltRegular';
                p{
                    margin: 0%;
                    font-size: 14px;
                    color: $paraColour;
                    min-width: 133px;
                    text-align: center;
                    span{
                        font-family: 'VisueltRegular';
                        color: $headColor;
                    }
                }
            }
            .bmiHistory{
                display: flex;
                outline: none;
                flex: 1;
                font-size: 14px;
                background: none;
                min-height: 48px;
                justify-content: center;
                font-family: 'VisueltRegular';
                align-items: center;
                border-radius: 5px;
                color: #00839B;

                border: 2px solid #00839B;
            }
            .bmiHistory:hover{
                
                background: #ADC2C2;
            }
        }
    }
    
}
.infoBmiPara{
    font-size: 12px;
    font-family: 'VisueltRegular';
    color: #013C44;
    margin-bottom: 2px;
}
.infoBmiLink{
    cursor: pointer;
    font-size: 12px;
    font-family: $fontmedium;
    color: #7bed9f;
    text-decoration: underline;
}
.addWeight{
    height: 44px;
    color: white;
    border: none;
    background: #00839B;
    outline: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-family: 'VisueltRegular';
    align-items: center;
    min-width: 133px;
    &:hover{
        color: #00839B;
        background: white;
        border: 1px solid #00839B !important;
    }
}
.profileUpdate{
    height: 49px;
    color: white;
    background: #00839B;
    outline: none;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-family: $fontnormal;
    align-items: center;
    min-width: 182px;
    margin: 2%;
    &:hover{
        color: #00839B;
        background: white;
        border: 1px solid #00839B !important;
    }
}
.formName {
    margin: 0%;
    font-size: 15px;
    font-family: "VisueltRegular";
    color: #013C44;
}

.form3div{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
    .inputsForm{
        flex: 1;
        outline: none;
        border-radius: 5px;
        border: 1px solid #ADC2C2;
        min-height:48px;
        min-width: 200px;
        margin-right: 10px;
    }

}

.cardFoot{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1085px;
    .cardProfile{
        // flex:25%;
        width: 260px;
    }
    .cardForm{
        flex:75%;

    }
}
.medicineChoice{
    display: flex;
    margin-left: -23px;
    margin-bottom: 20px;
    flex-direction: row;
    width: 1085px;
    z-index: 1;
    // margin-top: -22px;
    flex-wrap: wrap;
    padding: 1% 24px;
    background-color:#F2F8F9;
    border-radius: 0px 5px 5px 5px;
    border-left: 1px solid #D9EDF1;
    border-bottom: 1px solid #D9EDF1;
    border-right: 1px solid #D9EDF1;
    .cardProfile{
        flex:25%;
        min-width: 200px;
    }
    .cardForm{
        flex:75%;

    }
}
.suffixDropdown {
    display: flex;
    .small{
        width: 140px;
    }
}
.fillup{
    .inputsForm{
        border-right-width: 0 !important;
        border-bottom-right-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .dropdown{
        width: 99px;
        .myControlClassName{
            border-left-width: 0;
            border-bottom-left-radius: 0px;
            border-top-left-radius: 0px;
        }
    }
}
.bmiContainerPopup{
    font-family: $fontmedium;
    color: #013C44;
    width: 513px;
    position: relative;
    padding: 20px 30px;
    img{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    }
    .headBmi{
    font-size: 24px;
    font-family: $fontmedium;
    color: #013C44;
    text-align: left;
    margin-bottom: 20px;
    }
    .bmiPopupbox{
        display: flex;
        background: #F2F8F9;
        width: 450px;
        height: 81px;
        border: 1px solid #D9EDF1;
        border-radius: 5px;
        padding: 0 30px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .childWeight{
            .heading{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #4D777C;
                margin-bottom: 2px;
            }
            .value{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 32px;
                color: #013C44;

                span{
                    font-family: VisueltRegular;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 16px;
                    /* identical to box height, or 133% */

                    text-align: center;

                    /* color/primary/tints/deep-docteal-T1 */

                    color: #4D777C;
                }
            }
            h1{
                font-size: 24px;
                font-family: $fontnormal;

            }
        }
        
    }
}

.dropdown{
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #013C44;
    
    .Dropdown-noresults{
        padding: 15px;
        font-size: 13px;
    }
    .dropdownMenu{
        position: absolute;
        display: block;
        background: #FFFFFF;    
        cursor: pointer;
        border: 1px solid #D9EDF1;
        top: 50px;
        width: 100%;
        z-index: 1;
        overflow: auto;
        // max-height: 200px;
    
    }
    
    .myControlClassName{
        // margin: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        height: 48px;
        border: 1px solid #ADC2C2;
        border-radius: 5px;

        .Dropdown-arrow-wrapper{
            img{
                position:absolute;
                right: 10px;
                top: 20px;
            }
        }
    }
    
    .Dropdown-option{
        padding: 10px;
        
        &:hover{
            background: #F2F8F9;
        }
    }
}

.intimation{
    position: relative;
    width: 513px;
    height: 356px;
    .close{
        position: absolute;
        top: 20px;
        right: 15px;
        cursor: pointer;
    }
    .Container{
    display: block;
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #4D777C;
    width: 513px;
    height: 356px;
    margin: auto;
    padding: 30px 70px;
img{
    display: block;
    margin: 0 auto 20px auto;
}
.btn{
    width: 96px;
    height: 44px;
    left: 706.5px;
    top: 505px;
    margin: 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00839B;
    border-radius: 5px;
    color: #FFFFFF;
}
  }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .formSection{
        // flex-direction: column;

        .innerFormSec{
            .inputsForm{
                width: 100%;
            }
        }
    }
    .radioSection3{
        width: 100% !important;
    }

    .formSection2{
        flex-direction: column !important;
    }

    .formSection3{
        .bmiCard{
            .bmiInfo{
                flex-direction: column;
                .bmiSeperator{
                    width: 100%;
                }
                .bmiHistory{
                    width: 133px;
                }
            }
        }
    }
    
    .form3div{
        flex-direction: column;
        .inputsForm{
            margin-bottom: 10px;
        }
        .formSection2{
            flex-direction: column;
    
        }
    }
    .radioSection5{
        width: 65%;
        margin: 0;
    }

    .cardFoot{
        .cardProfile{
            // flex:25%;
            width: 0px;
        }
    }

   
}





@media (min-width: 300px) and (max-width: 767px) {
    .formSection{
        // flex-direction: column;

        .innerFormSec{
            .inputsForm{
                width: 100%;
            }
        }
    }
    .radioSection3{
        width: 100% !important;
    }

    .formSection2{
        flex-direction: column !important;
    }

    .formSection3{
        .bmiCard{
            .bmiInfo{
                flex-direction: column;
                .bmiSeperator{
                    width: 100%;
                }
                .bmiHistory{
                    width: 133px;
                }
            }
        }
    }
    
    .form3div{
        flex-direction: column;
        .inputsForm{
            margin-bottom: 10px;
        }
        .formSection2{
            flex-direction: column;
    
        }
    }
    .cardFoot{
        .cardProfile{
            // flex:0%;
            width: 0px;
        }
    }
    .radioSection5{
        width: 65%;
        margin: 0;
    }
}


.tabs_conatiner{
    background-color: red;
    width:100%;
}
@media (min-width: 300px) and (max-width: 467px) {
    .tabs_conatiner{
        background-color: red;
        width:30%;
    }
    .radioSection5{
        width: 30%;
        margin: 0;
    }
    .medicineChoice{
        width: 30%;
    }
    // .cardFoot{
    //     .cardProfile{
    //         flex:0%;
    //         width: 0px;
    //     }
    // }
}
@media (min-width: 300px) and (max-width: 320px) {
    .formSection{
        // flex-direction: column;

        .innerFormSec{
            .inputsForm{
                width: 85%;
            }
        }
    }
    .react-date-picker {
        width: 82% !important;
    }
}