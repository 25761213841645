.dashboardContainer {
    padding-top: 5%;
    padding-left: 5%;
    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 15.3%;
        .namecontainer {
            margin-bottom: 2%;
            .name {
                font-family: "VisueltRegular";
                font-size: 30px;
                line-height: 38px;
                letter-spacing: -0.3px;
                color: #66b5c3;
                margin-bottom: 6px;
            }
            .welcometext {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 114.6%;
                letter-spacing: -0.3px;
                color: #143656;
            }
        }
    }
    .content-bottom {
        display: flex;
        flex-direction: row;
        margin-top: 2.8%;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .col-cont {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 312px;
        margin-top: 1%;
        margin-right: 22px;
        .text-three {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 129%;
            text-transform: capitalize;
            color: #00839b;
            margin-bottom: 3.5%;
        }
        .text-four {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 133%;
            color: rgba(1, 60, 68, 0.8);
        }
       
        .arrow-cont{
            width: 35px;
            height: 31px;
            background: #66B5C3;
            border-radius: 5px;
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .img-cont{
                display: flex;
                justify-content: center;
                align-items: center;
            }
           img{
               display: flex;
               justify-content: center;
               align-items: center;
           }
        }
    }
    .percent-row{
        display:"flex";
        flex-direction:"row";
        justify-content:"space-between";
    }
    @media (min-width: 768px) and (max-width: 1279px) {
        .wrapper {
            .namecontainer {
                .name {
                    font-size: 24px;
                    line-height: 30px;
                }
                .welcometext {
                    font-size: 20px;
                    line-height: 114.6%;
                }
            }
        }
        .col-cont {
            width: 260px;
            .text-three {
                font-size: 12px;
            }
            .text-four {
                font-size: 20px;
            }
        }
    }
    @media (min-width: 300px) and (max-width: 767px) {
        .wrapper {
            .namecontainer {
                .name {
                    font-size: 20px;
                    line-height: 28px;
                }
                .welcometext {
                    font-size: 17px;
                    line-height: 114.6%;
                }
            }
        }
        .col-cont {
            width: 200px;
            .text-three {
                font-size: 10px;
            }
            .text-four {
                font-size: 17px;
            }
        }
    }
}
.click-temp{
    position: absolute;
width: 75.4px;
height: 75.4px;
left: 1276.66px;
top: 747.64px;

background: #00839B;
box-shadow: 0px 0px 15.2615px 2.34792px rgba(0, 131, 155, 0.47);
}