@import '../../layout/authLayout.scss';
.profileTitle {
    padding: 3% 0 0 3%;
    font-size: 30px;
    font-family: 'VisueltMedium';
    color: #013C44;
    margin-left: 20px;
}
.progress {
    display: flex;
    height: 3px !important;
    overflow: hidden;
    line-height: 0;
    width: 143px;
    font-size: .75rem;
    background-color: #e9ecef;
    border-radius: .25rem;
}
.progress-bar {
    background-color: #37CC8C !important;
}
.progressSpace{
    display: flex;
    //justify-content: center;
    align-items: center;
    gap: 11px;
}
.profileHead {
    display: flex;
    justify-content: space-between;
    padding: 3% 50px 3% 30px;
    flex-wrap: wrap;
    margin-left: 20px;
    max-width: 1150px;
    .profileInner1{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 2rem;
        h1{
            font-size: 30px;
            font-family: $fontmedium;
            color: $headColor;
            margin-bottom: 8px;
            margin-top: 10px;
        }
        h3{
            font-size: 21px;
            font-family: $fontmedium;
            color: $headColor;
            margin-bottom: 8px;
            margin-top: 10px;
        }
        p{
            font-size: 14px;
            line-height: 18px;
            font-family: $fontnormal;
            color: $paraColour;
            margin-bottom: 4px;
        }

        .profilediv{
            width: 86.71px;
            height: 86.71px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid rgb(0, 131, 155);
            background-color: #fff;
            color: #66b5c3;
        }
    
    }
}
.deletedContent{
    font-family: 'VisueltMedium';
    color: #013C44;
    h2{
    font-family: 'VisueltMedium';
        font-size: 24px;
    color: #013C44;

    }
    p{
        font-size: 18px;
        color: #4D777C;
    }
}
.progressHead{
    font-size: 16px;
    font-family: 'VisueltMedium';
    color: $headColor;
    margin-bottom: 8px;
    margin-top: 10px;
    
}
.linearBarHead{
    position: relative;
    p{
        position: absolute;
    }
}
.profileFormCard{
    // padding: 3% 1.9375rem;
    // display: flex;
    // flex-wrap: wrap;
    margin-left: 20px;

    .relativeNotice{
        display: flex;
        gap: 5px;
        flex-direction: row;
        width: 100%;
        align-items: center;
        min-height: 47px;
        font-weight: lighter;
        font-size: 14px;
        font-family: $fontmedium;
        color:#00839B;
        background-color: #EFFFF8;
        border-radius: 5px;
        padding: 13px 3% ;
        border: 1px solid #C4F0DD;
        box-sizing: border-box;
    }
    
}
.profileFormCard{
    padding: 3% 1.9375rem;
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-between;
    max-width: 1150px;
    .relativeCard{
        display: flex;
        border: 1px solid #D9EDF1;
        flex-wrap: wrap;
        flex: 1;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        min-height: 90px;
        border-radius: 5px;
        max-width: 525px;
        min-width: 400px;
        padding: 2% 2.5%;
        div{
            flex: 2;
            display: flex;
            flex-direction: row;
            gap: 10px;
            justify-content: flex-end;
            .actionRelative{
                max-width: 100px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                font-family: 'VisueltRegular';
                align-items: center;
                gap: 5px;
                border-radius: 5px;
                border: 1px solid #D9EDF1;
                padding: 2% 5%;
                img{
                    margin-bottom: 4px;
                }
    
            }
        }
        .actionRelative:hover{
            background-color:#D9EDF1;
        }
        
    }

}
.addRelatives{
    height: 49px;
    color: white;
    background: #00839B;
    outline: none;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    font-size: 18pxpx;
    font-family: $fontnormal;
    align-items: center;
    min-width: 182px;
}
@media (max-width:600px) {
    .profileFormCard{
        flex-direction: column;
        padding: 10px;
    }
    
}

@media (min-width: 300px) and (max-width: 320px) {
    .profileFormCard{
        flex-direction: column;
        padding: 10px;
    }
    .inputsForm{
        width: 85%;
    }
  
  }