.header-text-1{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 30px;
line-height: 34px;
/* identical to box height, or 113% */
display: flex;
align-items: center;
color: #143656;
margin-top: 54px;
// margin-left: 60px;
}


.copy-button {
    height: 44px;
    background: #FFFFFF;
    color: #00839B;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    // color: #FFFFFF;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 60px;
    margin-right: 10px;
}
.copy-button-1{
    background: #EFFFF8;
border: 1px solid #C4F0DD;
box-sizing: border-box;
border-radius: 50%;
}
.Copy {
    height: 35px;
    background: #EFFFF8;
    color: #00839B;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    // color: #FFFFFF;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-top: 0px;
    cursor: pointer;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 60px;
    margin-right: 10px;
    margin-left: auto;
    width: auto;
}
.share-button {
    height: 44px;
    background: #00839B;
    color: #FFFFFF;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    // color: #FFFFFF;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 60px;
    margin-right: 10px;
}
.Main{
    width: 90%;
    height: auto;
    border: 1px solid  rgba(3, 130, 153, 0.11);
    margin: auto;
}
.Reffer{
    width: 100%;
    height: auto;
    border: 1px solid  rgba(3, 130, 153, 0.11);
    margin: auto;
}
.View{
    border-radius: 50%;
    border: 1px solid  rgba(3, 130, 153, 0.11);
    width: auto;
    text-align: center;
    background: #EFFFF8;
    margin-top: 5%;
    margin-left: 5%;
    color: #4D777C;
}
.Sub-Main{
    width: 90%;
    height: auto;
    border: 1px solid  rgba(3, 130, 153, 0.11);
    margin: auto; 
    background: #EFFFF8; 
}
.Main-top{
    width: 90%;
    margin: auto;
    display: flex;
}
.Main-text{
    width: 100%;
    height: auto;
    border: 1px solid  rgba(3, 130, 153, 0.11);
    margin: auto;
}
.data{
    flex-wrap: nowrap;
}
.table .thead-light th {
    color: #013C44;
    background-color: #EFFFF8;
    border-color: #C4F0DD;
}

@media (min-width:320px) and (max-width:767px) {
    .Main{
        width: 90%;
        border: 1px solid  rgba(3, 130, 153, 0.11);
        margin: auto;
    }
    .main-con{
        display: flex;
        flex-direction: column;
    }
    .Main-top{
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
    }
    .search{
        display: none;
    }
}