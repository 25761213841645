.healthdetails {
    width: 640.84px;
    background: #f2f8f9;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 2% 3% 4% 3%;
    margin-left: 20px;
    margin-bottom: 20px;
    .heading {
        font-family: "VisueltMedium";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 129%;
        color: #013c44;
    }
    .docContainer {
        margin-top: 2%;
        background: #ffffff;
        border: 1px solid #d9edf1;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 2%;
        cursor: pointer;
        .container {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;

            color: #013c44;
            display: flex;
            justify-content: space-between;
            padding: 1.3%;
            .title {
                width: 50%;
                border-right-width: 1px;
                border-right-color: #000;
                border-right-style: solid;
            }
            .value {
                width: 50%;
                padding-left: 10%;
            }
        }
    }
}
