.DeletePopup{
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
    width: 513px;
    height: 362.19px;
    display: flex;
    justify-content: center;
    align-items: center;

    .closeDel{
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .DelContainer{
        width: 40%;
        img{
            display: block;
            margin: 0 auto;
        }

        div{
            width: 100%;
            text-align: center;
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            margin-bottom: 10px;

color: #013C44;
        }
        p{
            width: 100%;
            text-align: center;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #4D777C;
        }

        .btnContainer{
            display: flex;
            justify-content: space-between;
            .btnYes{
                width: 84px;
                height: 44px;
                background: #00839B;
                border-radius: 5px;
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #FFFFFF;
                cursor: pointer;
            }

            .btnNo{
                width: 84px;
                height: 44px;
                background: #FFFFFF;
                border-radius: 5px;
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #00839B;
                cursor: pointer; 
                border: 1px solid #00839B;
            }
        }
    }
}