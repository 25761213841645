.specialistContainer {
    .searchContainerfind {
        margin-top: 49.92px;
        display: flex;
        flex-direction: column;
        .searchContainerfindinput {
            box-sizing: border-box;
            padding: 0px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 6px;
            border: 1px solid #e3ebf6;
            // min-width: 417px;
            height: 38px;
            img {
                padding: 8.49px 13.3px 14.3px 15.5px;
            }
            input {
                border: 0px solid;
                padding-top: 5px;
                height: auto;
                // width: 363px;
                &:focus {
                    outline: none !important;
                    outline-offset: 0px !important;
                }
            }
        }

        .filterbutton {
            margin-left: 17px;
            width: 138px;
            height: auto;
            background: #ffffff;
            border: 1px solid #e3ebf6;
            box-sizing: border-box;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
            border-radius: 6px;

            span {
                text-align: center;
                padding: 10px 51px 10px 55px;
            }
        }
    }

    padding: 2% 4%;
    .specialTitle {
        margin-top: 20px;
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        // width: 730.87px;
        line-height: 133%;
        color: #013c44;
    }
    .specialistCards {
        margin-top: 3%;
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
    .filter-section-form {
        background: #ffffff;
        border-radius: 5px;
        padding: 10px;
        margin: 2px;
        border: 1px solid #E3EBF6;
        box-sizing: border-box;
        border-radius: 10px;
    }
}

.containerWrapper {
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    .componentSection {
        .componentSectionChild {
            display: flex;
            gap: 13px;
            flex-wrap: wrap;
        }
    }
}
.listing {
    display: flex;
    flex-wrap: wrap;
    margin:20px;
    .searchDoc {
        width: 348px;
        // height: 478px;
        box-sizing: border-box;
        border-radius: 5px;
        margin-right: 1.77%;
        margin-bottom: 2%;
    }
}
