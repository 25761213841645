.sadnBooking{
    padding: 2% 4%;
     .h4{
        margin-top: 20px;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        width: 494px;
        height: 32px;
       line-height: 133%;
        color: #013C44;
    }
.h5{
    
        margin-top: 2.01px;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        width: 352px;
        height: 24px;
        line-height: 133%;
        color: #013C44;
    

}
.buttonConfirm{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 0.972vw;
    color: #FFFFFF;
    background-color: #00839B;
    
    line-height: 107%;
    font-family: 'VisueltRegular';
    font-size: 14px;
    line-height: 15px;
    font-style: normal;
    font-weight: normal;
    padding: 1% 1% 0.8%;
    cursor: pointer;
    width: 218px;
    height: 44px;       
    border: 2px solid #00839B;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 30px;
    &:hover{
        color: #00839B;
        background: transparent;

    }
}

.availablelightgreen{
    display:flex;
    flex-direction: row;
    margin-top: 78.43px;

    width: 546.48px;
    height: 69.91px;
    background: #EFFFF8;
    border: 1px solid #EFFFF8;
    box-sizing: border-box;
    border-radius: 5px;


    .next{
        display: flex;
        flex-direction: row;

        width: 307.57px;
        height: 24px;
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #013C44;
        margin: 22.01px 51.56px 23.9px 27.92px;
    }

    .time{
       

   
    
    
    width: 132.13px;
    height: 43.2px;
  
    
    background: #C4F0DD;
    border-radius: 5px;
    margin: 13.35px 27.31px 13.35px 0;
        
    }

    p{
        width: 97.4px;
height: 24px;

margin: 9.6px 17.36px 9.6px 17.36px;;
font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 32px;
/* or 133% */

display: flex;
align-items: center;
text-align: center;

/* color/primary/deep-docteal */

color: #013C44;
    }

}

.buttons{
    margin-top: 23.98px;
display: flex;   
.proceed{
    cursor: pointer;

    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
// padding: 17px 34px 14px;
width: 306px;
height: 49px;
background: #00839B;
border-radius: 5px;

font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 18px;
/* identical to box height, or 100% */


color: #FFFFFF;
padding: 17px 34px;

&:hover {
    background-color: transparent;
    border: 1px solid #00839b;
    color: #00839b;
}
}


.check{
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 34px 14px;
    margin-left: 26px;
    width: 212px;
    height: 49px;
    background: #FFFFFF;
    border-radius: 5px;
    border: 1px solid #00839b;

    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    /* identical to box height, or 100% */
    
    color: #00839b;

    padding: 17px 26px;
    
    &:hover {
        background-color: #00839b;
        border: 1px solid #00839b;
        color: #FFFFFF;

    }

}


}
.check{
    // margin-top:28px;
    cursor: pointer;

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 17px 34px 14px;
// margin-left: 26px;
width: 212px;
height: 49px;
background: #FFFFFF;
border-radius: 5px;
border: 1px solid #00839b;

font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 18px;
/* identical to box height, or 100% */

color: #00839b;

padding: 17px 26px;

&:hover {
    background-color: #00839b;
    border: 1px solid #00839b;
    color: #FFFFFF;

}

}

.kind{
width: 314.22px;
height: 22px;

margin-top: 64px;
font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
/* identical to box height, or 137% */


/* color/primary/deep-docteal */

color: #013C44;

}

.find{
    cursor: pointer;
    margin-top: 28.4px;
   display: flex;
// position: absolute;
width: 314.22px;
height: 150.45px;


background: #FFFFFF;
/* color/secondary/linge */

border: 2px solid #FF5C3C;
box-sizing: border-box;
border-radius: 10px;
    ;
    // width: 314.22px;
    // height: 150.45px;

    .image{
        margin: 26.27px 23.14px 88.08px 19.8px;
    }
.content{
    margin-top: 22.9px;
    width: 100%;
    .title{

width: 187.83px;
height: 59.27px;


font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 110%;
/* or 26px */

letter-spacing: -0.415271px;

color: #013C44;

    }
    .desc{

// position: absolute;
width: 187.83px;
height: 44.16px;


/* font/P14 */

font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
/* or 129% */


/* color/primary/tints/deep-docteal-T1 */

color: #4D777C;

    }
}
}


// .afterproceedsadn{
//     display: flex;
//     flex-direction: row;
// }

.containerWrapper{
    display:  flex;
    flex-direction: row;
    
    flex-wrap: wrap;
    .componentContainer{
        margin-right: 19px;
        // flex-direction: column;



    }
    .componentContainer2{
        // margin-right: 19px;
    width: 150px;
                display:  flex;
        flex-direction: row;

}
}
@media (min-width: 768px) and (max-width: 1279px) {
    // .h4{
    //     width: 90%;
    //     font-size: 20px;
    // }
    // .h5{
    //     width: 90%;
    //     font-size: 16px;
    // }

}

@media (min-width: 300px) and (max-width: 767px) {

   .h4{ margin-top: 20px;
        font-family: 'VisueltMedium';
        font-style: normal;
        font-weight: normal;
        font-size:24px;
        width: 100%;
        // height: 32px;
       line-height: 133%;
        color: #013C44;}

        .h5{
    
            margin-top: 32px;
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            width: 100%;
            line-height: 133%;
            color: #013C44;
        
    
    }

    .availablelightgreen{
        display: flex;
        flex-direction: column;
        width: 100%;
        height:160px;
        .time{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: 25%;
        }

       
    }
    .buttons{
        display: flex;
        flex-direction: column;
        .proceed{
            width: 100%;
        }
        .check{

            margin: 24px 0 0 0;
            width: 100%;
        }
    }

    .check{

        margin: 24px 0 0 0;
        width: 100%;
      
    }

}


}