.field-label {
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
/* identical to box height, or 129% */


/* color/primary/tints/deep-docteal-T2 */

color: #ADC2C2;
}

.form-label {
    font-style: normal;
    font-weight: 600;
    font-size: 21px;
    line-height: 113%;
    letter-spacing: -0.03em;
    color: #013C44;
}

.field-button {
    height: 44px;
    background: #00839B;
    border-radius: 5px;
    color: #fff;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    cursor: pointer;
}
