doctor-details-page {
    width: 100%;
    padding: 0px;
    overflow-x: hidden;
}

.doctor-details-header {
    padding: 35px;
    background: #f2f8f9;
}

.doctor-details-link {
    display: flex;
    justify-content: flex-end;
}

.doctor-details-data {
    background: #013c44;
    color: white;
    min-height: 589px;
    margin-bottom: 159px;
}

.doctor-details-back,
.doctor-details-back a,
.doctor-details-back a:hover {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #ffffff;
    margin-top: 50px;
    cursor: pointer;
}

.doctor-details-title {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #ffffff;
    margin-top: 20px;
}

.doctor-details-profile-photo {
    box-shadow: 0px 0px 14px 5px rgba(3, 130, 153, 0.08);
    border-radius: 5px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 100%;
    max-width: 448px;
    height: 475px;
}

.doctor-details-full-name {
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    line-height: 42px;
    color: #ffffff;
}

.doctor-details-speciality {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    opacity: 0.8;
}

.doctor-details-profile-details {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
    margin-top: 20px;
    .col-12 {
        display: flex;
        align-items: center;
        .lable-text {
            opacity: 0.6;
        }
    }
}

.doctor-details-speciality-title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #013c44;
    margin-bottom: 20px;
}

.doctor-details-fee {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    color: #013c44;
    text-align: end;
}

.doctor-details-bio {
    font-size: 18px;
    line-height: 30px;
    margin-top: 25px;
    color: #013C44;

}

.doctor-details-specialities-title {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.doctor-details-list {
    background: #effff8;
    border: 1px solid #c4f0dd;
    box-sizing: border-box;
    border-radius: 5px;
    max-width: 330px !important;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 40px;
    text-align: justify;
    color: #013c44;
    opacity: 0.8;
    margin-right: 15px;
    margin-left: 15px;
    margin-top: 15px;
}

.doctor-details-slot {
    background: #f0f5f5;
    padding: 25px;
    margin: 0px !important;
}

.doctor-details-time-title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    color: #013c44;
    padding: 0px;
}

.doctor-details-slot-button,
.doctor-details-slot-button:hover {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 17px 34px 14px;
    /* position: absolute; */
    width: 218px;
    height: 49px;
    left: 519px;
    top: 2062px;
    background: #00839b;
    border-radius: 5px;
    color: white;
    margin-top: 20px;
}

.doctor-details-more-title {
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    color: #013c44;
    padding: 0px !important;
}

.doctor-profile-full-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 113%;
    letter-spacing: -0.03em;
    color: #013c44;
    font-family: "VisueltMedium";
    display: flex;
    justify-content: space-between;
    min-width: 270px !important;
}

.doctor-profile-speciality {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: -0.3px;
    color: #378198;
}

.doctor-profile-details {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    margin-top: 20px;
    font-family: "VisueltMedium";
}

.doctor-profile-section {
    background: #f9fbfd;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    max-width: 272px !important;
    min-width: 272px !important;
    padding: 15px;
    min-height: 447px;
    margin-right: 20px;
    margin-top: 20px;
    cursor: pointer;
}
.doctor-details-rating{
    display: flex;
    align-items: center;
}
.doctor-details-rating .MuiRating-root label {
    font-size: 30px;
   
}

.MuiRating-root label {
    color: #ffb800 !important;
}

.MuiRating-root.Mui-disabled {
    opacity: inherit !important;
}

.doctor-profile-photo {
    background: #ffffff;
    /* color/primary/tints/deep-docteal-T2 */

    border: 1px solid #adc2c2;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 20px;
}

.doctor-profile-location {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.doctor-details-rating-count {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-left: 12px;
}

.slotList {
    font-weight: bold;
}
.doctor-img{
    width: 500px;
    height: 500px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: CENTER;
    box-shadow: 0px 0px 14px 5px rgba(3, 130, 153, 0.08);;
    border-radius: 5px;
    .doctor-photo {
        width: 300px;
        height: 300px;
    }
}
.nextDisabledButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 38px !important;
    height: 30px;
    border: 1px solid #00839B;
    box-sizing: border-box;
    border-radius: 7.27273px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    cursor: pointer;
}

.prevDisabledButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 38px !important;
    height: 30px;
    border: 1px solid #00839B;
    box-sizing: border-box;
    border-radius: 7.27273px;
    cursor: pointer;
}

.nextEnabledButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 38px !important;
    height: 30px;
    background-color: #00839B;
    border-radius: 7.27273px;
    cursor: pointer;
}

.prevEnabledButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 38px !important;
    height: 30px;
    background-color: #00839B;
    border-radius: 7.27273px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    cursor: pointer;
}