.ProgressBar{
    width: 575.26px;
    height: 360px;
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 3.71134px 5.56701px 1.85567px rgba(3, 61, 69, 0.18);
    border-radius: 9.27835px;
    img{
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
    }

    .progressContainer{
        width: 575.26px;
        height: 360px;
        display: flex;
        align-items: center;
        justify-content: center;
        .ant-progress{

            .ant-progress-inner{
                position: relative;
                width: 177px !important;
                height: 177px !important;
                font-family: 'VisueltMedium';
                font-style: normal;
                font-weight: normal;
                font-size: 50px !important;
                color: #013C44 !important;
                line-height: 54px;
                .ant-progress-text{
                    position: absolute;
                    top: 86px;
                    left: 84px;
                }
            }
        }

        p{
            position: absolute;
            bottom: 100px;

            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            color: #ADC2C2;
        }
        .docName{
            position: absolute;
            bottom: 50px;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;


color: #013C44;
        }

        
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .ProgressBar{
        width: 100%;

    .progressContainer{
        width: 100%;
        height: 100%;

       
    } 
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .ProgressBar{
        width: 100%;

    .progressContainer{
        width: 100%;
        height: 100%;
    } 
    }
}