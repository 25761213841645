.Appointment{
    background: #EFFFF8;
    width: 502.81px;
    height: 125px;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 3% 5%;
    margin-top: 21.62px;
    .headingContainer{
            width: 90%;
            .headingContainer{
                display: flex;
                position: relative;
                align-items: center;
                margin-bottom: 10px;
                // justify-content: center;
                
                img{
                    display: block;
                    margin-right: 5px;
                    margin-top: -5px;
                }
                .heading{
                    display: block;
                    margin: auto 0;
                    font-family: 'VisueltRegular';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    /* identical to box height, or 133% */
                    color: #37CC8C;
                }
            }
        

        .text{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 137%;
            color: #4D777C;
        }
    }
}
#AppointmentSpecial{
    background: #EFFFF8;
    width: 300px;
    height: auto;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 3% 2%;
    margin-top: 6.62px;
}

.AppointmentTipsTwo{
    width: 323px;
    height: 344.68px;
    background: #F2F8F9;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px 25px;

    img{
    margin-bottom: 20px;  

    }

    .appoHeading{

        font-family: 'VisueltMedium';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #013C44;
        margin-bottom: 15px;

    }

    .appoTxt{
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;        
        color: #4D777C;
        margin-bottom: 15px;
    }

    .appobtn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 230px;
        height: 44px;
        border: 1px solid #00839B;
        box-sizing: border-box;
        border-radius: 5px;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        text-transform: uppercase;
        color: #00839B;
        &:hover{
            background-color:#00839B;
            color: white;
        }
            }

}

.ImportantNote{
    width: 613.87px;
    height: 344.68px;
    left: 669.58px;
    top: 677.76px;
    background: #EFFFF8;
    border: 1px solid #C4F0DD;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 20px 25px;

    img{
        margin-bottom: 20px;  
    
    }
    
    .impHeading{
        font-family: 'VisueltMedium';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        /* identical to box height, or 133% */
        margin-bottom: 15px;
        color: #013C44;
    }

    .inpTxt{
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        color: #4D777C;
        // width: 461.54px;   
        width: 100%; 

    }
}


.NoAppointments{
    display: flex;
    margin-top: 40.51px;
    background: #EFFFF8;
    width: 546.48px;
    height: 88.2px;
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */
    margin-bottom: 15px;
    color: #013C44;
    img{
        width: 43.5px;
height: 43.5px;
margin: 22.35px 14.56px 22.35px 20.4px;
    }
    .inpTxt{
        width: 350.57px;
        height: 48px;
    margin: 22.01px  160.45px 4.19px 10px;
        
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .Appointment{
        width: 100%;
    }
    .AppointmentTipsTwo{
        margin-bottom: 20px;
        width: 100%;
    height: auto;
    
}

.ImportantNote{
    width: 100%;
    height: auto;
}
}

@media (min-width: 300px) and (max-width: 767px) {
    .Appointment{
        width: 100%;
    }
    .AppointmentTipsTwo{
        margin-bottom: 20px;
        width: 100%;
    height: auto;
    

}

.NoAppointments
{
    display: flex;
    flex-direction: row;
    width: 100%;

   
    .inpTxt{
display: flex;
flex-direction: row;
// margin: auto auto auto auto;
margin: 8px;

}
}

.ImportantNote{
    width: 100%;
    height: auto;
}
}