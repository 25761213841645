.doctall-header {
    background: #F1F9F5;
    padding: 15px 0px;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
.main-header {
    margin-bottom: 10px;
    height: 35px;
}

.img-logo {
    width: 120px;
    position: relative;
    top: 5px;
}

.img-synlab {
    height: 40px;
    margin-left: 40px;
}

.img-medplus {
    height: 40px;
    margin-left: 40px;
}

.img-healthplus {
    height: 40px;
    margin-left: 40px;
}

.react-datepicker__input-container input {
    width: 200px;
    position: relative;
    top: 3px;
}

.react-datepicker__close-icon::after {
    background-color: #046673 !important;
    position: relative;
    top: 3px;
}

.react-datepicker__header {
    text-align: center;
    background-color: #F1F9F5 !important;
    color:#fff !important;
    border-bottom: 0px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
  
  }
  
  .react-datepicker__today-button {
    background: #046673 !important;
    border-top: 0px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    padding: 5px 0;
    clear: left;
  }

  .react-datepicker {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 0.8rem;
    background-color: #F1F9F5 !important;
    color: #000;
    border: 0px;
    border-radius: 0;
    display: inline-block;
    position: relative;
  }

.header-link{
    width: 67px;
    height: 50px;
    top: 35px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    padding:7px;
    padding-right:20px !important;
    color: #046673;
    cursor: pointer;
    text-decoration: none;
}

.header-link:hover{
    color: #046673;
}

.branch-name {
    font-family: 'visuelt-regular';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    text-align: right;
    color: #046673;
    padding: 18px 0px;
}

.back-button {
    border: none;
    background: no-repeat;
    color: #046673;
    margin-top: 15px;
    font-size: 15px;
    line-height: 50px;
    font-family: 'visuelt-regular';
}

.logout {
    color: #013C44;
    margin-top: 20px;
    box-sizing: border-box;
    box-shadow: -1px 6px 8px 1px rgba(8, 180, 212, 0.15);
    border-radius: 10px;
    font-family: 'GreycliffCF-Medium';
    font-weight: 600;
    width: auto;
    height: 55%;
    padding: 12px 30px;
}

.card {
    background: #F1F9F5;
    border-radius: 20px;
    border: 1px solid rgba(102, 195, 189, 0.5);
}

.admin-dashboard .doctall-card {
    margin: 30px 0px;
    height: 400px;
}

.partner-dashboard .doctall-card {
    margin: 30px 0px;
    height: 350px;
    
}
.card-title, .card-text {
    color: #046673;
    font-size: 15px;
}

.img-icon {
    padding: 0px 15px;
    height: 29px;
}

.doctall-card .card-title {
    font-family: "GreycliffCF-DemiBold";
    font-size: 18px;
    color: #046673;
    margin-bottom: 25px;
    letter-spacing: -0.2px;
}

.back-btn {
    color: #046673;
    margin-top: 30px;
}

.hr-line {
    color: #046673;
    margin: 20px 0px;
    height: 1px;
}

.card-body-bg {
    background-color: #fff;
    border-radius: 20px;
    border: 1px solid rgb(139, 228, 220);
}

.welcome-title {
    font-family: "visuelt-regular";
    font-size: 30px;
    line-height: 38px;
    letter-spacing: -1px;
    color: #66B5C3;
    margin: 20px 0px 15px 0px;
}

.welcome-note{
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    color: #046673;
    margin: 15px 0px;
    font-family: "visuelt-regular";
}

.white-bg{
    background-color: white;
}

.count{
    letter-spacing: -0.09em;
    font-family: 'GreycliffCF-Heavy';
    font-size: 54px;
    text-align: center;
    color: #046673;
}

.pending-info-text {
    font-family: 'visuelt-regular';
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 18px;
    text-align: center;
    color: #0CAB67;
}

.static-text-head {
    font-family: 'visuelt-medium';
    font-size: 14px;
    line-height: 18px;
    color: #046673;
}

.static-text-body {
    font-family: 'visuelt-regular';
    font-size: 13px;
    line-height: 18px;
}

.admin-dashboard .doctall-card .card-text{
    height: 300px;
}

.alert-texts {
    font-size: 15px;
    color: #046673;
    font-family: 'visuelt-medium';
}

.hr-invisible {
    visibility: hidden;
}

.search-icon {
    cursor: pointer;
}
.search-icon {
    float: right;
    position: relative;
    top: 27px;
    right: 15px;
}

.filter-icon {
    float: right;
    position: relative;
    top: 25px;
    right: 15px;
}

.search-input {
    font-family: 'visuelt-regular';
    font-size: 18px;
    border-radius: 12px;
    color: #046673 !important;
    border: 1px solid rgba(102, 195, 189, 0.5);
}

.filter-icn{
    margin-left: 10px !important;
    width: 14px;
    position: relative;
    bottom: 1px;
}

.table-responsive {
    padding: 0px;
}
.view-order {
    width: 115px;
    font-size: 11px !important;
}

.view-result-btn{
    margin-top: 10px;
}

/*Modal Custom css*/

.doctall-modal, .doctall-modal-static {
    background: rgba(1, 60, 68, 0.7);
}

.modal .sr-only {
    display: none;
}

.doctall-modal .close {
    border: 0;
    color: #000;
    background: 0;
    position: relative;
    /* bottom: 60px;
    left: 50px; */
    float: right;
}

.modal .close span {
    
    border: 2px solid #fff;
    border-radius: 50%;
    padding: 0px 50px;
    position: relative;
    bottom: 0px;
}

.doctall-modal .modal-content {
    /* background: #F1F9F5; */
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    background: #FFFFFF;
}

.doctall-modal .modal-content button {
    font-family: 'Roboto', sans-serif !important;
    font-size: 14px !important;
    line-height: 18px;
}


.doctall-modal .modal-body {
    background-color: #fff;
    border-radius: 15px;
}

.doctall-card .modal-body .modal-margin-50{
    margin: 0px 50px !important;
}
.modal .modal-header {
    border-bottom: 0px;
}

.doctall-modal .modal-footer {
    border-top: 0px;
    display: block;
    text-align: center;
}

.doctall-modal .modal-footer button {
    width: 150px;
}

.doctall-modal .doctall-modal-title {
    font-family: 'GreycliffCF-Bold';
    font-size: 20px;
    color: #046673;
    padding-left: 35px;
}

.doctall-modal .doctall-modal-subtitle {
    /*font-family: Visuelt;*/
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #046673;
    padding-left: 35px;
}

.doctall-modal textarea {
    resize:none;
    border: 0;
    width: 100%;
}

.doctall-modal textarea:focus-visible {
    resize:none;
    border: 0;
    outline: none;
}

.doctall-modal-static .modal-content{
    background: #F1F9F5;
    border: 1px solid rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
}

.doctall-modal-static .close {
    border: 0;
    color: #fff;
    background: 0;
    position: relative;
    bottom: 35px;
    left: 60px;
    float: right;
}

.doctall-modal-static .modal-header {
    padding: 0px 15px;
}

.doctall-modal-static .tab-content{
    margin: 0 auto;
}

.doctall-modal-static .modal-body {
    padding: 0px 25px;
}

.doctall-modal-static .modal-body nav{
    border-bottom: 1px solid rgba(4, 102, 115, 0.5);
}

.doctall-modal-static .modal-body .nav-tabs .nav-link {
    /*font-family: Greycliff CF;*/
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    color: #046673;
    width: 50%;
    transition: none;
}

.doctall-modal-static .nav-tabs .nav-link.active {
    border: none;
    background: none;
    border-bottom: 2px solid #00BE6D;
}

.doctall-modal-static .nav-tabs .nav-link:hover, .doctall-modal-static .nav-tabs .nav-link:focus {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.doctall-modal-static .nav-tabs .nav-link:focus-visible {
    outline: none;
}

.doctall-modal-static .tab-values-row{
    /*font-family: Visuelt;*/
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #046673;
    border-bottom: 1px solid #ccc;
    padding: 10px 0px;
}

.file-upload-container {
    text-align: center;
    background: #F1F9F5;
    border: 1px dashed rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    border-radius: 20px;
    padding: 20px;
    cursor: pointer;
    margin: 0px;
    outline: 0;
}

.file-upload-modal .file-upload-modal-body {
    background:#F1F9F5 !important;
    padding: 0px;
    max-height: 320px;
    overflow-x: hidden;
}

.file-upload-text {
    font-family: 'visuelt-regular';
    font-size: 16px;
    line-height: 36px;
    text-align: center;
    color: #046673;
}

.file-upload-config {
    font-family: 'visuelt-regular';
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #046673;
}

.browse-text {
    color: #11c1e1;
}

.file-upload-modal .progress{
    height: 4px;
}

.file-upload-modal .progress-bar {
    height: 4px;
    background-color: #00839B;
}

.file-upload-modal .file-uploaded-name {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 36px;
    color: #046673;
}

.file-upload-modal .file-uploaded-size {
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 36px;
    color: #046673;
}

.file-upload-modal .file-uploaded-percentage {
    font-family: 'visuelt-medium';
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 36px;
    text-align: right;
    color: #046673;
}

.file-upload-modal .file-uploaded-section{
    background: #FFFFFF;
    border: 1px dashed rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    margin: 15px 0px;
}

.existing-file-section {
    background: #FFFFFF;
    border: 1px dashed rgba(102, 195, 189, 0.5);
    box-sizing: border-box;
    border-radius: 10px;
    margin: 15px 25px;
    padding: 10px 0px;
}

.file-upload-modal .delete-icon {
    width: 12px;
    height: 12px;
    position: relative;
    right: 10px;
    cursor: pointer;
}

.file-upload-modal .delete-icon-exist {
    width: 12px;
    height: 12px;
    position: relative;
    right: 10px;
    bottom: 12px;
    cursor: pointer;
}

.file-upload-modal .img-icon-file-color {
    position: relative;
    top: 15px;
    padding: 0px 15px;
}

.file-upload-modal .file-color-existing {
    padding: 0px 15px;
}



.current-date{
    background: #F1F9F5;
    border-radius: 7px;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: #046673;
    margin-bottom: 4px;
    margin-top: 5px;
}

.date-range-select{
    color:#046673 ;
    background-color: transparent;
    border: 0;
    padding: 7px;
    font-weight: 500;
    margin-top: 5px;
    font-size: 15px;
    letter-spacing: -0.3px;
}

.date-range{
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgb(139, 228, 220);
    margin-right: 3px;
}

.date-range input {
    border: 0;
}

.float{
    justify-content: flex-end;
    margin-top: 10px;
}

.filter-select{
    border-radius: 12px;
    border: 1px solid rgba(102, 195, 189, 0.5);
    font-size: 13px;
    padding: 10px;
    color:#046673;
    margin-right: 15px;
}

select.filter-select:last-of-type{
    margin-right: 0px;
}

.filter-select option{
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    border-bottom: 1px solid #046673;
}

.react-datepicker__input-container {
    margin: 2px;
}

select.filter-select:focus-visible {
    outline-offset: 0px !important;
}

.filter-option {
    text-align: left;
}

.filter-option-row{
    justify-content: flex-end;
}

.location-modal{
    font-family: 'visuelt-medium';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.3px;
    color: #013C44;
}

.white-modal-body{
    /* background: #F1F9F5 !important; */
    background: #FFFFFF;
}

.img-check-uncheck {
    cursor: pointer;
    width: 16px;
}

.spinner-center {
    top: 50%;
    left: 50%;
    position: relative;
}

.spinner-center-notification {
    top: 30%;
    left: 40%;
    position: relative;
}

.address-update{
    color: #0CAB67 !important;
    font-size: 12px;
    text-decoration-line: underline;
    cursor: pointer;
    padding: 0px;
}

.address-input{
    margin-top: 12px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #A0DFC4;
    box-sizing: border-box;
    border-radius: 10px;
}

.address-input:focus .address-input:hover{
    border: 1px solid #A0DFC4 !important;
}

.address-input:read-write{
    color: #046673;;
}

.service-instruction{
    border: 1px solid #A0DFC4 !important;
    border-radius: 10px !important;
}

.address-modal{
    color: #013C44 !important;
}

.address-label-font{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.3px;
    color: #013C44;
}

.address-label-head{
    font-weight: 500;
    font-size: 21px;
}

.dashboard-table-head {
    top: 0;
    position: sticky;
    background-color: #F1F9F5;
}
.ant-select-selector{
    border: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: none;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ezNeFY{
    margin: 0 -2%; 
} 
.button{
    margin: 0 8%; 
    display: flex;
    flex-direction: row;
    column-gap: 30px;
 
}