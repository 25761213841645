.card_container{
    width: 277px;
    height: 363px;
    border-radius: 10px 10px 0px 0px;
    margin-right: 20px;

    .card_img{
        
        width: 277px;
        height: 207px;
    }

    .text_container{
        width: auto;
        height: 156px;
        background: #F2F8F9;
        border-radius: 0px 0px 10px 10px;
        position: relative;
        padding: 20px 20px;
        .heading{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 28px;
            color: #013C44;
            width: 80%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
        .hr{
            border: 0.5px solid #00839B;
            margin: 20px 0px;
            order: 1;
            position: absolute;
            bottom: 30px;
        }

        .small_container{
            width: 100%;
            display: flex;
            position: absolute;
            bottom: 20px;
            .date{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                /* identical to box height, or 100% */
                
                letter-spacing: 0.08em;
                
                color: #013C44;
            }

            .txt{
                padding-left: 8px;
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 12px;
                /* identical to box height, or 100% */
                
                letter-spacing: 0.08em;
                
                color: #013C44;
                span{
                    padding-right: 4px;
                }

            }
        }

   
    }   
}