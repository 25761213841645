@import '../../layout/authLayout.scss';
.tabCard{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 1085px;
    margin-left: -23px;
    margin-bottom: 20px;
    padding: 3% 24px;
    border-radius: 10px;
    box-shadow: 0px 0px 8px 1px rgba(3, 130, 153, 0.08);
    border:1px solid #D9EDF1;
    .cardProfile{
        flex:25%;
        min-width: 200px;
        h3{
            font-size: 21px;
            font-family: $fontmedium;
            color: $headColor;
            margin-bottom: 8px;
            margin-top: 10px;
        }
        .cautionDiv{
            display: flex;
            gap: 8px;
            align-items: flex-start;
            img{
                margin-top: 2px;
            }
            p{
                
                font-family: $fontnormal;
                font-size: 12px;
                color: #013C44;
            }

        }
        
    }
    .cardForm{
        flex:75%;
        input[type=text]:focus {
            outline: none !important;
            outline-offset: none !important;
        }
        .react-date-picker__inputGroup {
            min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
            flex-grow: 1;
            padding: 3px 13px;
            box-sizing: content-box;
        }

    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .tabCard{
        width: 85%;
        .cardProfile{
            width: 15%;
        }
    }
}

@media (min-width: 426px) and (max-width: 767px) {
    .tabCard{
        width: 66% !important;

        .cardProfile{
            width: 15%;
        }
    }
}

@media only screen and (device-width: 768px) {
    .tabCard{
        width: 66% !important;

        .cardProfile{
            width: 15%;
        }
    }
  }

  @media (min-width: 376px) and (max-width: 426px) {
    .tabCard{
        width: 30% !important;

        .cardProfile{
            width: 15%;
        }
    }
}
@media (min-width: 320px) and (max-width: 376px) {
    .tabCard{
        width: 30% !important;

        .cardProfile{
            width:15%;
        }
    }
}
@media (min-width: 300px) and (max-width: 320px)  {
    .tabCard{
        width: 27% !important;

        .cardProfile{
            width:15%;
        }
    }
}