.notification-container{
.header-text{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #66B5C3;
margin-top: 40px;
margin-left: 49px;
}
.header-border{
    width: 58.59px;
border: 1px solid #66B5C3;
transform: rotate(0.64deg);
margin-top:12px;
margin-left: 49px;

}
}