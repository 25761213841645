.dialoglimit{
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    width: 36.667vw;
    background-color: white;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10% 5%;
    img.res{
        margin-bottom: 5%;
        height: 70px;
        width: 70px;
    }
    p{
        color: #4D777c;
        font-size: 18px;
    }
    
    }

    .actionButtonsYes{
        height: 49px;
        color: white;
        background: #00839B;
        outline: none;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        font-size: 18pxpx;
        border:1px solid #00839B;
        // font-family: $fontnormal;
        align-items: center;
        min-width: 182px;

    }
    .actionButtonsNo{
        height: 49px;
        color: #00839B, 100%;
        background: #fff;
        outline: none;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        font-size: 18pxpx;
        border:1px solid #00839B;
        // font-family: $fontnormal;
        align-items: center;
        min-width: 182px;

    }
    
.actions{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5%;
    gap: 15px;
}
    .xbutton{
        position: absolute;
        top:1%;
        right:0;
        cursor: pointer;
    }