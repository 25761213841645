.appointment {
    padding: 2% 4%;
    .ListContainer {
        margin-top: 4.2%;
    }
    .titlelist {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 133%;
        color: #013c44;
        margin-top: 5%;
        margin-bottom: 2.4%;
     
    }
    .listing {
        display: flex;
        flex-wrap: wrap;
        .appointDoctor {
            width: 348px;
            height: 478px;
            // border: 1px solid #d9edf1;
            box-sizing: border-box;
            border-radius: 5px;
            margin-right: 1.77%;
            margin-bottom: 2%;
        }
    }

    
    @media (min-width: 768px) and (max-width: 1279px) {
        .titlelist {
            font-size: 20px !important;
        }
    }
    @media (min-width: 375px) and (max-width: 767px) {
        .titlelist {
            font-size: 18px !important;
        }
    }
    @media  (max-width: 791px) {
        .listing {
            justify-content: center;
        }
        .titlelist{
            text-align: center;
        }
    }
}
.symptomsTab{
    display: flex;
    flex-wrap: wrap;
    padding: 2% 4%;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    background: #f2f8f9;
    justify-content: space-around;
    margin-bottom: 20px;
    .symptomNoted{
        .Notehead{
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 144%;
            color: #046673;
        }
        .Notebody{
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 133%;
            color: #013c44;
        }
    }
    
}
.extendedNote{
    display: flex;
    flex-wrap: wrap;
    min-height: 128px;
    flex-direction: row;
    width: 640.84px;
    padding: 2% 4% 0% 4%;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    background: #EFFFF8;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left: 20px;
    margin-bottom: 20px;
    img{
        flex: 1;
        height: 45px;
        width: 45px;
    }
    .extendedNoteChild{
        flex: 6;
        h3{
            font-family: "VisueltRegular";
            font-size: 24PX;
            color: #013c44;
        }
        p{
            font-family: "VisueltRegular";
            font-size: 18PX;
            color: #4D777C;

        }
    }
}
.extendedBtn{
    background-color: #00839B;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 266px;
        color: white;
        min-height: 44px;
        font-size: 14px;
        border-radius: 5px;
        margin-left: 20px;
        text-transform: uppercase;
      
}
.disableBtn{
    background-color:grey;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 266px;
    color: white;
    min-height: 44px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 20px;
    text-transform: uppercase;
    pointer-events: none;
    cursor: not-allowed !important;
}
.appointmentDetails {
    padding: 2% 4%;
    .appointwrapper{
        display: flex;
        justify-content: space-between;
        max-width: 1050px;
        flex-wrap: wrap;
    }

    .appointmentIns{
        display: flex;
        flex-direction: row;
        padding: 30px;
        width: 1023.64px;
        height: 395.47px;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        border-radius: 5px;
        justify-content: space-between;
    }
}
.btncontainer{
    display: flex;
}
.appointMentBtns{
    margin: 2% 0%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    .resheduleAppoint{
        background-color: #00839B;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 243px;
        color: white;
        min-height: 44px;
        border-radius: 5px;
        text-transform: uppercase;
        &:hover{
            background-color: white;
            border:1px solid #00839B;
            color: #00839B;
        }
    }
    .cancelAppoint{
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        min-width: 243px;
        color: #00839B;
        border:1px solid #00839B;
        min-height: 44px;
        border-radius: 5px;
        text-transform: uppercase;
        &:hover{
            background-color: #00839B;
            color: white;
        }
    }

}
.resheduleContent{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    p{   
        font-size: 18px;
        padding: 0% 18%;
        text-align: center;
    }
}
.cancelContent{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    p{   
        font-size: 18px;
        padding: 0% 18%;
        text-align: center;
    }
    div{
        display: flex;
        gap: 15px;
        justify-content: center;
        .cancelYes{
            background-color: #00839B;
            color: white;
            border-radius: 5px;
            text-transform: uppercase;
            width: 88px;
            height: 44px;
        }
        .cancelNo{
            background-color: white;
            color: #00839B;
            border-radius: 5px;
            border:1px solid #00839B;
            text-transform: uppercase;
            width: 88px;
            height: 44px;
        }
    }
    
}



@media (min-width: 768px) and (max-width: 1279px) {
    .appointmentDetails {
            
        .appointmentIns{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            
        }
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .appointmentDetails {
            
        .appointmentIns{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
        }
    }
}
