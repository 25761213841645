.specialCard{
    padding: 3% 3%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 311px;
    min-width: 311px;
    height: 100px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: normal;
    color: #013C44;
    .titleSpecial{
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: baseline;
        h3{
            font-size: 16px;
            color: #013C44;
        }
        img{
            height: 11px;
            width: 11px;
        }
    }
    .paraSpecial{
        margin-top: 1%;
        width: 100%;
        h5{
            font-size: 14px;
            color: #013C44;
            line-height: 18px;
        }
    }
}