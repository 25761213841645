.radioBoxInput input {
    display:none;
 }
 
 .radioBoxInput img {
     padding: 10px;
 }
 
 .formControl {
     width: 100%;
 }
 
 .radioBoxLabel {
     margin-right: 16px;
     margin-top: 0px;
 }
 