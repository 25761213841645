.UploadSuccssMsg{
    width: 513px;
    height: 235.94px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .closeButton{
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
    }

    div{
        img{
            display: block;
            margin: 0 auto;
        }
        div{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #4D777C;
            margin-top: 10%;
        }
    }

}