.appointment {
    padding: 2% 3%;
    .ListContainer {
        margin-top: 4.2%;
    }
    .titlelist {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 133%;
        color: #013c44;
        margin-top: 5%;
        margin-bottom: 2.4%;
     
    }
    .listing {
        display: flex;
        flex-wrap: wrap;
        .appointDoctor {
            width: 348px;
            height: 478px;
            // border: 1px solid #d9edf1;
            box-sizing: border-box;
            border-radius: 5px;
            margin-right: 1.77%;
            margin-bottom: 5%;
        }
    }

    
    @media (min-width: 768px) and (max-width: 1279px) {
        .titlelist {
            font-size: 20px !important;
        }
    }
    @media (min-width: 375px) and (max-width: 767px) {
        .titlelist {
            font-size: 18px !important;
        }
        .listing {
            display: flex;
            flex-wrap: wrap;
            .appointDoctor {
                width: 348px;
                height: 478px;
                // border: 1px solid #d9edf1;
                box-sizing: border-box;
                border-radius: 5px;
                margin-right: 1.77%;
                margin-bottom: 15%;
            }
        }
    }
    @media  (max-width: 791px) {
        .listing {
            justify-content: center;
        }
        .titlelist{
            text-align: center;
        }
    }
}
.appointmentDetails {
    padding: 2% 3%;
    .appointwrapper{
        display: flex;
        justify-content: space-between;
        max-width: 1023px;
        flex-wrap: wrap;
    }

    .appointmentIns{
        display: flex;
        flex-direction: row;
        padding: 30px;
        width: 1023.64px;
        height: 395.47px;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        border-radius: 5px;
        justify-content: space-between;
    }
}
.btncontainer{
    display: flex;
}

@media (min-width: 768px) and (max-width: 1279px) {
    .appointmentDetails {
            
        .appointmentIns{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
            
        }
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .appointmentDetails {
            
        .appointmentIns{
            display: flex;
            flex-direction: column;
            width: 100%;
            height: auto;
        }
    }
}

@media (min-width: 319px) and (max-width: 375px) {
    .titlelist {
        font-size: 18px !important;
    }
    .listing {
        display: flex;
        flex-wrap: wrap;
        .appointDoctor {
            width: 348px;
            height: 478px;
            // border: 1px solid #d9edf1;
            box-sizing: border-box;
            border-radius: 5px;
            margin-right: 1.77%;
            margin-bottom: 20% !important;
        }
    }
}
