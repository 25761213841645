.cont-pres-rec {
  .filter-row-pres-rec {}

  .container-pres-tab {
    max-width: 1093px;
    height: 100%;
    // margin-left:62px;
    // margin-top:94px;
    margin-bottom: 186px;
    background: #ffffff;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;

    .info-container {
      // width: calc(100% - 52px);
      // width: 95%;
      min-height: 47px;
      background: #effff8;
      border: 1px solid #c4f0dd;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      flex-direction: row;
      // margin-left: 26px;
      // margin-top: 28px;
      margin: 20px;
      justify-content: flex-start;
      align-items: center;

      .info-img {
        padding-left: 24px;
      }

      .info-text {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        padding-left: 8px;
        padding-top: 5px;
        color: #00839b;
      }
    }

    .card-align-cont {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding-bottom: 28px;
      max-width: 1093px;

      .card-cont-pres {
        width: 329px;
        height: 322px;
        background: #ffffff;
        border: 1px solid #d9edf1;
        box-sizing: border-box;
        border-radius: 5px;
        // margin-top: 32px;
        // margin-left: 26px;
        margin: 10px;

        .badge-cont-outer {
          display: flex;
          flex-direction: row;
          padding-left: 28px;
          padding-top: 28px;

          .badge-cont {
            width: 68px;
            height: 22px;
            background: #f3f4f6;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 12px;

            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              /* identical to box height, or 129% */
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: center;
              color: #991b1b;
            }
          }
        }

        .card-content-det-wrapper {
          padding-left: 30px;
          padding-top: 32px;

          .card-content-det-out {
            display: flex;
            flex-direction: column;

            .card-content-det {
              padding-top: 5px;

              .card-font {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }

        .pending-btn {
          width: 272px;
          height: 44px;
          border: 1px solid #66b5c3;
          box-sizing: border-box;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 63px !important;
          margin-left: 28px;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            /* identical to box height, or 107% */

            color: #00839b;
          }
        }

        .pending-cont-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 63px !important;
          margin-left: 28px;

          .pending-button {
            width: 137px;
            height: 47px;
            background: #00839b;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;

            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 18px;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          .view-button {
            width: 137px;
            height: 44px;
            border: 1px solid #66b5c3;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;

            span {
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 15px;
              display: flex;
              justify-content: center;
              align-items: center;

              color: #00839b;
            }
          }

          .icon-cont {
            width: 58px;
            height: 44px;
            border: 1px solid #66b5c3;
            box-sizing: border-box;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;

            img {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }

  .ContainerWrapper {
    position: relative;
    margin-top: 50px;
    margin-right: 50px;

    .Closepopup {
      position: absolute;
      transform: scale(1.5);
      top: -30px;
      right: -30px;
      cursor: pointer;
    }
  }

  .pdfiframe {
    height: 75vh;
    width: 96%;
    margin: 25px;
    border: 0px;
    display: block;
  }
}

.upcomming {
  position: relative;
  width: 513px;
  height: 362.19px;
  background: #ffffff;
  box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
  border-radius: 5px;
  padding: 20px 35px;

  .closePop {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


  .info {
    display: block;
    margin: 20px auto;
  }

  .heading {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 10px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #013c44;
  }

  .txt {
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 32px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #013c44;
  }

  .btn {
    width: 160px;
    height: 49px;
    background: #00839b;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto;
  }
}

.DeleteAccountdiv {
  min-height: 350px;
  max-height: 450.19px;
  background: #ffffff;
  // box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
  // border-radius: 5px;
  padding: 20px 35px;

  .closePop1 {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }


  .info1 {
    display: block;
    margin: 20px auto;
  }

  .heading1 {
    font-family: "GreycliffCFBold";
    font-style: normal;
    font-weight: normal;
    font-size: 27px;
    line-height: 32px;
    margin-bottom: 10px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #013c44;
  }

  .txt1 {

    font-family: 'Greycliff CF';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
   text-align: center;
    color: #013C44;
  }
  .txt123 {

    font-family: 'Greycliff CF';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    margin-left: 20px;
    color: #013C44;
  }
  .txt12 {

    font-family: 'Greycliff CF';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.3px;
    margin-left: 20px;
    color: #013C44;
  }

  .btn1 {
    width: 160px;
    height: 49px;
    background: #08B4D4;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    // margin: 30px auto;
  }

  .btn11 {
    width: 160px;
    height: 49px;
    background: #00839b;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    // margin: 30px auto;
  }


}
@media (min-width: 320px) and (max-width: 426px) {
  .card-cont-pres{
    width: 95% !important;
    padding: 5px;
    min-height: 400px !important; 
  }

}
// @media (min-width: 300px) and (max-width: 320px) {
//   .card-cont-pres{
//     min-height: 400px ; 
//   }
// }
