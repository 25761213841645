@import url('https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500&display=swap');

$fontnormal :"VisueltRegular";
$fontmedium: "VisueltMedium";
$backgroundColour : #F2F8F9;
$primaryColour:#00839B;
$paraColour:#4D777C;
$headColor:#013C44;

.playstorelink{
    display: flex;
    width: 500px;
    margin: auto;
    justify-content: space-between;
    padding-top: 26px;
    padding-bottom: 26px;

  
    
}
.loginImage{
    // background-image: url(../assets/AuthImages/bg1.svg);
    background-image: url(../assets/images/bg2.png);
    height: 100vh;
    background-repeat: no-repeat;
    background-position:top center;
    position: relative;
    background-size: contain;
    background-size: cover;
    resize: both;
    mix-blend-mode: darken;
    .overlay{
        background-image: url(../assets/images/overlay.svg);
        height: 100vh;
        background-repeat: no-repeat;
        background-position:top center;
        background-size: cover;
        z-index: 1;
    }
    .loginHeadText{
        position: absolute;
        font-family: $fontnormal;
        bottom: 20%;
        color: white;
        font-style: normal;
        font-weight: 600;
        font-size: 4.444vw;
        line-height: 70px;
        padding: 0% 8%;
    }
    .loginParaText{
        position: absolute;
        font-family: $fontnormal;
        font-style: normal;
        font-weight: normal;
        bottom: 5%;
        color:white;
        font-size: 1.667vw;
        padding: 0% 8%;
    }
    
}
// .loginImage::before{
//     content: '';
//     position: absolute;
//     height: 120vh;
//     background-color:#013C44;
//     opacity: 80%;
// }

.innerDiv2{
    background-color: $backgroundColour;
    font-family: $fontnormal;
}

.logoHead{
    display: flex;
    justify-content: space-between;
    padding: 5% 5%;
    .downloadBtn{
        padding: 2%;
        border: 1px solid $primaryColour;
        color: $primaryColour;
        background-color: $backgroundColour;
        height: 44px;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        width: 152px;
    }
}
.downloadBtn:hover{
    background-color: $primaryColour;
    color: white;
}
@media (max-width: 991px) {
    .innerDiv1{
        display: none;
    }
}


@media (min-width: 300px) and (max-width: 767px) {
    .playstorelink{
        // background-color: brown;
        width: 100%;
        flex-direction: column;
        height: 150px;
        padding: 0;
     
    }
    .googleplay,.applestore{
    
        margin-top: 10px;
        margin-left:30px;
    }
}
@media (min-width: 768px) and (max-width: 1279px) {
    .playstorelink{    
            width: 100%;
            justify-content: space-around;
      
        
    }
}