html {
  body {
    margin: 0;
    padding: 0;
    overflow: auto !important;
    min-width: 100% !important;
    font-family: "VisueltRegular";
  }
}

@font-face {
  font-family: "GreycliffCFBold";
  src: local("GreycliffCFBold"),
    url("./assets/fonts/GreycliffCF-Bold.otf") format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "GreycliffCFRegular";
  src: local("GreycliffCFRegular"),
    url("./assets/fonts/GreycliffCF-Regular.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "VisueltRegular";
  src: url("./assets/fonts/visuelt-regular.eot");
  src: url("./assets/fonts/visuelt-regular.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/visuelt-regular.woff2") format("woff2"),
    url("./assets/fonts/visuelt-regular.woff") format("woff"),
    url("./assets/fonts/visuelt-regular.ttf") format("truetype"),
    url("./assets/fonts/visuelt-medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "VisueltMedium";
  src: url("./assets/fonts/visuelt-medium.eot");
  src: url("./assets/fonts/visuelt-medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/visuelt-medium.woff2") format("woff2"),
    url("./assets/fonts/visuelt-medium.woff") format("woff"),
    url("./assets/fonts/visuelt-medium.ttf") format("truetype"),
    url("./assets/fonts/visuelt-regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
input[type="text"]:focus {
  outline: none !important;
  outline-offset: 0px !important;
}
input[type="text"] {
  outline: none !important;
  outline-offset: 0px !important;
}
.modal-dialog {
  input[type="text"]:focus {
    outline: none !important;
    outline-offset: 0px !important;
  }
  input[type="text"] {
    outline: none !important;
    outline-offset: 0px !important;
  }
}
#zmmtg-root {
  display: none;
}
@media (max-width: 1050px) {
  #zmmtg-root {
    .more-button {
      display: none;
    }
    .send-video-container {
      width: 90px;
    }
  }
}
@media (max-width: 600px) {
  .bmiSeperator {
    flex-direction: column;
  }
  .bmiInfo {
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #00839b;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background: #00839b;
  border-radius: 3px;
}

.loadingcontainer {
  position: fixed;
  z-index: 1300;
  inset: 0px;
  width: 100%;
  height: 100vh;
  background-color: #4c4a4a75;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-tel-input .form-control {
  font-size: 16px;
  background: #ffffff;
  border: 1px solid #adc2c2;
  border-radius: 5px;
  width: 100% !important;
  outline: none;
  padding: 18.5px 14px 18.5px 60px;
  transition: box-shadow ease 0.25s, border-color ease 0.25s;
  // color: #495057;
}

.closeButtonPopup {
  img {
    float: right;
    padding: 12px;
  }
}

.better {
  display: flex;
  font-family: "VisueltRegular";
  color: #013c44;
  justify-content: space-around;
}

.react-date-picker__wrapper {
  border: none !important;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff !important;
}

.MuiTab-root {
  color: #4d777c !important;
}

.Mui-selected {
  color: #00839b !important;
}

.MuiTab-wrapper {
  text-transform: capitalize;
  font-size: 16px;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari/Edge */
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

input:-moz-placeholder {
  /* Firefox 18- */
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

input::placeholder {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */

  /* color/primary/tints/deep-docteal-T2 */

  color: #adc2c2;
}

// .carousel-indicators {
//   z-index: -1 !important;
// }

.PrivateTabIndicator-colorPrimary-8 {
  background-color: #00839b !important;
}

@media (min-width: 300px) and (max-width: 767px) {
  .better {
    font-size: 16px;
    margin-left: 12px;
    padding-top: 12px;
    padding-bottom: 24px;
  }
  .store {
    justify-content: space-around;
    .googleplay {
      padding-top: 32px;
      padding-bottom: 32px !important;
    }
    .googleplay,
    .applestore {
      margin: auto !important;
    }

    .applestore {
      padding-top: 12px !important;
    }
  }
}
@media (min-width: 768px) and (max-width: 1279px) {
  .better {
    font-size: 20px;
  }
}
