
    // .filter-comp-cls-hr{
    .select-date-pres{
        padding-left: 74px;
    }
    .select-doc-pres{
        padding-left: 28px;
    }
    .btn-class-hr-filter{
          display: flex;
          align-items: flex-end;
          margin-top: 2%;
        .filter-btn{
            width:145px;
    height: 44px;
    background: #00839B;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 28px;
    // margin-top: 3%;
    cursor: pointer;
    span{
        display: flex;
    justify-content: center;
    align-items: center;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
    }
        }
        .clear-btn{
            width:145px;
            height: 44px;
            background: #00839B;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 28px;
            margin-top: 3%;
            cursor: pointer;
            span{
                display: flex;
            justify-content: center;
            align-items: center;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            }
        }
    }
    .upload-align-hr{
       display: flex;
       margin-top: 2%;
       justify-content: flex-end;
    flex-direction: row;    
    margin-left: auto;
    margin-right: 4%;
    align-items: center;
          .filter-btn-upload{
              width:145px;
      height: 44px;
      background: #00839B;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 28px;
      margin-top: 6%;
      cursor: pointer;
      span{
          display: flex;
      justify-content: center;
      align-items: center;
      font-family: "VisueltRegular";
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 15px;
      color: #FFFFFF;
      }
          }
          .clear-btn-upload{
              width:145px;
              height: 44px;
              background: #00839B;
              border-radius: 5px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 28px;
            
              margin-top: 6%;
              cursor: pointer;
              span{
                  display: flex;
              justify-content: center;
              align-items: center;
              font-family: "VisueltRegular";
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 15px;
              color: #FFFFFF;
              }
          }
      }
    //   @media (min-width: 768px) and (max-width: 1279px) {
    //     .select-date-pres{
    //         padding-left: 0px !important;
    //         padding-top: 4%;
    //     }
    //     .select-doc-pres{
    //         padding-left: 0px !important;
    //         padding-top: 4%;
    //     }
    // }
@media (min-width: 300px) and (max-width: 767px) {
    .select-date-pres{
        padding-left: 60px;
        padding-top: 4%;
    }
    .select-doc-pres{
        padding-left: 60px;
        padding-top: 4%;
    }
    .btn-class-hr-filter{
      margin-top: 4%;
      .filter-btn{
          max-width:100px;
  height: 34px;
  margin-left: 10px;
  span{
  font-size: 12px;
  }
      }
      .clear-btn{
          max-width:100px;
          height: 34px;
          span{
          font-size: 12px;
          }
      }
  }
  .upload-align-hr{
      margin-left: auto;
      margin-right: auto;
        .filter-btn-upload{
            max-width:100px;
    height: 34px;
    span{
    font-size: 12px;
    }
        }
        .clear-btn-upload{
            max-width:100px;
            height: 34px;
            span{
            font-size: 12px;
            }
        }
    }
}