.ReasonForVisit,.SadnReasonForVisit{
    width: 502.8px;
    height: 437.62px;
    background: #F2F8F9;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 6%;
    padding: 9px 31px;
    .input{
        width: 439.26px;
        // width: 100%;
    font-family: "VisueltRegular";
    color: #013C44;
    height: 275.94px;
    background: #FFFFFF;
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 30px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid #D9EDF1;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    resize: none;

    }
    .input-find{
        width: 100%;
    font-family: "VisueltRegular";
    color: #013C44;
    height: 275.94px;
    background: #FFFFFF;
    padding-left: 15px;
    padding-top: 10px;
    padding-right: 30px;
    font-size: 15px;
    border-radius: 5px;
    border: 1px solid #D9EDF1;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    resize: none;
    }
}
#reasonSpecial{
    width: auto;
    max-height: 280px;
    .input{
        max-height: 114.38px;
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .ReasonForVisit,.SadnReasonForVisit{
        width: 100%;

        .input{
            width: 100%;
        }
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .ReasonForVisit,.SadnReasonForVisit{
        width: 100%;

        .input{
            width: 100%;
        }
    }
}

.SadnReasonForVisit{
    height: auto;
    .input{
    height: 168px;
    }
}

