$fontnormal :VisueltRegular;
$fontmedium: VisueltMedium;
$backgroundColour : #F2F8F9;
$primaryColour:#00839B;
$paraColour:#4D777C;
.head {
    height: 80px;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0% 3%;

    box-shadow: 0px 0px 8px 5px rgba(3, 130, 153, 0.08);
}
.loginButton {
    padding: 1% 5%;
    background-color: #00839b;
    color: white;
    border-radius: 5px;
    a {
        color: white;
    }
}
.loginButton:hover {
    background-color: $primaryColour;
    color: white;
}

.loginBtn{
    padding: 2%;
    border: 1px solid $primaryColour;
    color: white;
    background-color: $primaryColour;
    height: 44px;
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    width: 152px;

}
.loginBtn:hover{
    background-color: $backgroundColour;
    color:$primaryColour;
}
.bg {
    font-family: $fontnormal;
    background-color: $backgroundColour;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
