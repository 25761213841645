.HealthAndSupport{
    padding: 2% 4%;
    display: block;
    .heading{
        display: block;
        width: 532.53px;
        height: 64.02px;
        left: 281.78px;
        top: 280.62px;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #013C44;
    }
    .container{
        float: left;
        display: block;
        width: 450.32px;
        height: 131.74px;
        background: #F2F8F9;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        border-radius: 5px;
        margin-top: 100px;
        padding: 20px 30px;

        .container_heading{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 32px;
            color: #013C44;
        }

        p{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #4D777C;
            margin-right: 80px;
            margin-top: 10px;
        }
    }
.btnContainer{
    width: 100%;
    display: block;
    float: left;

    .supportBtn{
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 274px;
        height: 49px;
        background: #00839B;
        border-radius: 5px;
        color: #FFFFFF;
        font-family: 'VisueltRegular';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 18px;
        margin-top: 20px;
    }
}
    
    
}

@media (min-width: 768px) and (max-width: 1279px) {
  .HealthAndSupport{
      .heading{width: 100%;}
      .container{
          width: 100%;
      }
  }
}

@media (min-width: 300px) and (max-width: 767px) {
    .HealthAndSupport{
        .heading{width: 100%;}
        .container{
            width: 100%;
        }
    }
}