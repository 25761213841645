.AddDocuments {
    width: 1047px;
    // width: 100%;
    height: 'auto';
    background: #f2f8f9;
    border: 1px solid #d9edf1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 15px;
    padding: 15px 25px 30px 25px;
    .inputContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .dropdownContainer {
            width: 473.25px;
            .selectSaveDocContainer{
                display: flex;
                width: 100%;
                padding: 0px 15px;
                align-items: center;
                justify-content: space-between;
                background: #FFFFFF;
                border: 1px solid #D9EDF1;
                box-sizing: border-box;
                border-radius: 5px;
                height: 48px;
                cursor: pointer;

                div{
                    font-family: 'VisueltMedium';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;
                    color: #013C44;
                }
                img{
                    cursor: pointer;
                }
            }
        }
        .searchContainer {
            width: 473.25px;

            .searchField {
                display: flex;
                position: relative;
                height: 48px;
                width: 473.25px;
                border: 1px solid #d9edf1;
                box-sizing: border-box;
                border-radius: 5px;
                background: #ffffff;
                align-items: center;
                background: #ffffff;
                .search {
                    width: 90%;
                    padding-left: 10px;
                    font-size: 18px;
                    font-family: "VisueltRegular";
                    color: #013c44;
                    outline: none;
                    border: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    resize: none;
                }
                img {
                    position: absolute;
                    right: 15px;
                    width: 17, 49px;
                    height: 17.51px;
                }
            }
        }
    }
    .buttonContainer {
        display: flex;

        .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #ffffff;
            background-color: #00839b;

            line-height: 107%;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            padding: 1% 1% 0.8%;
            cursor: pointer;
            width: 109px;
            height: 44px;
            border: 2px solid #00839b;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 4%;
            margin-right: 2%;
            &:hover {
                color: #00839b;
                background: transparent;
            }
        }

        .buttonTwo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #00839b;
            line-height: 107%;
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            padding: 1% 1% 0.8%;
            cursor: pointer;
            width: 276px;
            // height: 44px;
            border: 2px solid #00839b;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 4%;

            &:hover {
                color: #ffffff;
                background-color: #00839b;
            }
        }
    }

    .additionalInformation {
        width: 958.1px;
        height: auto;
        background: #ffffff;
        border: 1px solid #d9edf1;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 10px 10px;

        .table {
            width: 100%;

            th {
                font-family: "VisueltMedium";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 133%;
                /* identical to box height, or 133% */
                color: #013c44;
                text-align: left;
            }
            td {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 144%;
                /* identical to box height, or 144% */
                text-align: left;

                color: #013c44;
            }

            .noRecords{
                width: 100%;
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                /* identical to box height, or 129% */
                color: #013C44;
                text-align: center;
            }
        }

        .noRecords{
            width: 100%;
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                /* identical to box height, or 129% */
                color: #013C44;
                text-align: left; 
        }

        .docContainer{
            display: flex;
            border: 1px solid #D9EDF1;
            background: #F2F8F9;
            padding: 20px;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 10px;
            .folderIcon{
                color:  #013C44;
                width: 10%;
                
            }

            .docName{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #013C44;
                width: 80%;
            }

            .delIcon{
                color: red;
                width: 10%;
                cursor: pointer;

                &:hover{
                    transform: scale(1.3);
                }
            }
        }
    }
}

.modal-upload {
    width: 620px;
    .uploadDocuments {
        height: auto;
        background: #ffffff;
        box-shadow: 0px 4px 6px rgba(3, 61, 69, 0.18);
        border-radius: 5px;

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // padding: 15px 15px 20px 25px;
            font-family: "VisueltMedium";
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            height: 50px;
            /* or 133% */
            color: #013c44;
            padding: 40px 20px 20px 20px;
            .heading_line {
                border: 1px solid #d9edf1;
                width: 100%;
                height: 0px;
            }
        }

        main {
            padding: 10px 40px 40px 40px;

            .uploadedText{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                margin-bottom: 15px;
                font-size: 16px;
                line-height: 16px;
                color: #4D777C;
                margin-top: 15px;
            }

            .instruction{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                color: #4D777C

                span{
                    font-family: 'VisueltMedium';
                }
            }

            .uploadContainer {
                
                .file-drop{
                    .file-drop-target{
                        width: 100%;
                        height: 72px;
                        position: relative;
                        border: 1px dashed #ADC2C2;
                        box-sizing: border-box;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 20px;
                        font-family: 'VisueltRegular';
                        font-style: normal;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 18px;
                        color: #ADC2C2;

                        .uploadFile{
                            position: absolute;
                            bottom: 6px;
                            right: 13px;
                            width: 126px;
                            height: 44px;
                            border: 1px solid #00839B;
                            box-sizing: border-box;
                            border-radius: 5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-family: 'VisueltRegular';
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 15px;
                            color: #00839B;
                            cursor: pointer;
                        }
                    }
                }
                .fileInput {
                    opacity: 0;
                    z-index: -1;
                }
            }

            .instruction {
                margin-bottom: 70px;
            }

            .inputField {
                //width: 541px;
                height: 48px;
                border: 1px solid #adc2c2;
                box-sizing: border-box;
                border-radius: 5px;
                margin-bottom: 30px;
                .input {
                    width: 100%;
                    height: 100%;
                    padding-left: 10px;
                    font-family: "VisueltRegular";
                    color: #013c44;
                    font-size: 15px;
                    outline: none;
                    border: none;
                    border-radius: 5px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    -ms-appearance: none;
                    resize: none;
                }
            }
            .samllInputContainer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .inputFieldTwo {
                    // width: 47%;
                    // height: 48px;
                    box-sizing: border-box;
                    border-radius: 5px;
                    margin-bottom: 30px;
                    .react-date-picker {
                        display: inline-flex;
                        position: relative;
                            // outline: none !important;
                            // border-radius: 5px;
                            // border: 1px solid #ADC2C2;
                        // min-height:48px;
                        // width: auto;
                        // min-width: 205px !important;
                        
                              width: 100%;
                        height: 100%;
                    }
                    // .input {
                    //     width: 100%;
                    //     height: 100%;
                        // padding-left: 10px;
                        // font-family: "VisueltRegular";
                        // color: #013c44;
                        // font-size: 0.833vw;
                        // outline: none;
                        // border: none;
                        // border-radius: 5px;
                        // -webkit-appearance: none;
                        // -moz-appearance: none;
                        // -ms-appearance: none;
                        // resize: none;
                    // }
                }
              
            }
            .buttonContainer {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                .saveDoc {
                    display: flex;
                    float: right;
                    align-items: center;
                    justify-content: center;
                    width: 207px;
                    height: 49px;
                    background: #00839b;
                    border-radius: 5px;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    /* identical to box height, or 100% */
                    color: #ffffff;
                    margin-right: 20px;
                    cursor: pointer;

                    &:hover {
                        background-color: transparent;
                        border: 1px solid #00839b;
                        color: #00839b;
                    }
                }

                .disa{
                    pointer-events: none;
                    cursor: not-allowed;
                    // background-color: gray;
                }

                .cancelDoc {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 160px;
                    height: 49px;
                    color: #00839b;
                    border-radius: 5px;
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    /* identical to box height, or 100% */

                    border: 1px solid #00839b;
                    &:hover {
                        background: #00839b;
                        color: #ffffff;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .AddDocuments {
        width: 100%;
        height: auto;
        margin-top: 100px;

        .inputContainer {
            width: 100%;
            flex-direction: column;

            .dropdownContainer {
                width: 100%;
                
            }
            .searchContainer {
                width: 100%;
                .searchField {
                    width: 100%;
                }
            }
        }

        .additionalInformation {
            width: 100%;

            .table {
                width: 100%;

                img {
                    width: 10px;
                }

                th {
                    font-size: 16px;
                }

                td {
                    font-size: 16px;
                }
            }

            .docContainer{
                width: 100%;
            }
        }
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    .AddDocuments {
        width: 100%;
        height: auto;

        .inputContainer {
            width: 100%;
            flex-direction: column;

            .dropdownContainer {
                width: 100%;
            }
            .searchContainer {
                width: 100%;
                .searchField {
                    width: 100%;
                }
            }
        }

        .additionalInformation {
            width: 100%;

            .table {
                width: 100%;

                img {
                    width: 10px;
                }

                th {
                    font-size: 10px;
                }

                td {
                    font-size: 10px;
                }
            }

            .docContainer{
                width: 100%;
            }
        }
    }
}

input[type=date]::-webkit-datetime-edit-text {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-month-field{
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-day-field {
    -webkit-appearance: none;
    display: none;
}
input[type=date]::-webkit-datetime-edit-year-field {
    -webkit-appearance: none;
    display: none;
}
