  .filter-row-upload-rec{
    .filter-row{
        display: flex;
        justify-content: flex-end;
        margin-top: 28px;
        margin-right: 61px;
        padding-bottom:30px;
        .add-doc-btn{
            width: 194px;
height: 44px;
background: #00839B;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
span{
    display: flex;
    justify-content: center;
    align-items: center; 
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;
color: #FFFFFF;
}
        }
    }
    .content-box{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 1093px;
height: 100%;
background: #FFFFFF;
border: 1px solid #D9EDF1;
box-sizing: border-box;
border-radius: 5px;
// margin-top:28px;
// margin-left: 62px;
margin-bottom: 170px;
.card-align-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 28px;
.card-cont{
    width: 328px;
min-height: 439px;
background: #FFFFFF;
border: 1px solid #D9EDF1;
box-sizing: border-box;
border-radius: 5px;
margin-top: 23px;
margin-left: 22px;
padding-bottom: 31px;
.header-font{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
display: flex;
align-items: center;
color: #013C44;
padding-top:22px;
padding-left: 32px;

}
.sub-cont{
    width: 277px;
height: 87px;
background: #EFFFF8;
border: 1px solid #C4F0DD;
box-sizing: border-box;
border-radius: 5px;
margin-left:32px;
margin-top:13px;
display: flex;
flex-direction: row;
justify-content: space-around;
align-items: center;
.sub-cont-font{
font-family:  "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: #013C44;
}
}
.card-info-cont{
display: flex;
flex-direction: column;
padding-top:21px;
padding-left:32px;
.card-info-font{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: #013C44;
padding-bottom: 6px;
}
.divider{
border: 1px solid #D9EDF1;
width: 277px;
height: 0px;
margin-top:16px;    
}
.sub-header-font{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #66B5C3;
padding-top: 16px;
}
.sub-header-sub-font{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 22px;
padding-top: 8px;
color: #013C44;
}
.card-bottom-row{
    display: flex;
    justify-content: flex-start;
    margin-top: 28px;
    .review-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 203px;
        height: 44px;
        background: #00839B;
        border-radius: 5px;
        cursor: pointer;
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #FFFFFF;
        }
    }
    .delete-cont{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58px;
height: 44px;
border: 1px solid #66B5C3;
box-sizing: border-box;
border-radius: 5px;
margin-left: 16px;
cursor: pointer;
img{
    display: flex;
        justify-content: center;
        align-items: center;
}
    }
}

}
}
}
    }
}
    .ContainerWrapper {
        position: relative;
        margin-top: 50px;
        margin-right: 50px;
        .Closepopup {
          position: absolute;
          transform: scale(1.5);
          top: -30px;
          right: -30px;
          cursor: pointer;
        }
      }
      .pdfiframe{
        height: 75vh;
        width: 96%;
        margin: 25px;
        border: 0px;
        display: block;
    
      }
    
    @media (min-width: 768px) and (max-width: 1279px) {
    
  .filter-row-upload-rec{
        .content-box{
            width: 620px;
    .card-align-cont{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 28px;
    .card-cont{
        width: 228px;
    height: 370px;
    background: #FFFFFF;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 23px;
    margin-left: 22px;
    .header-font{
    font-size: 16px;  
    }
    .sub-cont{
        width: 150px;
    height: 67px;
    .sub-cont-font{
    font-size: 10px;
    }
    }
    .card-info-cont{
    display: flex;
    flex-direction: column;
    padding-top:21px;
    padding-left:32px;
    .card-info-font{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    color: #013C44;
    padding-bottom: 6px;
    }
    .divider{
    border: 1px solid #D9EDF1;
    width: 200px;
    height: 0px;
    margin-top:5px;    
    }
    .sub-header-font{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    color: #66B5C3;
    padding-top: 5px;
    }
    .sub-header-sub-font{
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    padding-top: 2px;
    color: #013C44;
    }
    .card-bottom-row{
        display: flex;
        justify-content: flex-start;
        margin-top: 10px;
        .review-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100px;
            height: 34px;
            background: #00839B;
            border-radius: 5px;
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: #FFFFFF;
            }
        }
        .delete-cont{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 38px;
    height: 34px;
    border: 1px solid #66B5C3;
    box-sizing: border-box;
    border-radius: 5px;
    margin-left: 16px;
    
    img{
        display: flex;
            justify-content: center;
            align-items: center;
    }
        }
    }
    
    }
    }
    }
        }
    }
}
