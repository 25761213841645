.tab-cls-health-rec{
    margin-top:36px;
    // margin-left: 60px;
    .MuiBox-root{
        padding-left: 0px !important;
    }
}
@media screen and (max-width: 440px) {
    .tab-cls-health-rec{
        margin-top:36px;
        margin: 10px;
      
    }

}
