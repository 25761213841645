.modal-content-Health-pop{
    max-width: 513px;
height: 423px;
    background: #FFFFFF;
    box-shadow: 0px 4px 6px 2px rgba(3, 61, 69, 0.18);
    border-radius: 5px;
}
.hp-submit-pop-cont{
.header{
    display: flex;
    .close-btn{
        margin-left: auto;
        // padding-right: 16px;
        margin-top:16px;
        cursor: pointer;
    }
    
}
.success-img-cont-wrap{
    margin-top: 51px;
    align-items: center;
    justify-content: center;
    display: flex;
.success-img-cont{
  
    width: 81.5px;
height: 81.5px;
background: #F2F8F9;
border-radius: 50%;
display: flex;
    justify-content: center;
    align-items: center;
.img-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.img-tick{
    position: absolute;
   
}
}
}
.sub-header-wrap{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
.sub-header{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */


color: #013C44;
text-align: center;
margin-top: 17px;
// margin-left: 130px;
span{
    display: block;
    margin-left: 23px;
}
}
}
.container-bottom{
    max-width: 430px;
height: 230px;
background: #EFFFF8;
border: 1px solid #C4F0DD;
box-sizing: border-box;
border-radius: 5px;
margin-left: 41px;
margin-top: 35px;
display: flex;
align-items: center;
flex-direction: column;
padding: 10px;
.text-cls{

font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
/* or 133% */

text-align: center;

color: #013C44;
margin-top:28px;
// margin-left: 41px;
span{
    display: block;
    
    // padding-left: 10px;
}
.span-align{
    // padding-left: 100px;
}
}
.inner-cont{
max-width: 284px;
height: 67px;
background: #00839B;
border-radius: 5px;
margin-top: 28px;
// margin-left: 69px;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
span{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 18px;
/* or 100% */

text-align: center;

color: #FFFFFF;

}
}
}
}
@media (min-width: 300px) and (max-width: 767px) {
    // .modal-content-submit{
    //     width: 350px;
    // }
    .header{
        display: flex;
        .close-btn{
            margin-left: auto;
            // padding-right: 16px;
            margin-top:16px;
        }
        
    }
    
    .sub-header{
    font-size: 12px;
    //margin-left: 110px;
    span{
        padding-left: 10px;
    }
    }
    .container-bottom{
      display: flex;
      margin-left: 15px !important;
      margin-right: 15px !important;
    .text-cls{
    font-size: 10px;
    margin-top:28px;
    }
    .inner-cont{
    // margin-left: 45px;
    span{
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    /* or 100% */
    
    text-align: center;
    
    color: #FFFFFF;
    
    }
    }
    }
}