* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

:root {
  --background-color: #ffffff;
  --themeColor: #00839b;
  --borderRadius: 0.5em;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-auto-rows: minmax(100px, auto);
}

.headerConatiner {
  position: sticky;
  z-index: 999;
  top: 0;
  background-color: var(--background-color);
  grid-column: 1/13;
  display: flex;
  justify-content: space-between;
  padding: 0px 4.5em;
  height: 107px;
  align-items: center;
  box-shadow: 0px 0px 8px 1px rgba(3, 130, 153, 0.08);
}
.headerConatiner .titleImage img {
  width: 110px;
}
.navItems {
  margin: 0.5em;
  font-family: "VisueltRegular";
}

.navItems a {
  color: var(--themeColor);
}

.landingpage-container {
  max-width: 1085px;
  margin: auto;
}
.toggle-icon {
  display: none;
}

.firstContainer {
  background-color: #ffffff;
  grid-column: 1/13;
  grid-row: 2/8;
  margin-top: 4%;
}

.firstContainer .heading-section .heading {
  color: var(--themeColor);
  text-align: center;
  font-weight: 500;
  font-family: "VisueltMedium";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 58px;
  margin-bottom: 1.5em;
}

.firstContainer .card {
  display: flex;
  flex-direction: column;
  border: none;
}

.card > .cardone {
  height: 290.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.card > .cardtwo {
  height: 290.5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.card > .cardone .one {
  width: 443px;
  height: 290.5px;
  cursor: pointer;
}
.card > .cardone .two {
  height: 290.5px;
  width: 624px;
  cursor: pointer;
}

.card > .cardtwo .one {
  height: 290.5px;
  width: 624px;
  cursor: pointer;
}
.card > .cardtwo .two {
  height: 290.5px;
  width: 443.5px;
  cursor: pointer;
}

.card-items {
  border-radius: var(--borderRadius);
}

.card-items {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 400;
}

.card-item-text {
  color: #ffffff;
  width: 70%;
}

.card-items-text > h1,
.card-items-link a {
  color: #ffffff;
}

.card-item-link a {
  color: #ffffff;
  font-size: 1rem;
  text-transform: uppercase;
}

.downloadButtons .buttons button {
  background-color: var(--themeColor);
  margin: 0.5em;
  padding: 1em 2.5em;
  color: white;
  border-radius: var(--borderRadius);
}

.downloadButtons .buttons {
  text-align: center;
}

.downloadButtons .buttons .app {
  margin-right: 1.5em;
}

.downloadButtons {
  margin: 5em;
}

.store-buttons {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 100px;
  padding: 2.5% 0px 0px 3%;
}
.linefooter {
  height: 1px;
  width: 92%;
  margin: 0px 4%;
  background-color: #66b5c3;
}

.store-buttons .contact {
  grid-column: 1/3;
  grid-row: 1/3;
  color: white;
}
.store-buttons .contact p {
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 0px;
}
.store-buttons .buttons1 .playstorebtn,
.store-buttons .buttons2 .appstorebtn {
  background-color: var(--themeColor);
  margin: 0.8em 0;
  padding: 1em 0.5em;
  width: 150px;
  color: white;
  border-radius: var(--borderRadius);
}

.store-buttons .buttons {
  grid-column: 3/6;
  border: 1px solid black;
}

.playstorebtn {
  grid-column: 3/5;
}

.appstorebtn {
  grid-column: 5/6;
}

.footer-social {
  display: flex;
  justify-content: space-between;
  padding: 21px 54px;
}

.footer-social .text-content p {
  color: #ffffff;
  font-size: 14px;
  line-height: 18px;
}

.footer-social .socialIcon {
  margin-right: 1em;
}
.socialIcons {
  white-space: nowrap;
}

.secondContainer {
  grid-column: 1/13;
  grid-row: 8/15;
  background-color: #f2f8f9;
  padding: 3em;
  padding-bottom: 0px;
}

.secondContainer .secondcard,
.thirdContainer .thirdcard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4em;
}

.secondcard .card-item,
.thirdcard .card-item {
  width: 344px;
  height: 335px;
  margin: 0 0.2em;
  border: 1px solid rgba(173, 194, 194, 0.5);
  box-sizing: border-box;
  border-radius: 5px;
}
.card-item img {
  width: 100%;
  object-fit: cover;
  height: 50%;
  border-radius: 5px 5px 0px 0px;
}

.secondContainer .text-content,
.thirdContainer .text-content {
  max-width: 500px;
  margin: auto;
  text-align: center;
}

.secondContainer .card-text-content {
  background-color: #ffffff;
  padding: 24px;
  height: 50%;
  border-radius: 5px;
}
.thirdContainer .card-text-content {
  background-color: #f2f8f9;
  padding: 24px;
  height: 50%;
  border-radius: 5px;
}
.secondContainer .card-text-content h4,
.thirdContainer .card-text-content h4 {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  font-size: 26px;
  letter-spacing: 0.105em;

  color: #013c44;
}
.secondContainer .card-text-content p,
.thirdContainer .card-text-content p {
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  line-height: 21px;
  font-size: 16px;
  color: #013c44;
}
.text-content > h1 {
  font-weight: 500;
  color: #00839b;
  font-family: "VisueltMedium";
  font-size: 36px;
}

.text-content > p {
  color: #013c44;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
}

.thirdContainer {
  grid-column: 1/13;
  grid-row: 15/22;
  background-color: #ffffff;
  padding: 3em;
}

.footers {
  grid-column: 1/13;
  grid-row: 22/27;
  background-color: #013c44;
}

.footers .links {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 60px;
  padding: 2.5% 0px 0px 3%;
}

.footers .footer-links > h5 {
  color: #76dcb0;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  letter-spacing: 3.5px;
  text-transform: uppercase;
  margin-bottom: 1em;
  font-size: 12px;
}

.footers .footer-links > p {
  color: #ffffff;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
}

.footers .links .titleImage {
  grid-row: 1/3;
  grid-column: 1/3;
}

.footers .links .titleImage img {
  height: 30px;
  margin-bottom: 40px;
}

.footers .links p {
  color: white;
  font-size: 14px;
  line-height: 24px;
}

.columnOne {
  width: 95%;
}
.columnThree {
  width: 105%;
}

.columnFour {
  width: 115%;
}
.store_container {
  display: flex;
  width: 100%;
  padding: 54px 0px 20px 50px;
  flex-direction: row;
}

.footer_contact_info {
  width: 30%;
  color: #ffffff;
}

.download_btn {
  width: 26%;
  display: flex;
}

.download_btn img {
  margin-right: 30px;
}
.twitter {
  margin-left: 55px;
}
.socialIcon img {
  margin-right: 30px;
}

.footer_bottom {
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .store_container {
    flex-direction: column;
    padding: 0;
    width: 95%;
    padding: 0.9em;
  }
  .footer_contact_info {
    width: 100%;
    margin-bottom: 20px;
  }

  .twitter {
    margin-left: 0px;
    /* padding: 0.9em; */
  }
  .socialIcons {
    margin-bottom: 20px;
  }

  .socialIcon img {
    margin-top: 20px;
  }

  .headerConatiner {
    padding: 1em;
    position: relative;
  }
  .titleNavItems {
    display: none;
  }
  .toggle-icon {
    display: inline;
  }

  .modalnav {
    position: absolute;
    top: 0;
    height: 100%;
    flex-direction: column;
    justify-content: normal;
    z-index: 9999;
    width: 100%;
  }
  .modalnav .titleNavItems {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .modalnav img {
    display: block;
    align-self: flex-end;
  }
  .downloadButtons {
    margin: auto;
    padding: 1em;
  }
  .downloadButtons .buttons {
    display: flex;
    justify-content: center;
  }
  .downloadButtons .buttons img {
    display: block;
    width: 100%;
  }
  .thirdContainer .thirdcard {
    display: flex;
    flex-direction: row !important;
    overflow-x: hidden;
  }

  .thirdcard .card-item img {
    width: auto;
    height: auto;
  }
  .store-buttons .buttons1,
  .store-buttons .buttons2 {
    width: 100px;
  }
  .store-buttons .buttons2 img {
    width: 100%;
  }
  .store-buttons .buttons1 img {
    width: 100%;
  }
  .footers .links,
  .store-buttons,
  .footer-social {
    padding: 0.9em;
  }
}

@media only screen and (max-width: 768px) {
  .store_container {
    flex-direction: column;
    padding: 0;
    width: 95%;
    padding: 0.9em;
  }
  .footer_contact_info {
    width: 100%;
    margin-bottom: 20px;
  }

  .twitter {
    margin-left: 0px;
    /* padding: 0.9em; */
  }
  .socialIcons {
    margin-bottom: 20px;
  }

  .socialIcon img {
    margin-top: 20px;
  }
  .firstContainer .heading-section .heading {
    margin-bottom: 0px !important;
  }
  .firstContainer .card {
    display: flex;
    height: 780px;
    justify-content: space-evenly;
  }
  .card > .cardone {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 5px;
    width: 100%;
    justify-content: space-between;
  }
  .card > .cardtwo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .card > .cardone .one {
    width: 346px;
    height: 100%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  .card > .cardone .two {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }

  .card > .cardtwo .one {
    width: 346px;
    height: 100%;
    object-fit: cover;
    margin-bottom: 20px;
  }
  .card > .cardtwo .two {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }

  .downloadButtons .buttons a {
    margin: 1.5em;
  }
  .downloadButtons .buttons button {
    padding: 10px 0;
    width: 180px;
  }
  .downloadButtons .buttons .app {
    margin-right: 0;
  }
  .downloadButtons .buttons .play {
    margin-left: 0;
  }
  .secondContainer .downloadButtons {
    margin: 1.5em 0;
  }
  .secondContainer .text-content,
  .thirdContainer .text-content {
    width: 100%;
  }

  .secondContainer .secondcard,
  .thirdContainer .thirdcard {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
  .secondcard .landingpage-container {
    margin: 0;
  }
  .secondcard .card-item,
  .thirdcard .card-item {
    width: 100%;
    margin: 1.5em 0;
  }
  .thirdContainer .text-content h1,
  .secondContainer .text-content h1 {
    font-size: 2em;
  }

  .landingpage-container .heading-section .heading {
    font-size: 28px;
  }
  .secondcard .card-item,
  .thirdcard .card-item {
    padding-bottom: 0px !important;
  }

  .card-item-text {
    width: 60%;
    font-size: 1.5em;
  }
  .downloadButtons .buttons a {
    margin: 1.5em;
  }
  .downloadButtons .buttons button {
    padding: 10px 0;
    width: 180px;
  }
  .downloadButtons .buttons .app {
    margin-right: 0;
  }
  .downloadButtons .buttons .play {
    margin-left: 0;
  }
  .secondContainer .downloadButtons {
    margin: 1.5em 0;
  }
  .secondContainer .text-content,
  .thirdContainer .text-content {
    width: 100%;
  }

  .secondContainer .secondcard,
  .thirdContainer .thirdcard {
    flex-direction: column;
    margin: 0;
    width: 100%;
  }
  .secondcard .landingpage-container {
    margin: 0;
  }
  .secondcard .card-item,
  .thirdcard .card-item {
    width: 100%;
    margin: 1.5em 0;
  }
  .thirdContainer .text-content h1,
  .secondContainer .text-content h1 {
    font-size: 2em;
  }
}
@media (max-width: 1170px) {
  .store_container {
    flex-direction: column;
    padding: 2.5% 0px 0px 3%;
  }
  .footer_contact_info {
    width: 100%;
    margin-bottom: 20px;
  }

  .twitter {
    margin-left: 0px;
    /* padding: 0.9em; */
  }
  .socialIcons {
    margin-bottom: 20px;
  }
  .socialIcon img {
    margin-top: 20px;
  }
  .footers .links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .store-buttons {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
  }
  .store-buttons .contact {
    grid-column: 1/3;
  }

  .store-buttons .buttons1 .playstorebtn {
    grid-column: 3/4;
    margin-right: 0.5em;
  }
  .store-buttons .buttons2 {
    grid-column: 4/6;
    margin-left: 0.8em;
  }
  .footer-social .text-content p {
    font-family: "VisueltRegular";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    width: 60%;
  }
  .footer-social .socialIcons {
    margin-right: 0.5em;
  }
  .footers .links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .store-buttons .contact {
    grid-column: 1/6;
  }
  .store-buttons .buttons2 {
    grid-column: 3/4;
  }
  .footer-social .text-content p {
    font-family: "VisueltRegular";
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    width: 60%;
  }
  .footer-social .socialIcon {
    margin-right: 0.5em;
  }
  .secondcard .card-item {
    height: auto !important;
    padding-bottom: 40px;
    background-color: #fff;
  }
  .thirdcard .card-item {
    height: auto !important;
    padding-bottom: 40px;
    background-color: #f2f8f9;
  }
}
@media (min-width: 320px) and (max-width: 768px) {
  .leftBtn{display: none;}
  .rightBtn{display: none;}
}
@media (min-width: 768px) and (max-width: 1200px) {

  .firstContainer .heading-section .heading {
    margin-bottom: 40px !important;
  }
  .firstContainer .card {
    display: flex;
    flex-direction: column;
    height: 345px;
    justify-content: space-around;
    align-items: center;
  }
  .card > .cardone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    width: 700px;
  }
  .card > .cardtwo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
    width: 700px;
  }

  .card > .cardone .one {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }
  .card > .cardone .two {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }

  .card > .cardtwo .one {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }
  .card > .cardtwo .two {
    width: 346px;
    height: 100%;
    object-fit: cover;
  }
}
.sliderComponent{
  position: relative;
}
.leftBtn{
  width: 70.94px;
  position: absolute;
  height: 363px;
  z-index: 9;
  background: #ffffffa2;
  border-radius: 10px 0px 0px 10px;
}
.rightBtn{
  width: 70.94px;
  position: absolute;
  height: 363px;
  z-index: 9;
  right: 0%;
  border-radius: 0px 10px 10px 0px;
  background: #ffffffa2;
}
.leftBtn:hover{
  background: #f2f8f9a2;
}
.rightBtn:hover{
  background: #f2f8f9a2;
}