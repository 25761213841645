.change-screen-one-cont{


.header-text{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 34px;
display: flex;
align-items: center;
margin-top:3.6%;
margin-left: 60px;
color: #143656;
}
.sub-header-text{
    font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;
display: flex;
align-items: center;
color: #4D777C;
margin-left: 60px;
margin-top:8px;
}
.pass-cont-outer{
    width: 530px;
    height: 139px; 
    background: #F2F8F9;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top:31px;
    margin-left:60px;
    .cont-text{
        font-family: "VisueltRegular";
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
padding-top:32px;
padding-left:32px;
color: #013C44;
    }
    .input-cont{
        width: 466px;
height: 48px;
background: #FFFFFF;
border: 1px solid #D9EDF1;
box-sizing: border-box;
border-radius: 5px;
margin-left: 32px;
margin-top:7px;
outline: none;
input {
    border: none !important;
    outline: none !important;
    box-shadow: none;
    border-color: inherit;
    -webkit-box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    height: 48px;
    width: 100%;
    padding-left: 15px;
    color: #143656;
    height: 46px;
}
textarea:focus, input:focus{
    outline: none;
}
    }
    .error-cls-cont{
        display: flex;
        flex-direction: row;
        padding-left: 33px;
        padding-top:12px;
        justify-content: flex-start;
        align-items: flex-start;
        .img{
            display: flex;
        }
        .error-text{
            font-family: "VisueltRegular";
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
padding-left: 3px;
        }
    }
}
.submit-btn{
    width: 125px;
height: 49px;
left: 284px;
top: 449px;
background: #00839B;
border-radius: 5px;
display: flex;
justify-content: center;
align-items: center;
margin-top:24px;
margin-left:60px;
cursor: pointer;
span{
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    
}
}
}
@media (min-width: 768px) and (max-width: 1279px) {
    .change-screen-one-cont{
        .header-text{
            font-size: 22px;
            }
            .sub-header-text{ 
            font-size: 12px;
            }
    }
  
}
@media (min-width: 300px) and (max-width: 767px) {
    .change-screen-one-cont{
    .header-text{
        font-size: 16px;
        }
        .sub-header-text{ 
        font-size: 10px;
        }
        .pass-cont-outer{
            width: 300px;
            height: 139px; 
            display: flex;
            flex-direction: column;
            margin-right:auto;
            margin-left:auto;
            .cont-text{
        font-size: 12px;
            }
            .input-cont{
                width: auto;
        height: 48px;
        input {
            font-size: 10px;
        }
            }
            .error-cls-cont{
                .error-text{
        font-size: 10px;
                }
            }
        }
        .submit-btn{
            width: 100px !important;
        height: 39px;
        span{
            font-size: 12px;
        }
        }
    }
}