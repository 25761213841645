.label{
    font-family: 'VisueltMedium';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    color: #013C44;
    margin: 12px 0;
}
.label-health-record-filter{
    font-family: "VisueltMedium";
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
color: #ADC2C2;
margin: 8px 0;

}