.zoomiframe {
    width: auto;
    height: 100%;
    overflow: hidden;
    background: #f2f8f9;
    display: flex;
    justify-content: center;
    .doctorcontainer {
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        .blur1 {
            width: 235.54px;
            height: 235.54px;
            background: #d9edf199;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
        }
        .blur2 {
            width: 185.38px;
            height: 185.38px;
            background: #d9edf1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            .profileimg {
                width: 136.34px;
                height: 136.34px;
                border-radius: 50%;
                object-fit: cover;
            }
            .avatarimg {
                width: 136.34px;
                height: 136.34px;
                border-radius: 50%;
                object-fit: contain;
            }
        }
        .welcome-text-cont {
            justify-content: center;
            display: flex;
            align-items: center;
            .welcome-text {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                line-height: 32px;
                /* identical to box height, or 133% */
                margin-top: 48px;
                // margin-left: 572px;

                color: #013c44;
            }
        }
        .profile-text {
            font-family: "VisueltRegular";
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            /* or 133% */

            text-align: center;
            justify-content: center;
            display: flex;
            align-items: center;
            /* color/primary/deep-docteal */
            margin-top: 21px;
            color: #013c44;
        }
        .appoint-cont {
            width: 509.12px;
            height: 68.23px;
            margin-top: 33px;
            justify-content: center;
            display: flex;
            align-items: center;
            background: #effff8;

            border: 1px solid #c4f0dd;
            box-sizing: border-box;
            border-radius: 5px;
        }
        .appoint-det {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;
            .appoint-text {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 22px;
                color: #013c44;
            }
            .appoint-sub-cont {
                width: 132.13px;
                height: 43.2px;

                justify-content: center;
                display: flex;
                align-items: center;

                margin-left: 25px;
                background: #c4f0dd;
                border-radius: 5px;
                span {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 32px;
                    /* identical to box height, or 133% */

                    display: flex;
                    align-items: center;
                    text-align: center;

                    /* color/primary/deep-docteal */

                    color: #013c44;
                }
            }
        }
        .carousel-cls {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 10%;
            .item-cls-one {
                width: 333.29px;
                height: 161.36px;

                background: #ffffff;
                border: 0.960481px solid #c4f0dd;
                box-sizing: border-box;
                box-shadow: 0px 0.960481px 3.84192px 1.92096px rgba(0, 131, 155, 0.05);
                border-radius: 19.2096px;
                .item-cont {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;
                }
                .img-cont {
                    padding-left: 20px;
                    img{
                        width: 60px !important
                    }
                }
                .right-cont {
                    display: flex;
                    flex-direction: column;
                    padding-top: 22px;
                    padding-left: 20px;
                    padding-right: 20px;
                    .row-one {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 12.4862px;
                        line-height: 116.1%;
                        /* or 14px */

                        color: rgba(0, 131, 155, 0.5);
                    }
                    .row-two {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 15px;
                        line-height: 116.1%;
                        /* or 17px */

                        padding-top: 7px;
                        color: #013c44;
                    }
                    .row-three {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 13.4467px;
                        line-height: 116.1%;
                        /* or 16px */

                        padding-top: 22px;
                        color: #013c44;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
    .carousel-cls {
        margin-bottom: 15px;
    }
}
@media (min-width: 300px) and (max-width: 767px) {
    .zoomiframe {
        .doctorcontainer {
            .welcome-text-cont {
                .welcome-text {
                    font-size: 15px;
                }
            }
            .profile-text {
                font-size: 12px;
            }
            .appoint-cont {
                width: 350.12px;
            }
            .appoint-det {
                .appoint-text {
                    font-size: 10px;
                }
                .appoint-sub-cont {
                    width: 90.13px;
                    span {
                        font-size: 12px;
                    }
                }
            }
            .carousel-cls {
                .item-cls-one {
                    width: 233.29px;
                    height: 161.36px;

                    background: #ffffff;
                    border: 0.960481px solid #c4f0dd;
                    box-sizing: border-box;
                    box-shadow: 0px 0.960481px 3.84192px 1.92096px rgba(0, 131, 155, 0.05);
                    border-radius: 19.2096px;
                    .item-cont {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                    .img-cont {
                        padding-left: 20px;
                    }
                    .right-cont {
                        display: flex;
                        flex-direction: column;
                        padding-top: 22px;
                        padding-left: 20px;
                        .row-one {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 8.4862px;
                            line-height: 116.1%;
                            /* or 14px */

                            color: rgba(0, 131, 155, 0.5);
                        }
                        .row-two {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 116.1%;
                            /* or 17px */

                            padding-top: 7px;
                            color: #013c44;
                        }
                        .row-three {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 10.4467px;
                            line-height: 116.1%;
                            /* or 16px */

                            padding-top: 22px;
                            color: #013c44;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 300px) and (max-width: 767px) {
    .zoomiframe {
        .doctorcontainer {
            .welcome-text-cont {
                .welcome-text {
                    font-size: 15px;
                }
            }
            .profile-text {
                font-size: 12px;
            }
            .appoint-cont {
                width: 350.12px;
            }
            .appoint-det {
                .appoint-text {
                    font-size: 10px;
                }
                .appoint-sub-cont {
                    width: 90.13px;
                    span {
                        font-size: 12px;
                    }
                }
            }
            .carousel-cls {
                .item-cls-one {
                    width: 233.29px;
                    height: 161.36px;

                    background: #ffffff;
                    border: 0.960481px solid #c4f0dd;
                    box-sizing: border-box;
                    box-shadow: 0px 0.960481px 3.84192px 1.92096px rgba(0, 131, 155, 0.05);
                    border-radius: 19.2096px;
                    .item-cont {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-evenly;
                        align-items: center;
                    }
                    .img-cont {
                        padding-left: 20px;
                    }
                    .right-cont {
                        display: flex;
                        flex-direction: column;
                        padding-top: 22px;
                        padding-left: 20px;
                        .row-one {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 8.4862px;
                            line-height: 116.1%;
                            /* or 14px */

                            color: rgba(0, 131, 155, 0.5);
                        }
                        .row-two {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 116.1%;
                            /* or 17px */

                            padding-top: 7px;
                            color: #013c44;
                        }
                        .row-three {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 10.4467px;
                            line-height: 116.1%;
                            /* or 16px */

                            padding-top: 22px;
                            color: #013c44;
                        }
                    }
                }
            }
        }
    }
}
