.DoctorProfile{
    width: 348px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px; 
    padding: 23px;
    .docContainer{
        display: flex;
        justify-content: space-between;
        width: 300px;
        .avatarimg{
            height: 218px;
            width: 146px;
            border-radius: 5px;
            background-color: #D9EDF1;
            object-fit: none;
        }
        .profileimg{
            height: 218px;
            width: 146px;
            border-radius: 5px;
            background-color: #D9EDF1;
            object-fit: cover;
        }
        .docNAme{
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #013C44;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .speciality{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #66B5C3;
            margin-top: -2px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-bottom: 0;
            // white-space: nowrap;
        }
       .docDetailsContainer{
           width: 133.45px;
           height: 174.19px;
           margin-left: 15px;

           img{
               margin: 10px 0;
           }
        .docDetails{
            margin-top: 15px;
            
            
        }

        .hr{
            width: 146px;
            // width: auto;
            height: 0px;
            border: 1px solid rgba(102, 181, 195, 0.3);
        }
        .feeContainer{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex-direction: column;
            span{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 16px;
                color: #046673;
            }
        }
    
    }
    .starcontainer{
        img{
            margin-right: 3px;
        }
    }
    }

    .appoContainer{
        width: 297px;
        //height: 238.13px;
        left: 25px;
        top: 218.19px;
        background: #F2F8F9;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        margin: 20px auto auto auto;
        padding: 10px;

        .heading{
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */
            color: #013C44;
            margin-bottom: 10px;

        }
        .appointmentDetails{
            width: 272px;
            background: #FFFFFF;
            border: 1px solid #D9EDF1;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 20px;
        }
        .appoButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 137px;
            height: 44px;
            background: #00839B;
            border-radius: 5px;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            margin-top: 15px;

            &:hover{
                color: #00839B;
                background: transparent;
                border: 1px solid #00839B
            }
        }
        .disable{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 137px;
            height: 44px;
            background: #00839b73;
            border-radius: 5px;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            margin-top: 15px;
        }
    }
}
.ChaperoneProfile{
    width: 348px;
    height: auto;
    background: #FFFFFF;
    border: 1px solid #D9EDF1;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 23px;
    margin-top: 15px;
    .docContainer{
        display: flex;
        gap: 10px;
        justify-content: space-between;
        .avatarimg {            
            width: 146px;
            border-radius: 5px;
            background-color: #D9EDF1;
            object-fit: cover;
        }
        .docDetailsContainer{
            padding: 1% 2%;
            .headtitle{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                color: #013C44;
                text-overflow: ellipsis;
            }
            .docNAme{
                font-family: 'VisueltMedium';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 22px;
                margin-top: 10px;
                color: #013C44;
                text-overflow: ellipsis;
            }
            .docDetails {
                margin-top: 25%;
                max-width: 133px;
                .details{
                    font-size: 12px;
                    font-family: 'VisueltRegular';
                    font-weight: 400;
                    color: #046673;
                    text-transform: capitalize;
                    white-space: normal;
                }
            }
        }
    }
}
.details{
    display: flex;
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 133%;
    color: #013C44;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    .imgholder{
        position: absolute;
        right: 0px;
        top: -9px;
    }
    span{
        font-family: 'VisueltMedium';
    }
}
.detailswithout{
    display: flex;
    font-family: 'VisueltRegular';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 133%;
    color: #013C44;

    span{
        font-family: 'VisueltMedium';
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}
.availableTime{
    font-size: 14px;
    font-family: 'VisueltMedium';
    color: #013C44;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    .inputForm option {
    display: block;
    width: 100%;
    padding: 1rem 1.5rem ;
    clear: both;
    margin: 2px 0px;
    // font-weight: 400;
    color: #013C44;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
}
.iconButtons{
    display: flex;
    margin-top: 18px;
    gap: 5px;
    min-height: 44px;
    flex-direction: column;
    margin-left: -6%;
    .bookDocBtn{
        // flex: 2;
        font-family: 'VisueltRegular';
        color: white;
        background: #00839B;
        border-radius: 5px;
        width: 272px;
        min-height: 44px;
    }
    .docDetailBtn{
        // flex: 1;
        border-radius: 5px;
        border: 1px solid #66B5C3;
        width: 50px;
        min-height: 44px;
    }
    .docVideoBtn{
        // flex: 1;
        border-radius: 5px;
        border: 1px solid #66B5C3;
        width: 50px;
        min-height: 44px;
        margin-left: 10px;
    }
}
.contactAdmin{
    font-size: 14px;
    font-family: 'VisueltRegular';
    margin-bottom: 5px;
    color: #013C44;
    text-decoration: underline;
    margin-top: 5px;
}
.docProfContainer{
    padding: 2% 0%;
    .aboutprof{
        display: flex;
        flex-wrap: wrap;
        gap: 10%;
        justify-content: space-between;
        .aboutHead{
            flex: 1;
            .profileimg{
                width: 300px;
                height: 350px;
             }
             .docDetails{
                 
                 margin-top: 2%;
                 .details{
                    font-size: 18px;
                 }
                 
             }
             .starcontainer{
                img{
                    height: 15px;
                    width: 15px;
                    margin-right: 3px;
                }
            }
            hr{

                border: 1px solid rgba(102, 181, 195, 0.3);
            }
            .profSpeciality{
                font-family: 'VisueltMedium';
                color: #013C44;
                margin-top: 10%;
                .titles{
                    font-size: 24px;
                    margin-bottom: 3%;
                }
                button{
                    margin:2%;
                    height: 50px;
                    padding: 5px 30px;
                    font-family: 'VisueltRegular';
                    font-size: 18px;
                    background: #D9EDF1;
                    border-radius: 5px;
                    color: #013C44;
                    &:hover{
                    background: #013C44;
                    color: #D9EDF1;
                    }
                }
            }

        }
        .aboutBody{
            flex: 2;
            .aboutProfText{
                h1{
                   color: #013C44;
                   font-family: 'VisueltMedium';
                   font-size: 36px;
                   margin-bottom: 3px;
                }
                p{
                    color: #66B5C3;
                    font-family: 'VisueltRegular';
                    font-size: 18px;
                    margin-bottom: 3px; 
                }
                h2{
                   margin-top: 10%;
                   color: #013C44;
                   font-family: 'VisueltMedium';
                   font-size: 24px;
                }
                .aboutContent{
                   color: #013C44;
                  
                   font-family: 'VisueltRegular';
                    font-size: 18px;
                }
            }
            .slotBooking{
                height: 230px;
                padding: 3% 5%;
                background: #EFFFF8;
                margin:  3% 0%;
                border-radius: 5px;
                font-family: 'VisueltMedium';
                h4{
                    color: #013C44;
                    font-size: 20px;
                    margin-bottom: 20px;
                }
                .inputForm{
                    background: white;
                }
                .sectionBook{
                    margin:4% 0%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    font-size: 12px;
                    // flex-direction: column;

                    .bookDocBtn{
                        
                        height: 50px;
                        min-width: 47%;
                        padding: 5px 30px;
                        font-family: 'VisueltRegular';
                        font-size: 18px;
                        background: #00839B;
                        border-radius: 5px;
                        color: white;
                        &:hover{
                            border: 1px solid #00839B;
                        background: #D9EDF1;
                        color: #00839B;
                        }
    
                    }

                }
                
            }
        }
         
    }
}

@media (min-width: 768px) and (max-width: 1279px) {
   
    .profSpeciality{

        button{
            height: 64px !important;
        }
    }
}

@media (min-width: 300px) and (max-width: 767px) {

}
.DoctorProfileSadn{
    width: 348px;
    height: auto;
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px; 
    padding: 23px;
    padding-top: 0px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    .docContainer{
        display: flex;
        width: 300px;
        .avatarimg{
            height: 178px;
            width: 146px;
            border-radius: 5px;
            background-color: #D9EDF1;
            //object-fit: none;
        }
        .profileimg{
            height: 178px;
            width: 146px;
            border-radius: 5px;
            background-color: #D9EDF1;
            object-fit: cover;
        }
        .docNAme{
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
            color: #013C44;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .speciality{
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #66B5C3;
            margin-top: -2px;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-bottom: 0;
            // white-space: nowrap;
        }
       .docDetailsContainer{
           width: 133.45px;
           height: 174.19px;
           margin-left: 15px;

           img{
               margin: 10px 0;
           }
        .docDetails{
            margin-top: 15px;
            
            
        }

        .hr{

            // width: auto;
            height: 0px;
            border: 1px solid rgba(102, 181, 195, 0.3);
            width: 146px;
        }
        .feeContainer{
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            flex-direction: column;
            span{
                font-family: 'VisueltRegular';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 16px;
                color: #046673;
            }
        }
    
    }
    .starcontainer{
        img{
            margin-right: 3px;
        }
    }
    }

    .appoContainer{
        width: 297px;
        //height: 238.13px;
        left: 25px;
        top: 218.19px;
        background: #F2F8F9;
        border: 1px solid #D9EDF1;
        box-sizing: border-box;
        margin: 20px auto auto auto;
        padding: 10px;

        .heading{
            font-family: 'VisueltMedium';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height, or 129% */
            color: #013C44;
            margin-bottom: 10px;

        }
        .appointmentDetails{
            width: 272px;
            background: #FFFFFF;
            border: 1px solid #D9EDF1;
            box-sizing: border-box;
            border-radius: 5px;
            padding: 20px;
        }
        .appoButton{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 137px;
            height: 44px;
            background: #00839B;
            border-radius: 5px;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            margin-top: 15px;

            &:hover{
                color: #00839B;
                background: transparent;
                border: 1px solid #00839B
            }
        }
        .disable{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 137px;
            height: 44px;
            background: #00839b73;
            border-radius: 5px;
            font-family: 'VisueltRegular';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 15px;
            color: #FFFFFF;
            margin-top: 15px;
        }
    }
}