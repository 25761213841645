.hh-grayBox {
  // background-color: #F8F8F8;
  margin-bottom: 20px;
  padding: 35px;
  margin-top: 20px;
}
.pt45 {
  padding-top: 45px;
}
.order-tracking {
  text-align: center;
  width: 33.33%;
  position: relative;
  display: block;
}
.order-tracking .is-complete {
  display: block;
  position: relative;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: 0px solid #afafaf;
  background-color: #37cc8c;
  margin: 0 auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
  z-index: 2;
}
.order-tracking .is-complete:after {
  display: block;
  position: absolute;
  content: "";
  height: 14px;
  width: 7px;
  top: -2px;
  bottom: 0;
  left: 5px;
  margin: auto 0;
  border: 0px solid #afafaf;
  border-width: 0px 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}
.order-tracking.completed .is-complete {
  border-color: #27aa80;
  border-width: 0px;
  background-color: #191716;
}
.order-tracking.completed .is-complete:after {
  border-color: #fff;
  // border-width: 0px 3px 3px 0;
  // width: 7px;
  // left: 11px;
  // opacity: 1;
}
.order-tracking p {
  // color: #A4A4A4;
  color: #0c44ac;
  font-size: 16px;
  margin-top: 8px;
  margin-left: 23%;
  margin-bottom: 0;
  line-height: 20px;
}
// .order-tracking .completed p{
// 	color: #0C44AC;
// }
.order-tracking p span {
  font-size: 14px;
}
.order-tracking.completed p {
  color: #000;
}
.order-tracking::before {
  content: "";
  display: block;
  height: 3px;
  width: calc(100% - 0px);
  background-color: #37cc8c;
  top: 13px;
  position: absolute;
  left: calc(-60% + 20px);
  z-index: 0;
}
.order-tracking:first-child:before {
  display: none;
}
.order-tracking.completed:before {
  background-color: #163e8c;
}

.tracker {
  margin-left: 4%;
  width: 100%;
}
.viewReceipt-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 44px;
  margin: 5px;
  border: 1px solid #0c44ac;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-transform: uppercase;
  background-color: #0c44ac;
  color: white;
  margin-left: -5%;
  margin-bottom: 5%;
  margin-top: -5%;

  &:hover {
    background-color: #f8f8f8;
    color: #0c44ac;
  }
}
.viewReceipt {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 44px;
  margin: 5px;
  border: 1px solid #0c44ac;
  box-sizing: border-box;
  border-radius: 5px;
  font-family: "VisueltRegular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  margin-left: 2%;
  text-transform: uppercase;
  margin-top: -5%;
  color: #0c44ac;
}

@media screen and (max-width: 440px) {
  .viewReceipt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 44px;
    margin: 5px;
    border: 1px solid #0c44ac;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    // margin-left: -11%;
    text-transform: uppercase;
    margin-bottom: 10%;
    color: #0c44ac;
  }
  .viewReceipt-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 44px;
    margin: 5px;
    border: 1px solid #0c44ac;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: "VisueltRegular";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    text-transform: uppercase;
    background-color: #0c44ac;
    color: white;
    margin-left: -11%;
    margin-bottom: 5%;

    &:hover {
      background-color: #f8f8f8;
      color: #0c44ac;
    }
  }
}
