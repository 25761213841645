.NotificationContainer {
    display: flex;
    flex-direction: column;
    max-width: 826.58px;
    .header-row {
        display: flex;
        flex-direction: row;
        margin-top: 43px;
        justify-content: space-between;
        align-items: center;
        .left-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            .img-cont {
                padding-right: 16px;
            }
            .select-all-text {
                font-family: "VisueltRegular";
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 26px;
                color: #013c44;
                padding-top: 6px;
            }
        }
        .right-cont {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            .sort-cont-out {
                cursor: pointer;
                width: 72.49px;
                height: 26.58px;
                background: rgba(0, 131, 155, 0.1);
                border-radius: 5px;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                .sort-text {
                    font-family: "VisueltRegular";
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    color: #195c65;
                }
                .sortpopup {
                    position: absolute;
                    width: 177.68px;
                    height: 167.1px;
                    top: 50px;
                    right: 0px;
                    background: #ffffff;
                    opacity: 0.95;
                    z-index: 9;
                    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
                    border-radius: 10px 0px 10px 10px;
                    .font-display-cls-sort {
                        display: flex;
                        flex-direction: column;
                        padding: 16px 28px 17px 15px;
                        .popuptext-sort {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 250%;
                            /* or 35px */
                            text-align: right;
                            color: #046673;
                            padding-top: 3px;
                        }
                    }
                }
            }
            .option-icon {
                padding-left: 20px;
                position: relative;
                cursor: pointer;
            }

            .popup {
                position: absolute;
                width: 177.68px;
                min-height: 127.06px;
                background: #ffffff;
                opacity: 0.95;
                box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.07);
                border-radius: 10px 0px 10px 10px;
                top: 50px;
                right: 0px;
                z-index: 9;
                .font-display-cls {
                    display: flex;
                    flex-direction: column;
                    padding-left: 33px;
                    padding-right: 28px;
                    padding-top: 16px;
                    padding-bottom: 17px;
                    .popuptext {
                        font-family: "VisueltRegular";
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 250%;
                        /* or 35px */
                        text-align: right;
                        letter-spacing: -0.373849px;
                        padding-top: 3px;
                        color: #046673;
                    }
                }
            }
        }
    }
    .subheader-text {
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color: #00839b;
        margin-top: 28px;
        margin-bottom: 20px;
    }
    .content {
        .list-cont-out {
            display: flex;
            flex-direction: column;
            .read{
                background: #bdd9e2c9 !important;

            }
            .wrapper {
                max-width: 826.58px;
                min-height: 134.27px;
                background: #f3f9fb;
                border: 2px solid rgba(196, 240, 221, 0.5);
                box-sizing: border-box;
                border-radius: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                padding-left: 23px;
                padding-top: 15px;
                padding-right: 72px;
                padding-bottom: 25px;
                margin-bottom: 20px;
                position: relative;
                .radio-btn {
                    padding-right: 20px;
                    cursor: pointer;
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
                .profile-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    border: 0.712172px solid #c4f0dd;
                    margin-right: 23px;
                    img {
                        width: 72.51px;
                        height: 72.51px;
                        border-radius: 50%;
                        object-fit: contain;
                    }
                }
                .list-right-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 20px;
                    padding-right: 20px;
                    .header-container-wrapper {
                        display: flex;
                        flex-direction: row;
                        .header-container-font-cls {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 24px;
                            line-height: 32px;
                            display: flex;
                            align-items: center;
                            color: #013c44;
                        }
                        .line-icon {
                            padding-left: 15px;
                        }
                        .timing-text {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 19px;
                            display: flex;
                            align-items: center;
                            color: #00839b;
                            padding-left: 12px;
                        }
                        .desc-text {
                            font-family: "VisueltRegular";
                            font-style: normal;
                            font-weight: normal;
                            font-size: 18px;
                            line-height: 24px;
                            color: rgba(1, 60, 68, 0.8);
                            padding-top: 5px;
                        }
                    }
                }
                .notify-important{
                    position:absolute;
                    right: 15px;
                    top: 10px;
                }
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1279px) {
        .header-row {
            .left-cont {
                .select-all-text {
                    font-size: 12px;
                }
            }
            .right-cont {
                .sort-cont-out {
                    .sort-text {
                        font-size: 10px;
                    }
                    .sortpopup {
                        .font-display-cls-sort {
                            .popuptext-sort {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .popup {
                    .font-display-cls {
                        .popuptext {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .subheader-text {
            font-size: 16px;
        }

        .list-cont-out {
            .wrapper {
                .profile-img {
                    img {
                        max-width: 72.51px;
                        max-height: 72.51px;
                    }
                }
                .list-right-container {
                    .header-container-wrapper {
                        .header-container-font-cls {
                            font-family: "VisueltRegular";

                            font-size: 15px;
                        }

                        .timing-text {
                            font-family: "VisueltRegular";

                            font-size: 10px;
                        }
                        .desc-text {
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
    
    @media (min-width: 300px) and (max-width: 767px) {
        .header-row {
            .left-cont {
                .select-all-text {
                    font-size: 10px;
                }
            }
            .right-cont {
                .sort-cont-out {
                    .sort-text {
                        font-size: 9px;
                    }
                    .sortpopup {
                        .font-display-cls-sort {
                            .popuptext-sort {
                                font-size: 9px;
                            }
                        }
                    }
                }
                .popup {
                    .font-display-cls {
                        .popuptext {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
        .subheader-text {
            font-size: 16px;
        }
        .list-cont-out {
            .wrapper {
                padding-top: 8px;
                .list-right-container {
                    .header-container-wrapper {
                        display: flex;
                        flex-direction: row;
                        .header-container-font-cls {
                            font-size: 12px;
                        }
                        .timing-text {
                            font-size: 10px;
                        }
                        .desc-text {
                            font-size: 11px;
                            padding-top: 3px;
                        }
                    }
                }
            }
        }
    }
}
