.goBack {
    display: flex;
    width: 100%;
    float: left;
    cursor: pointer;

    img {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 10px;
    }
    p {
        display: block;
        margin-top: auto;
        margin-bottom: auto;
        font-family: "VisueltRegular";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #66b5c3;
        text-transform: capitalize;
    }
}

.h3 {
    display: block;
    color: #66b5c3;
    font-size: 18px !important;
    line-height: 133% !important;
    margin-top: 30px;
}

.hr {
    border: 1px solid #66b5c3;
    width: 60px;
    float: left;
}
@media (min-width: 768px) and (max-width: 1279px) {
    .h3 {
        font-size: 16px !important;
    }
}
@media (min-width: 375px) and (max-width: 767px) {
    .h3 {
        font-size: 14px !important;
    }
}